<div class="contact-message {{ type }}" *ngIf="isHidden === false">
<!--  <div *ngIf="this.type === 'modal' && isMobile" class="mobile-message-header">
    <ParagraphAtom [mobileSize]="20" color="dark" size="large" weight="medium-weight" text="{{this.isMessageSent === false ? 'Discutons ensemble' : ''}}"></ParagraphAtom>
    <div class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
  </div>-->

  <!-- DESKTOP -->
  <div class="form" *ngIf="isMessageSent === false && !isMobile">
    <div class="form-article-title">
      <Image type="icon" image="message" width="64px"></Image>
      <TitleAtom align="left" *ngIf="!isMobile" color="light" weight="semi-bold" size="intermediate" text="{{ title }}"></TitleAtom>
    </div>

    <div class="form-article-icons">
      <Image type="icon" image="form-article-top-right" width="150.57px"></Image>
    </div>

    <div class="close" *ngIf="type === 'modal' && !isMobile" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>

    <ul>
      <li>
        <ParagraphAtom color="light" weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
        <InputAtom [style]="'basic'" color="light" type="name" name="username" [(value)]="form.name"></InputAtom>
      </li>
      <li>
        <ParagraphAtom color="light" weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
        <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" type="number" placeholder="Saisissez votre code postal" (onSelect)="onSelectCpChange($event)" [style]="'basic'" color="{{isPostalCodeNotAvailable() ? 'red' : 'light'}}" name="address" [(value)]="this.form.postal_code"></InputAtom>
        <ParagraphAtom *ngIf="isPostalCodeNotAvailable()" color="red" size="small" weight="medium" text="Monabee n’est pas encore présent dans votre ville."></ParagraphAtom>
      </li>
      <li>
        <ParagraphAtom color="light" weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
        <InputAtom (input)="onInputChange(2)" type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="{{form.phone_number === '' || checkPhoneNumber() ? 'light' : 'red'}}" name="phoneNumber" [(value)]="form.phone_number"></InputAtom>
        <ParagraphAtom *ngIf="!checkPhoneNumber() && isPhoneInputDirty && form.phone_number !== ''" color="red" size="small" weight="medium" text="Numéro de téléphone invalide."></ParagraphAtom>
      </li>
    </ul>

    <div class="validate-btn">
      <ButtonAtom [style]="'basic'" color="yellow" [isDisabled]="!this.canSubmit()" text="{{ btnText }}" (click)="onSubmit()"></ButtonAtom>
    </div>
  </div>

  <div class="is-validated" *ngIf="isMessageSent === true && !isMobile">
    <div *ngIf="type === 'modal' && !isMobile" class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
    <Image type="icon" image="check-blue" width="56px"></Image>
    <TitleAtom [mobileSize]="28" color="dark" align="center" size="large" weight="semi-bold" text="Votre message a bien été envoyé !"></TitleAtom>
    <ParagraphAtom color="dark" align="center" size="intermediate" weight="regular" text="Nous vous répondrons dans les plus brefs délais."></ParagraphAtom>
    <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="onHideModal()"></ButtonAtom>
  </div>

  <!-- MOBILE : BTN -->
  <div *ngIf="!isModalOpen && isMobile" class="form-article-mobile-btn">
    <ButtonAtom size="full" [style]="'basic'" color="yellow" text="{{ btnText }}" (click)="openModal()"></ButtonAtom>
  </div>

</div>

<!-- MOBILE : MODAL -->
<div *ngIf="isModalOpen" class="form-article-modal">
  <div class="mobile-message-header">
    <ParagraphAtom [mobileSize]="20" color="dark" size="large" weight="medium-weight" text="{{this.isMessageSent === false ? 'Discutons ensemble' : ''}}"></ParagraphAtom>
    <div class="close" (click)="openModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
  </div>

  <div *ngIf="!isMessageSent && isMobile" class="form-article-mobile">
    <div class="form-article-title">
      <Image type="icon" image="message" width="64px"></Image>
      <TitleAtom align="left" color="dark" weight="semi-bold" size="intermediate" text="{{ title }}"></TitleAtom>
    </div>

    <div class="form-article-icons">
      <Image type="icon" image="form-article-top-right" width="150.57px"></Image>
    </div>

    <div class="close" *ngIf="type === 'modal' && !isMobile" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>

    <ul>
      <li>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
        <InputAtom [style]="'basic'" color="light" type="name" name="username" [(value)]="form.name"></InputAtom>
      </li>
      <li>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
        <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" type="number" placeholder="Saisissez votre code postal" (onSelect)="onSelectCpChange($event)" [style]="'basic'" color="{{isPostalCodeNotAvailable() ? 'red' : 'light'}}" name="address" [(value)]="this.form.postal_code"></InputAtom>
        <ParagraphAtom *ngIf="isPostalCodeNotAvailable()" color="red" size="small" weight="medium" text="Monabee n’est pas encore présent dans votre ville."></ParagraphAtom>
      </li>
      <li>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
        <InputAtom (input)="onInputChange(2)" type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="{{form.phone_number === '' || checkPhoneNumber() ? 'light' : 'red'}}" name="phoneNumber" [(value)]="form.phone_number"></InputAtom>
        <ParagraphAtom *ngIf="!checkPhoneNumber() && isPhoneInputDirty && form.phone_number !== ''" color="red" size="small" weight="medium" text="Numéro de téléphone invalide."></ParagraphAtom>
      </li>
    </ul>

    <div class="validate-btn">
      <ButtonAtom [style]="'basic'" color="yellow" [isDisabled]="!this.canSubmit()" text="{{ btnText }}" (click)="onSubmit()"></ButtonAtom>
    </div>
  </div>

  <div class="is-validated" *ngIf="isMessageSent && isMobile">
    <div *ngIf="type === 'modal' && !isMobile" class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
    <Image type="icon" image="check-blue" width="56px"></Image>
    <TitleAtom [mobileSize]="28" color="dark" align="center" size="large" weight="semi-bold" text="Votre message a bien été envoyé !"></TitleAtom>
    <ParagraphAtom color="dark" align="center" size="intermediate" weight="regular" text="Nous vous répondrons dans les plus brefs délais."></ParagraphAtom>
    <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="openModal()"></ButtonAtom>
  </div>
</div>
