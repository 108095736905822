<div class="card-customer-review-component">
  <div class="card-customer-review-content">
    <div>
      <span class="{{initialsColor}}">
        <ParagraphAtom color="light" size="large" weight="bold" text="{{getInitial()}}"></ParagraphAtom>
      </span>
      <div>
        <ParagraphAtom color="dark" size="large" weight="semi-bold" text="{{username}}"></ParagraphAtom>
        <ul class="stars">
          <li *ngFor="let star of getRatings()">
            <Image width="16px" type="icon" image="star-yellow"></Image>
          </li>
        </ul>
      </div>
    </div>
    <ParagraphAtom color="dark" size="intermediate" weight="regular" text="{{text.slice(0, 220)}}"></ParagraphAtom>
  </div>
</div>
