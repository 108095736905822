import {Component, NgZone, OnInit} from '@angular/core';
import { CmsAnnounceType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import { Router } from '@angular/router';

@Component({
  selector: 'Footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  /** Data from the CMS */
  public data: CmsAnnounceType | null = null;

  /* The current url */
  currentUrl: string = '';

  constructor(public router: Router, public cms: CmsService, private ngZone: NgZone,) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;

    // Get an article
    this.cms.retrieve(`[[at(document.type, \"bandeau_annonce\")]]`, "[my.page.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.data = content.results[0];
      });
    });
  }

}
