import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'CardInline',
  templateUrl: './card-inline.component.html',
  styleUrls: ['./card-inline.component.scss']
})
export class CardInlineComponent implements OnInit {

  // On btn click
  @Output() btnClick: EventEmitter<void> = new EventEmitter<void>();

  // The image name
  @Input() image: string = '';

  // The title of the card
  @Input() title: string = '';

  // The paragraph of the text
  @Input() text: string = '';

  // The text of the btn
  @Input() btnText: string = '';

  // The icon of the btn
  @Input() btnIcon: string = '';

  // The style of the card
  @Input() style: string = '';

  // The size of the card
  @Input() size: string = '';

  // The href of the card
  @Input() href: string = '';

  // The query params of the card
  @Input() queryParams: any;

  constructor() { }

  ngOnInit(): void {
  }

  public get classes(): Array<string> {
    return [`${this.style} ${this.size}`];
  }

}
