<div class="home-container">
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <div class="first-section">
    <!-- NAVBAR -->
    <div>
      <Navbar></Navbar>
    </div>

    <!-- HEROBANNER -->
    <div id="herobanner" class="herobanner-container">
      <TitleAtom type="p" text="Vivez avec le soleil et économisez jusqu’à 50% sur votre facture d’énergie" align="center" color="dark" size="heading" weight="semi-bold"></TitleAtom>
      <ParagraphAtom text="Monabee vous accompagne vers l’autonomie énergétique grâce à l’autoconsommation solaire, au pilotage intelligent de vos équipements et à l’accompagnement au changement." align="center" size="large" weight="regular"></ParagraphAtom>
      <SearchSimulation type="simulation" icon="pinned" text="Simuler mon installation solaire" placeholder="Saisissez votre adresse"></SearchSimulation>
      <GoogleRatingAtom></GoogleRatingAtom>

      <!-- KEY-POINTS -->
      <div class="key-points-container wrapper">
        <ul>
          <li><Keypoint icon="solar" value="+6000" text="producteurs d’électricité verte accompagnés"></Keypoint></li>
          <li><Keypoint icon="badge" value="97%*" text="de nos clients nous recommanderaient"></Keypoint></li>
          <li><Keypoint icon="piggy-bank" value="53%" text="d’économies d’énergies réalisées en moyenne"></Keypoint></li>
        </ul>
      </div>
    </div>
  </div>

  <!-- TAKE CARE -->
  <div class="take-care-container wrapper"  style="display: none;">
    <div id="takeCare" class="take-care-content">
      <div>
        <div class="take-care-title">
          <TitleAtom type="p" text="On s’occupe de tout !" color="orange" size="tiny" [mobileSize]="16" weight="semi-bold"></TitleAtom>
          <TitleAtom type="h1" text="Votre installation de panneaux solaires sur mesure… Le pilotage en plus !" color="dark" size="x-large" [mobileSize]="22" weight="semi-bold"></TitleAtom>
        </div>
        <ParagraphAtom text="Installateur de panneaux solaires, Monabee aide depuis 2012, les particuliers et les entreprises à réaliser leur projet d’énergie solaire. En plus de l’installation de panneaux solaires et/ou bornes solaires, notre accompagnement comprend :" color="dark" size="intermediate" weight="regular"></ParagraphAtom>
        <ul>
          <li>
            <span>1</span>
            <ParagraphAtom text="Visite technique de faisabilité de votre projet solaire + devis" color="dark" size="medium" weight="medium-weight"></ParagraphAtom>
          </li>
          <li>
            <span>2</span>
            <ParagraphAtom text="Etude de votre besoin en énergie solaire + calcul de la rentabilité de vos panneaux solaires" color="dark" size="medium" weight="medium-weight"></ParagraphAtom>
          </li>
        </ul>
        <ul>
          <li>
            <span>3</span>
            <ParagraphAtom text="Prise en charge des démarches administratives" color="dark" size="medium" weight="medium-weight"></ParagraphAtom>
          </li>
          <li>
            <span>4</span>
            <ParagraphAtom text="Suivi de votre installation solaire grâce à nos solutions domotiques intelligentes" color="dark" size="medium" weight="medium-weight"></ParagraphAtom>
          </li>
        </ul>
        <div class="take-care-btn">
          <ButtonAtom [style]="'basic'" color="yellow" text="Demander un devis gratuitement" size="default" (click)="hideContactModal = false"></ButtonAtom>
        </div>

        <div class="take-care-condition">
          <ParagraphAtom text="*selon une enquête de satisfaction interne réalisée sur nos clients" color="dark" size="intermediate" weight="regular"></ParagraphAtom>
        </div>
      </div>
      <VideoYoutubeAtom video="https://www.youtube.com/embed/FMc9oGKF35s" width="496px" height="280px" widthMobile="100%" heightMobile="200px"></VideoYoutubeAtom>
    </div>
  </div>




  <!-- ADVANTAGES -->
  <div id="advantages" class="advantages-container" style="display: none;">
    <TitleAtom type="h2" color="light" align="center" size="x-large" [mobileSize]="22" weight="semi-bold" text="Installez vos panneaux photovoltaïques avec Monabee : vos avantages"></TitleAtom>
    <Image *ngIf="currentWidth > 1000" class="advantages-top-left" type="icon" image="advantages-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="advantages-top-right" type="icon" image="advantages-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" class="advantages-bottom-left" type="icon" image="advantages-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="advantages-bottom-right" type="icon" image="advantages-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" class="advantages-top-left" type="icon" image="advantages-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="advantages-top-right" type="icon" image="advantages-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="advantages-bottom-left" type="icon" image="advantages-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="advantages-bottom-right" type="icon" image="advantages-bottom-right-mobile"></Image>
    <ul class="wrapper">
      <li>
        <Image imageCloudinary="homepage/image_2_autoconso_solaire_e6aejx_tnprfc.jpg" width="352px" mobileWidth="330px" type="image" borderRadius="radius" alt="produire et piloter son électricité solaire"></Image>
        <TitleAtom [mobileSize]="16" type="h3" color="light" size="intermediate" weight="semi-bold" text="78% d’autoconsommation solaire"></TitleAtom>
        <ParagraphAtom color="grey" size="intermediate" weight="regular" text="Naturellement, les clients autoconsomment 30% de leur énergie. Avec la méthode Monabee, vous doublez votre capacité d’autoconsommation. Dimensionnement sur mesure, pilotage de vos équipements, et accompagnement dans le changement de vos habitudes de consommation. Au final, une installation de plus petite taille pour le même résultat."></ParagraphAtom>
      </li>
      <li>
        <Image imageCloudinary="homepage/image_3_installateurs_rfve4c_puvv2y.jpg" width="340px" mobileWidth="330px" type="image" borderRadius="radius" alt="installateur panneaux solaires"></Image>
        <TitleAtom [mobileSize]="16" type="h3" color="light" size="intermediate" weight="semi-bold" text="1 équipe d’installateurs internes certifiés"></TitleAtom>
        <ParagraphAtom color="grey" size="intermediate" weight="regular" text="Nos installateurs sont des salariés Monabee, ce qui nous permet de maîtriser l’ensemble de la chaîne du projet et d’être très réactifs ! Chaque installateur possède les certifications Quali PV, IRVE, RGE. Le petit plus ? Ils sont tous très sympathiques !"></ParagraphAtom>
      </li>
      <li>
        <Image imageCloudinary="homepage/image_4_onsedeplace_ufyqfa_tu5anq.jpg" width="352px" mobileWidth="330px" type="image" borderRadius="radius"></Image>
        <TitleAtom [mobileSize]="16" type="h3" color="light" size="intermediate" weight="semi-bold" text="On se déplace, en vrai !"></TitleAtom>
        <ParagraphAtom color="grey" size="intermediate" weight="regular" text="Notre équipe de visiteurs techniques se déplace chez vous pour faire une étude de faisabilité complète (prise en compte des arbres, type de toit, orientation du soleil…) Chez Monabee, pas de visite technique via Google maps… Uniquement nos yeux, pour valider que votre projet puisse être vraiment réalisé."></ParagraphAtom>
      </li>
    </ul>
  </div>

  <!-- BENEFICE DES INSTALLATIONS -->

  <div id="benefits" class="benefits-container">
    <div class="benefits-content">
      <div>
        <div class="benefits-title">
          <TitleAtom type="h2" color="dark" align="center" size="heading" weight="semi-bold" text="Les bénéfices de nos installations"></TitleAtom>
          <ParagraphAtom color="dark" align="center" size="medium" weight="regular" text="La production d’électricité par des panneaux solaires offre de nombreux avantages dès leur mise en place. Nos installations optimisent ses gains pour une efficacité maximale."></ParagraphAtom>
        </div>

      <ul>
        <li>
          <div class="benefit-item">
            <Image type="icon" width="64px" image="piggy-bank-blue"></Image>
            <div>
              <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Réduire ma facture d'électricité de 50%*"></TitleAtom>
              <ParagraphAtom color="dark" size="medium" weight="regular" text="Économie moyenne observée chez nos clients depuis 2012"></ParagraphAtom>
            </div>
          </div>
        </li>
        <li>
          <div class="benefit-item">
            <Image type="icon" width="64px" image="results-blue"></Image>
            <div>
              <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Maîtriser la hausse du prix de l’énergie"></TitleAtom>
              <ParagraphAtom color="dark" size="medium" weight="regular" text="Un kWh consommé à 0,07€ vs. 0,25€ acheté sur le réseau"></ParagraphAtom>
            </div>
          </div>
        </li>
        <li>
          <div class="benefit-item">
            <Image type="icon" width="64px" image="green-energy-blue"></Image>
            <div>
              <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Économiser 16 kg de CO2 par kWh"></TitleAtom>
              <ParagraphAtom color="dark" size="medium" weight="regular" text="Économie moyenne de CO2 observée chez nos clients depuis 2012"></ParagraphAtom>
            </div>
          </div>
        </li>
      </ul>

    </div>
      <ButtonAtom   [routerLink]="'/simulateur-panneau-solaire'" [style]="'basic'" color="yellow" text="Simuler mon installation solaire"></ButtonAtom>

    </div>
  </div>



  <!-- OFFERS -->
  <div id="offers" class="offers-container wrapper">
    <!-- OFFERS TITLE -->
    <div class="offers-title">
      <TitleAtom type="h2" align="center" color="orange" size="tiny" [mobileSize]="16" weight="semi-bold" text="Notre solution sur mesure en autoconsommation"></TitleAtom>
      <TitleAtom type="p"  align="center" color="dark" size="x-large" [mobileSize]="22" weight="semi-bold" text="Devenez producteur de votre propre électricité verte"></TitleAtom>
    </div>

    <!-- OFFERS FIRST SECTION -->
    <div class="offers-section">
      <div>
        <div class="offers-title">
          <TitleAtom type="p" color="orange" size="tiny" [mobileSize]="16" weight="semi-bold" text="Inclus et unique sur le marché, notre service premium Securee'z ! Maintenance, garanties pièces, main d'œuvre et déplacement jusqu'à 30 ans."></TitleAtom>
        </div>

        <ParagraphAtom color="dark" size="intermediate" weight="regular" text="L’installation de panneaux photovoltaïques, va vous permettre de : Consommer immédiatement l’électricité verte, renouvelable et gratuite que vous produisez en utilisant vos équipements électriques (<a href=': https://www.monabee.fr/blog/alimenter-mon-chauffe-eau-grace-a-mon-surplus-photovoltaique/'>chauffe-eau</a>, climatiseur, <a href='https://www.monabee.fr/blog/panneaux-solaires-pour-piscine/ '>piscine</a>, …). Revendre sur le réseau électrique votre surplus de production énergétique = Réduire votre facture électrique jusqu’à 50% !"></ParagraphAtom>
        <ul>
          <li>
            <Image type="icon" image="co2" width="24"></Image>
            <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Vous économisez 19,8 kg émissions de Co²"></ParagraphAtom>
          </li>
          <li>
            <Image type="icon" image="leaf" width="24"></Image>
            <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Vous participez activement à la transition énergétique"></ParagraphAtom>
          </li>
        </ul>
        <div class="offers-btn">
          <div>
            <ButtonAtom [style]="'basic'" color="yellow" size="default" href="/simulateur-panneau-solaire" text="Simuler mon installation photovoltaïque"></ButtonAtom>
          </div>

        </div>
      </div>
      <Image type="image" borderRadius="radius" imageCloudinary="homepage/Homepage_client-Castellani_hfhmat.jpg" width="604px" mobileWidth="100%"></Image>
    </div>

    <!-- OFFERS SECOND SECTION -->
    <div class="offers-section" style="display: none;">
      <div>
        <div class="offers-title">
          <TitleAtom type="p" color="orange" size="tiny" [mobileSize]="16" weight="semi-bold" text="Offre Energee’z option borne de recharge"></TitleAtom>
          <TitleAtom type="h3" color="dark" size="large" weight="semi-bold" text="Produire son électricité + Recharger son véhicule électrique"></TitleAtom>
        </div>
        <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Vous souhaitez recharger votre véhicule électrique ou hybride à domicile, à moindre frais ? C’est possible, en installant des panneaux photovoltaïques reliés à une borne de recharge solaire chez vous. Autoconsommation d’électricité solaire à 100 % possible."></ParagraphAtom>
        <ul>
          <li>
            <Image type="icon" image="co2" width="24"></Image>
            <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Vous économisez 19,8 kg émissions de Co²"></ParagraphAtom>
          </li>
          <li>
            <Image type="icon" image="wheel" width="24"></Image>
            <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Vous roulez avec une énergie verte pour 0 €"></ParagraphAtom>
          </li>
        </ul>
        <div class="offers-btn">
          <div>
            <ButtonAtom [style]="'basic'" color="yellow" size="default" text="Je veux un devis" (click)="hideContactModal = false"></ButtonAtom>
          </div>
          <div>
            <ButtonAtom [style]="'stroked'" color="light" size="default" text="Choisir Electree’z" href="installer-borne-de-recharge-solaire"></ButtonAtom>
          </div>
        </div>
      </div>
      <Image type="image" borderRadius="radius" imageCloudinary="homepage/image_6_energeez_borne_t11znp.jpg" width="604px" mobileWidth="100%"></Image>
    </div>
  </div>

  <!-- CERTIFICATIONS -->
  <div class="certifications-container">
    <div id="certifications" class="certifications-content wrapper">
      <div>
        <TitleAtom color="dark" size="large" weight="semi-bold" text="Nos labels et certifications"></TitleAtom>
        <ParagraphAtom [mobileSize]="12" color="dark" size="intermediate" weight="regular" text="Ils prouvent nos engagements et notre expertise au quotidien !"></ParagraphAtom>
      </div>
      <ul>
        <li>
          <Image type="icon" width="108px" mobileWidth="108px" image="certification-RGE"></Image>
        </li>
        <li>
          <Image type="icon" width="130px" mobileWidth="130px" image="certification-IRVE"></Image>
        </li>
        <li>
          <Image type="icon" width="74px" mobileWidth="74px" image="certification-lyon"></Image>
        </li>
        <li>
          <Image type="icon" width="84px" mobileWidth="84px" image="certification-abeilles"></Image>
        </li>
      </ul>
    </div>
  </div>

  <!-- OUR WORK -->
  <div id="workTestimonials" class="work-container">
    <div>
      <TitleAtom type="h2" size="tiny" [mobileSize]="16" align="center" weight="semi-bold" color="orange" text="Nos réalisations"></TitleAtom>
      <TitleAtom type="p" size="x-large" [mobileSize]="22" align="center" weight="semi-bold" color="dark" text="Ils témoignent pour nous"></TitleAtom>
    </div>
    <ul>
      <li *ngFor="let testimonial of testimonials">
        <a routerLink="/temoignage-client/{{ testimonial.data.slug }}">
          <Content type="image" imageExternal="{{ testimonial.data.image.url }}" title="{{ testimonial.data.title }}" textHeadline="Témoignage" text="{{ testimonial.data.summary }}" name="{{ testimonial.data.author[0].text }}"></Content>
        </a>
      </li>
    </ul>
    <ButtonAtom [style]="'underline'" href="/temoignage-client" color="dark" text="Découvrir tous nos témoignages"></ButtonAtom>
  </div>

  <!-- CUSTOMER REVIEWS -->
  <div id="customersReviews" class="customer-review-container">
    <TitleAtom align="center" color="dark" size="x-large" [mobileSize]="22" weight="semi-bold" text="Nos clients nous recommandent"></TitleAtom>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-top-left" type="icon" image="customer-reviews-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-bottom-left" type="icon" image="customer-reviews-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-bottom-right" type="icon" image="customer-reviews-bottom-right"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-top-right" type="icon" image="customer-reviews-top-right"></Image>

    <Image *ngIf="currentWidth < 1000" class="customer-reviews-top-left" type="icon" image="customer-reviews-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-bottom-left" type="icon" image="customer-reviews-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-bottom-right" type="icon" image="customer-reviews-bottom-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-top-right" type="icon" image="customer-reviews-top-right-mobile"></Image>
    <div class="scroll-parent">
      <ul class="scroll-element first">
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>

      </ul>
    </div>
    <GoogleRatingAtom [style]="'dark'"></GoogleRatingAtom>
  </div>

  <!-- CONTACT -->
  <div id="contact" class="contact-container">
    <TitleAtom type="p" align="center" color="orange" weight="semi-bold" size="tiny" [mobileSize]="16" text="Contact"></TitleAtom>
    <TitleAtom type="p" align="center" color="dark" weight="semi-bold" size="x-large" [mobileSize]="22" text="Une question, un devis ?"></TitleAtom>
    <div>
      <TitleAtom type="p" align="center" color="dark" weight="semi-bold" size="x-large" [mobileSize]="22" text="Contactez nous au"></TitleAtom>
      <TitleAtom type="p" align="center" color="orange" weight="semi-bold" size="x-large" [mobileSize]="22" text="04 82 53 72 45"></TitleAtom>
    </div>
    <div class="contact-paragraph">
      <ParagraphAtom align="center" color="dark" size="intermediate" weight="medium" text="Nos conseillers sont disponibles du lundi au vendredi de <strong>9h à 12h30</strong> et de <strong>14h à 17h.</strong>"></ParagraphAtom>
    </div>
    <div class="contact-action">
      <ButtonAtom color="yellow" [style]="'basic'" text="Être rappelé" icon="phone-dark" (click)="handleCallModal()"></ButtonAtom>
      <ButtonAtom color="yellow" [style]="'basic'" text="Envoyer un message" icon="message-dark" (click)="hideContactModal = false"></ButtonAtom>
    </div>
    <ul>
      <li>
        <CardMeeting imageCloudinary="homepage/image_8_occitanie_woxbwt.jpg" localisation="Auvergne-Rhône-Alpes" text="Conseillers en Energie Solaire" (click)="handleMeetingModal()"></CardMeeting>
      </li>
      <li>
        <CardMeeting imageCloudinary="homepage/image_7_auvergne_kk20ov.jpg" localisation="Occitanie" text="Conseillers en Energie Solaire" (click)="handleMeetingModal()"></CardMeeting>
      </li>
      <li>
        <CardMeeting imageCloudinary="homepage/image_11_provence_ommnim_wpqv84.jpg" localisation="Provence-Alpes-Côte d’Azur" text="Conseillers en Energie Solaire" (click)="handleMeetingModal()"></CardMeeting>
      </li>
      <li>
        <CardMeeting imageCloudinary="homepage/image_10_nouvelleaquitaine_derkgk.jpg" localisation="Nouvelle aquitaine" text="Conseillers en Energie Solaire" (click)="handleMeetingModal()"></CardMeeting>
      </li>
    </ul>
  </div>


  <!-- POURQUOI MONABEE -->

  <div id="choisir-monabee" class="choisir-monabee-container">

    <div class="choisir-monabee-text">
      <div class="choisir-monabee-logo" *ngIf="!isMobile">
        <Image image="logo" type="image" width="131px" mobileWidth="131px" ></Image>

      </div>
      <TitleAtom type="p"   color="dark" weight="semi-bold" size="x-large" [mobileSize]="22" text="Pourquoi choisir Monabee ?"></TitleAtom>
      <TitleAtom type="p"   color="dark" weight="semi-bold" size="x-large" [mobileSize]="22" text="Parce que Monabee n’est pas qu’un installateur de panneaux…"></TitleAtom>

    </div>

    <div class="choisir-monabee-content">
      <div class="choisir-monabee-list">
        <details #detailElement open>
          <summary>Installateur de confiance
              <span class="arrow"> <img class="icon"  [src]="'../../../assets/images/icons/arrow-bottom.svg'"  > </span>
          </summary>
          <ul>
            <li><span class="circle">1</span>12 ans d’expertise</li>
            <li><span class="circle">2</span>Présidence Aura Digital Solaire</li>
            <li><span class="circle">3</span>Engagement dans les réseaux d’excellence</li>
            <li><span class="circle">4</span>1 client sur 2 nous recommande une personne de son entourage</li>
          </ul>
        </details>
        <details #detailElement>
          <summary>Installation optimisée
            <span class="arrow"> <img class="icon" [src]="'../../../assets/images/icons/arrow-bottom.svg'"> </span>
          </summary>
          <ul>
            <li><span class="circle">1</span>Le meilleur taux d’autoconsommation</li>
            <li><span class="circle">2</span>Dimensionnement sur-mesure</li>
            <li><span class="circle">3</span>Domotique intelligente & pilotage</li>
          </ul>
        </details>
        <details #detailElement>
          <summary>Qualité
            <span class="arrow"> <img class="icon" [src]="'../../../assets/images/icons/arrow-bottom.svg'"> </span>
          </summary>
          <ul>
            <li><span class="circle">1</span>0% de sinistralité</li>
            <li><span class="circle">2</span>Visite technique systématique</li>
            <li><span class="circle">3</span>Equipe de pose interne</li>
            <li><span class="circle">4</span>Remise en état de 5000 installations pendant les premières années « maintenance » de Monabee</li>
          </ul>
        </details>
        <details #detailElement>
          <summary>Société engagée
            <span class="arrow"> <img  class="icon" [src]="'../../../assets/images/icons/arrow-bottom.svg'"> </span>
          </summary>
          <ul>
            <li><span class="circle">1</span>Fabricant de boîtiers de monitoring français</li>
            <li><span class="circle">2</span>Propriétaire de sa propre installation</li>
            <li><span class="circle">3</span>Engagement dans des réseaux pour faire évoluer la réglementation photovoltaïque</li>
            <li><span class="circle">4</span>Mixité au travail</li>
          </ul>
        </details>
        <details #detailElement>
          <summary>Innovation
            <span class="arrow"> <img class="icon" [src]="'../../../assets/images/icons/arrow-bottom.svg'"> </span>
          </summary>
          <ul>
            <li><span class="circle">1</span>Une équipe de R&D et de développements internes</li>
            <li><span class="circle">2</span>Innovations produits et services pour toujours être à la pointe</li>
          </ul>
        </details>
        <details #detailElement>
          <summary>Accompagnement unique
            <span class="arrow"> <img class="icon" [src]="'../../../assets/images/icons/arrow-bottom.svg'"> </span>
          </summary>
          <ul>
            <li><span class="circle">1</span>Garanties pièces, main d’œuvre et déplacement</li>
            <li><span class="circle">2</span>Bilan d’autoconsommation et visite à domicile</li>
            <li><span class="circle">3</span>Maintenance et surveillance proactive à distance</li>
          </ul>
        </details>
      </div>
      <div class="choisir-monabee-image" *ngIf="!isMobile">
        <Image width="600px" mobileWidth="100%" type="image" image="monabee-installation-client-bordeaux" borderRadius="radius"></Image>

      </div>
    </div>
  </div>




  <!-- BEEHIVE -->
  <div id="beehive" class="beehive-container">
    <Image *ngIf="currentWidth > 1000" class="beehive-top-left" type="icon" image="beehive-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="beehive-top-right" type="icon" image="beehive-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" class="beehive-bottom-left" type="icon" image="beehive-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="beehive-bottom-right" type="icon" image="beehive-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" class="beehive-top-left" type="icon" image="beehive-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="beehive-top-right" type="icon" image="beehive-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="beehive-bottom-left" type="icon" image="beehive-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="beehive-bottom-right" type="icon" image="beehive-bottom-right-mobile"></Image>
    <div class="beehive-first wrapper">
      <div class="beehive-heading">
        <TitleAtom type="p" color="yellow" size="small" weight="semi-bold" text="La ruche"></TitleAtom>
        <TitleAtom type="h2" color="light" size="large" weight="semi-bold" text="Monabee, entreprise française d’installation de panneaux solaires, 100% engagée"></TitleAtom>
        <ParagraphAtom color="light" size="intermediate" weight="regular" text="Depuis 2012, Monabee accompagne les particuliers et professionnels dans la production et la gestion de l’énergie solaire. Notre objectif, vous permettre de rentabiliser au mieux votre installation photovoltaïque, grâce à nos conseils sur l’autoconsommation solaire et nos solutions de monitoring. Nos outils simples et pédagogiques vous permettent de maîtriser votre production d’électricité ET d’être acteur de l’énergie solaire produite."></ParagraphAtom>
        <ButtonAtom color="light" [style]="'stroked'" size="default" text="Découvrez l'entreprise Monabee" href="/entreprise"></ButtonAtom>
      </div>
      <Image width="600px" mobileWidth="100%" type="image" imageCloudinary="homepage/image-equipe.jpg" borderRadius="radius"></Image>
    </div>
    <ul class="beehive-list wrapper">
      <li>
        <Image width="56px" type="icon" image="badge-yellow"></Image>
        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="medium" text="Plus de 11 ans d’expertise dans le domaine de l’énergie solaire"></TitleAtom>
        <ParagraphAtom color="grey-dark" weight="regular" size="intermediate" text="Depuis 2012, Monabee rapproche les lieux de production d’énergie solaire et les consommateurs. Monabee participe ainsi à la réduction du recours aux énergies fossiles et - in fine – de l’impact environnemental de l’activité humaine."></ParagraphAtom>
      </li>
      <li>
        <Image width="56px" mobileWidth="72px" type="icon" image="tool-yellow"></Image>
        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="medium" text="Fabricant d’une solution de pilotage Française"></TitleAtom>
        <ParagraphAtom color="grey-dark" weight="regular" size="intermediate" text="En 2017, Monabee innove à travers plusieurs outils de pilotage en temps réel et d’optimisation de sa consommation d’énergie solaire. Solutions de domotique solaire fabriquées à Lyon."></ParagraphAtom>
      </li>
      <li>
        <Image width="56px" mobileWidth="72px" type="icon" image="team-yellow"></Image>
        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="medium" text="Une équipe engagée pour vous accompagner"></TitleAtom>
        <ParagraphAtom color="grey-dark" weight="regular" size="intermediate" text="Les collaborateurs Monabee sont responsables, transparents et honnêtes. Ils placent le respect des clients au cœur de leurs actions. Pas de fausses promesses."></ParagraphAtom>
      </li>
    </ul>
<!--    <div class="beehive-bottom">-->
<!--      <span>3 452 888</span>-->
<!--      <TitleAtom color="light" weight="semi-bold" size="intermediate" text="kW verts produits par nos clients Monabee"></TitleAtom>-->
<!--    </div>-->
  </div>

  <!-- GUIDES -->
  <div id="guides" class="guides-container">
    <TitleAtom align="center" type="h2" size="tiny" [mobileSize]="16" weight="semi-bold" color="orange" text="Guides, articles et conseils sur l’énergie solaire photovoltaïque"></TitleAtom>
    <TitleAtom align="center"  size="x-large" [mobileSize]="19" weight="semi-bold" color="dark" text="On vous explique tout sur l’autoconsommation solaire - Guides gratuits à télécharger"></TitleAtom>
    <ul>
      <li *ngFor="let guide of guides">
        <Content imageExternal="{{ guide.data.image.url }}" type="guide" title="{{ guide.data.title }}" textHeadline="Guide complet" text="{{ guide.data.summary }}" href="/guides/{{ guide.data.slug }}"></Content>
      </li>
    </ul>
  </div>

  <!-- ADVICES -->
  <div id="advices" class="advices-container">
    <TitleAtom align="center" type="h3" size="x-large" [mobileSize]="22" weight="semi-bold" color="dark" text="Nos conseils et actualités sur la production d'énergie solaire"></TitleAtom>
    <div class="advices-articles-container">
      <ul>
        <li *ngFor="let article of articles">
          <a routerLink="/blog/{{ article.data.slug }}">
            <CardArticle imageExternal="{{ article.data.image.url }}" date="{{ article.data.published_at || '' | date: 'dd MMMM YYYY': '': 'fr-FR' }}" title="{{ article.data.title }}" text="{{ article.data.summary }}"></CardArticle>
          </a>
        </li>
      </ul>
    </div>
    <ButtonAtom color="yellow" href="/blog" [style]="'basic'" text="Découvrir nos articles"></ButtonAtom>
  </div>

  <!-- THEY TALK ABOUT US -->
  <div id="talkAboutUs" *ngIf="this.currentWidth > 1024" class="talk-about-us-container">
    <Image class="talk-about-top-left" type="icon" image="talk-about-top-left"></Image>
    <Image class="talk-about-bottom-left" type="icon" image="talk-about-bottom-left"></Image>
    <Image class="talk-about-bottom-right" type="icon" image="talk-about-bottom-right"></Image>
    <Image class="talk-about-top-right" type="icon" image="talk-about-top-right"></Image>
    <TitleAtom color="dark" weight="semi-bold" size="heading" text="Ils parlent de nous"></TitleAtom>
    <ul>
      <li>
        <Image type="image" image="liberation" width="146.9px"></Image>
      </li>
      <li>
        <Image type="image" image="capital" width="132.45px"></Image>
      </li>
      <li>
        <Image type="image" image="bfm" width="53.78px"></Image>
      </li>
      <li>
        <Image type="image" image="les-echos" width="166.06px"></Image>
      </li>
      <li>
        <Image type="image" image="tribune" width="118.86px"></Image>
      </li>
    </ul>
  </div>
  <div *ngIf="currentWidth < 1024" class="talk-about-us-container-mobile">
    <Image class="talk-about-top-left-mobile" type="icon" image="talk-about-top-left-mobile"></Image>
    <Image class="talk-about-bottom-left-mobile" type="icon" image="talk-about-bottom-left-mobile"></Image>
    <Image class="talk-about-bottom-right-mobile" type="icon" image="talk-about-bottom-right-mobile"></Image>
    <Image class="talk-about-top-right-mobile" type="icon" image="talk-about-top-right-mobile"></Image>
    <TitleAtom class="talk-about-title" align="center" color="dark" weight="semi-bold" size="heading" [mobileSize]="22" text="Ils parlent de nous"></TitleAtom>
    <div class="logo-container">
      <ul class="logo-list">
        <li>
          <Image type="image" image="liberation" width="146.9px" mobileWidth="146.9px"></Image>
        </li>
        <li>
          <Image type="image" image="capital" width="132.45px" mobileWidth="132.45px"></Image>
        </li>
        <li>
          <Image type="image" image="bfm" width="53.78px" mobileWidth="53.78px"></Image>
        </li>
        <li>
          <Image type="image" image="les-echos" width="166.06px" mobileWidth="166.06px"></Image>
        </li>
        <li>
          <Image type="image" image="tribune" width="118.86px" mobileWidth="118.86px"></Image>
        </li>
        <li>
          <Image type="image" image="liberation" width="146.9px" mobileWidth="146.9px"></Image>
        </li>
        <li>
          <Image type="image" image="capital" width="132.45px" mobileWidth="132.45px"></Image>
        </li>
        <li>
          <Image type="image" image="bfm" width="53.78px" mobileWidth="53.78px"></Image>
        </li>
        <li>
          <Image type="image" image="les-echos" width="166.06px" mobileWidth="166.06px"></Image>
        </li>
        <li>
          <Image type="image" image="tribune" width="118.86px" mobileWidth="118.86px"></Image>
        </li>
      </ul>
    </div>
  </div>
  <!-- FAQ -->
  <div id="faq" class="faq-container">
    <TitleAtom type="h2" size="tiny" [mobileSize]="16" align="center" weight="semi-bold" color="orange" text="FAQ sur les installations photovoltaïques"></TitleAtom>
    <TitleAtom size="x-large" [mobileSize]="22" weight="semi-bold" color="dark" text="Questions fréquentes"></TitleAtom>
    <ul>
      <li *ngFor="let faq of faqs">
        <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
      </li>
    </ul>
    <ButtonAtom href="/faq" color="yellow" [style]="'basic'" text="Tout voir"></ButtonAtom>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

</div>

<!-- Contact modal -->
<ContactMessage cardType="message" title="Discutons ensemble" btnText="Envoyer ma demande" userType="particulier" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
<ContactCall [(isHidden)]="hideCallModal"></ContactCall>
<ContactMeeting [(isHidden)]="hideMeetingModal"></ContactMeeting>
