import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  endpoint = environment.api;

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Extract the data of each response
   */
  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  /**
   * Handle error of the api calls
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      // throw new Error(error.error.code);

      return throwError(error);
    };
  }

  /**
   * Get request
   * @param route The route to request
   * @param params The query params to add
   * @param isTokenRequired Is the token is required during the call
   */
  public get(route: string, params: any = {}, isTokenRequired = false): Observable<any> {
    const url = `${this.endpoint}/${route}`;
    const token = "";
    let queryParams = new HttpParams();

    // Prepare header
    const headers: any = {
      'Content-Type':  'application/json'
    };

    if (isTokenRequired) {
      headers.Authorization = token;
    }

    // Prepare query params
    if (Object.keys(params).length > 0) {
      for (const key of Object.keys(params)) {
        queryParams = queryParams.append(key, params[key]);
      }
    }

    const httpOptions = {
      headers: new HttpHeaders(headers),
      params: queryParams
    };

    return this.http.get<any>(url, httpOptions).pipe(
      tap((response) => {
      }),
      catchError(this.handleError<any>('[GET]'))
    );
  }

  /**
   * Get request
   * @param route The route to request
   * @param params The query params to add
   * @param isTokenRequired Is the token is required during the call
   */
  public getExternal(url: string, params: any = {}, isTokenRequired = false): Observable<any> {
    const token = "";
    let queryParams = new HttpParams();

    // Prepare header
    const headers: any = {
      'Content-Type':  'application/json'
    };

    if (isTokenRequired) {
      headers.Authorization = token;
    }

    // Prepare query params
    if (Object.keys(params).length > 0) {
      for (const key of Object.keys(params)) {
        queryParams = queryParams.append(key, params[key]);
      }
    }

    const httpOptions = {
      headers: new HttpHeaders(headers),
      params: queryParams
    };

    return this.http.get<any>(url, httpOptions).pipe(
      tap((response) => {
      }),
      catchError(this.handleError<any>('[GET]'))
    );
  }

  /**
   * Delete (use to delete resources)
   * @param route The route to request
   * @param params The query params to add
   * @param isTokenRequired Is the token is required during the call
   */
  public delete(route: string, params = {}, isTokenRequired = false): Observable<any> {
    const url = `${this.endpoint}/${route}`;
    const token = "";

    const headers: any = {
      'Content-Type':  'application/json'
    };

    if (isTokenRequired) {
      headers.Authorization = token;
    }

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    return this.http.delete<any>(url, httpOptions).pipe(
      tap((response) => {
      }),
      catchError(this.handleError<any>('[DELETE]'))
    );
  }

  /**
   * Post (use to create resources)
   * @param route The route to request
   * @param body The data to pass to the call
   * @param isTokenRequired Is the token is required during the call
   */
  public post(route: string, body = {}, isTokenRequired = false): Observable<any> {
    const url = `${this.endpoint}/${route}`;
    const token = "";

    const headers: any = {
      'Content-Type':  'application/json'
    };

    if (isTokenRequired) {
      headers.Authorization = token;
    }

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    return this.http.post<any>(url, JSON.stringify(body), httpOptions).pipe(
      tap((response) => {
      }),
      catchError(this.handleError<any>('[POST]'))
    );
  }

  /**
   * Post (use to create resources)
   * @param url The url
   * @param body The data to pass to the call
   * @param isTokenRequired Is the token is required during the call
   */
  public postForm(url: string, body: FormData, isTokenRequired = false): Observable<any> {
    const token = "";

    const headers: any = {
      // 'Content-Type': 'multipart/form-data'
    };

    if (isTokenRequired) {
      headers.Authorization = token;
    }

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    return this.http.post<any>(url, body, httpOptions).pipe(
      tap((response) => {
      }),
      catchError(this.handleError<any>('[POST]'))
    );
  }


  /**
   * Patch (use to update resources
   * @param route The route to request
   * @param body The data to pass to the call
   * @param isTokenRequired Is the token is required during the call
   */
  public patch(route: string, body = {}, isTokenRequired = false): Observable<any> {
    const url = `${this.endpoint}/${route}`;
    const token = "";

    const headers: any = {
      'Content-Type':  'application/json'
    };

    if (isTokenRequired) {
      headers.Authorization = token;
    }

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    return this.http.patch<any>(url, JSON.stringify(body), httpOptions).pipe(
      tap((response) => {
      }),
      catchError(this.handleError<any>('[PATCH]'))
    );
  }
}
