<div class="blog-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Témoignages'}]"></Breadcrumb>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="blog-content">
    <div class="first-section">
      <TitleAtom type="h1" text="Nos témoignages clients" align="center" color="dark" size="x-large" weight="semi-bold"></TitleAtom>
      <ParagraphAtom size="intermediate" weight="regular" align="center" text="Retrouvez les avis de nos clients sur leur installation de panneaux solaires"></ParagraphAtom>
    </div>

    <!-- HEADLINE -->
    <div class="second-section" *ngIf="articleHeadline !== null">
      <div class="headline">
        <Image imageExternal="{{ articleHeadline.data.image.url }}" alt="{{ articleHeadline.data.image.alt }}" width="808px" mobileWidth="100%" borderRadius="radius"></Image>
        <TitleAtom type="h3" text="{{ articleHeadline.data.title }}" color="light" size="large" weight="semi-bold"></TitleAtom>
      </div>

      <div class="popular">
        <TitleAtom type="h3" text="Les derniers témoignages" color="dark" size="medium" weight="semi-bold"></TitleAtom>
        <ul>
          <ng-container *ngFor="let article of articlesPopular">
            <li>

              <a routerLink="/temoignage-client/{{ article.data.slug }}">
                <Image imageExternal="{{ article.data.image.url }}" alt="{{ article.data.image.alt }}" width="104px" mobileWidth="104px" borderRadius="radius-half"></Image>
                <TitleAtom type="h4" text="{{ article.data.title }}" align="left" color="dark" size="tiny" weight="semi-bold"></TitleAtom>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <div class="third-section" *ngIf="articles.length > 0">
      <ul>
        <ng-container *ngFor="let article of articles">
          <li>
            <a routerLink="/temoignage-client/{{ article.data.slug }}">
              <CardArticle imageExternal="{{ article.data.image.url }}" date="{{ article.data.published_at || '' | date: 'dd MMMM YYYY': '': 'fr-FR' }}" title="{{ article.data.title }}" text="{{ article.data.summary }}"></CardArticle>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>

    <!-- TODO: DELETE -->
    <!-- FORM -->
    <div class="prismic-form-bottom" *ngIf="formType === FORM_TYPE.CONTACT">
      <TitleAtom align="center" *ngIf="isMobile" color="dark" weight="semi-bold" size="intermediate" text="Discutons ensemble"></TitleAtom>
      <ContactMessage title="Je veux être contacté" btnText="Envoyer ma demande"></ContactMessage>
    </div>
    <div  class="prismic-form-bottom" *ngIf="formType === FORM_TYPE.MEETING">
      <CardInline [style]="'basic'" image="appointment" title="Prendre un rendez-vous téléphonique" btnText="Prendre rendez-vous" btnIcon="calendar" (btnClick)="handleMeetingModal()" text="Un appel dure 30 min en moyenne. Vous souhaitez être rappelé à une date/heure précise car vous avez des questions ? Un Conseiller en Energie Solaire vous répond et vous conseille sur votre projet."></CardInline>
      <ContactMeeting [(isHidden)]="hideMeetingModal"></ContactMeeting>
    </div>
    <div class="prismic-form-bottom" *ngIf="formType === FORM_TYPE.SIMULATOR">
      <SearchSimulation type="simulation" icon="pinned" text="Simuler mon installation solaire" placeholder="Saisissez votre adresse"></SearchSimulation>
    </div>

  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
