import {Component, Inject, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import { CmsJobType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import { Meta, Title } from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-hr-list',
  templateUrl: './hr-list.component.html',
  styleUrls: ['./hr-list.component.scss']
})
export class HrListComponent implements OnInit {

  /* The current category */
  public jobs: Array<CmsJobType> | null = null;

  /* Slider step count */
  public stepCount: number = 1;

  /* Items of the slider */
  public items: Array<{image?: string, imageCloudinary?: string, title: string, text: string}> = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cms: CmsService,
    private ngZone: NgZone,
    private titleService: Title,
    private metaService: Meta
  ) { }

  /* The width of the user window */
  public currentWidth = 2000;

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.currentWidth = window.innerWidth;
    }

    // Set title and meta description
    this.titleService.setTitle('Recrutement secteur solaire : Rejoignez Monabee');
    this.metaService.updateTag({ name: 'description', content: "Travailler chez Monabee, c'est de l'énergie positive. Découvrez notre entreprise et nos offres d'emploi !" });

    // Get Popular articles
    this.cms.retrieve("[[at(document.type, \"job\")]]", "[my.job.title]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.jobs = content.results;
      });
    });

    this.items = [
      {
        imageCloudinary: "recrutement/Recrutement_BE_elmcrs.jpg",
        title: "Le bureau d'étude",
        text: "Le bureau d’étude est en lien étroit avec les techniciens des visites techniques. Ensemble, ils valident la faisabilité du projet solaire.\n« Ce que j’aime, c’est que nous proposons une visite technique physique, c’est une preuve de qualité ! » Pierre D."
      },
      {
        imageCloudinary: "recrutement/Recrutement_support_jepyos.jpg",
        title: "Service support",
        text: "Le service support répond à toutes les questions techniques de nos clients. Il prend le temps de traiter chaque demande !\n« Nous accompagnons nos clients dans la durée, c’est un vrai gage de qualité dont nous sommes fiers » Clément B."
      },
      {
        imageCloudinary: "recrutement/Recrutement_administratif_yxohzu.jpg",
        title: "Service administratif et financier",
        text: "Le service administratif et financier s’occupe de l'ensemble des services généraux, il est garant de la pérennité financière de Monabee.\n« Des prévisionnels aux bilans, nous traitons l'ensemble des opérations avec la plus grande attention » Céline T."
      },
      // {
      //   imageCloudinary: "recrutement/image_3_incarnent1_jwfltw.jpg",
      //   title: "Ressources humaines",
      //   text: "Le service ressources humaines vous accompagne dès le recrutement et tout au long de votre vie professionnelle chez Monabee (onboarding, plan de carrière et formation).\n« Nos collaborateurs sont libres d’entreprendre ! » Hélène T."
      // },
      {
        imageCloudinary: "recrutement/Recrutement_clara-vianney_odbcdi.jpg",
        title: "CODIR",
        text: "Le CODIR a pour objectif de donner la vision stratégique de Monabee. Innovations et plans de développement sont les maîtres mots dans ces réunions mensuelles.\n« Le client est au cœur de nos préoccupations tout au long de son dossier. Nous réfléchissons aux meilleurs moyens de le servir au quotidien et d'assurer un service de qualité du début à la fin » Augustin R."
      },
      {
        imageCloudinary: "recrutement/Recrutement_commerce_adby1v.jpg",
        title: "Service commercial",
        text: "L’équipe commerciale, déployée sur les régions Auvergne-Rhône-Alpes, PACA, Occitanie et Nouvelle Aquitaine répond aux besoins des clients.\n« Ce que j’aime dans mon métier, c’est que chaque jour, ce sont de nouvelles rencontres » Christophe L."
      },
      {
        imageCloudinary: "recrutement/Recrutement_com-mkg_siqr7c.jpg",
        title: "Service communication et marketing",
        text: "Le service com & marketing travaille au quotidien dans l’amélioration de la satisfaction de nos clients, et comment mobiliser les prospects autour de la transition énergétique.\n« C’est notre rôle d’apporter des explications concrètes sur l’énergie solaire et son pilotage » Apolline J."
      },
      {
        imageCloudinary: "recrutement/Recrutement_ADV_q5axcj.jpg",
        title: "Service administration des ventes",
        text: "Le service administration des ventes est en contact avec tous les intermédiaires administratifs. Il facilite l’avancée des dossiers.\n« C’est gratifiant de voir que certains dossiers, d’abord refusés par les mairies, sont finalement acceptés. Les projets de nos clients prennent vie ! » Ornella C."
      }
    ]
  }

  /**
   * Function to up the step in slider
   */
  public nextStep(): void {
    if (this.stepCount < 3) {
      this.stepCount++;
    } else {
      this.stepCount = 1;
    }
  }

  /**
   * Function to return to the previous step in slider
   */
  public previousStep(): void {
    if (this.stepCount > 1) {
      this.stepCount--;
    } else {
      this.stepCount = 3;
    }
  }

  /**
   * Function to scroll to an element
   * @param id
   */
  public scrollTo(id: string) {
    const element = document.querySelector(`#${id}`);

    if (element) {
      const topPos = element.getBoundingClientRect().top + window.pageYOffset

      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth' // smooth scroll
      });
    }
  }
}
