import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'InputAtom',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {

  @ViewChild('input', { static: true }) inputRef!: ElementRef<HTMLInputElement>;

  /** Value of the input */
  @Input() value: any = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  /** Icon of input */
  @Input() icon: string = '';

  /** Name of the input */
  @Input() name = '';

  /** Placeholder on input */
  @Input() placeholder: string = '';

  /** Color of input */
  @Input() color: string = '';

  /** Style of input */
  @Input() style: string = '';

  /** Type of the input */
  @Input() type: string = '';

  /** Maxlength of the input */
  @Input() maxlength: number | null = null;

  /** Pattern of the input */
  @Input() pattern: string = '';

  /** Pattern of the input */
  @Input() autocomplete: string = '';

  // Regex for validation
  // private emailRegex: string = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$';
  private emailRegex: string = '';
  private phoneRegex: string = '[0-9]*';
  private nameRegex: string = '^[a-zA-Z]*$';


  constructor() {}

  ngOnInit(): void {
    if (this.type === 'email') {
      this.pattern = this.emailRegex;
      this.autocomplete = "username";
    }
    else if (this.type === 'tel' || this.type === 'number') {
      this.pattern = this.phoneRegex;
      this.autocomplete = "tel";
    }
    else if (this.type === 'name') {
      this.pattern = this.nameRegex;
      this.autocomplete = "family-name";
    }
  }

  /**
   * When the input value change
   */
  public onChange(value: any) {
    this.valueChange.emit(value);
  }

  /**
   * When the input is for a phoneNumber
   */
  public onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    if ((this.type === 'tel' || this.type === 'number') && this.pattern) {
      target.value = target.value.replace(/[^0-9]/g, '');
    }
    else if (this.type === 'name' && this.pattern) {
      target.value = target.value.replace(/[^a-zA-Z]/g, '');
    }
  }

  public get classes(): Array<string> {
    return [`${this.style}-${this.color}`];
  }


}
