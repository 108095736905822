import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'TextareaAtom',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @ViewChild('textarea', { static: true }) inputRef!: ElementRef<HTMLInputElement>;

  /** Value of the input */
  @Input() value: any = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  /** Characters limit */
  @Input() maxlength?: number;

  /** Characters available */
  @Output() remainingCharacters?: number;

  constructor() { }

  ngOnInit(): void {
    if (this.maxlength) {
      this.remainingCharacters = this.maxlength;
    }
  }

  /**
   * When the input value change
   */
  public onChange(value: any) {
    this.valueChange.emit(value);
    if (this.maxlength) {
      this.remainingCharacters = this.maxlength - value.length;
    }
  }

}
