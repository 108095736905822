import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'CheckboxAtom',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {

  @ViewChild('input', { static: true }) inputRef!: ElementRef<HTMLInputElement>;

  /** Value of the input */
  @Input() value: any = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  /** Name of the input */
  @Input() name = '';

  /** Label of the input */
  @Input() label = '';

  /** Style of input */
  @Input() style: string = '';

  constructor() {}

  ngOnInit(): void {

  }

  /**
   * When the input value change
   */
  public onChange(value: any) {
    this.valueChange.emit(value);
  }

  public get classes(): Array<string> {
    return [`${this.style}`];
  }


}


