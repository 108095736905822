<div [ngClass]="{
    'navbar': true,
    'sticky': sticky,
    'standalone': router.url.includes('simulateur-panneau-solaire') || router.url.includes('recrutement/offres/postuler')
}" *ngIf="isMobile === false">
  <div class="navbar-component">
    <div *ngIf="!router.url.includes('simulateur-panneau-solaire') && !router.url.includes('recrutement/offres/postuler')" class="navbar-list">
      <Image image="logo" type="icon" width="158px" (click)="this.router.navigate(['/'])"></Image>
      <ul>
        <li *ngFor="let item of cmsData?.data?.menu_items_list; let i = index">
          <ng-container *ngIf="item.menu_main_first_title || item.menu_main_second_title">
            <div (mouseenter)="removeHiddenClass(i)" (click)="handleMainItemClick(i, item.menu_main_link!.url)">
              <ButtonAtom
                [text]="item.menu_main_first_title"
                [text2]="item.menu_main_second_title"
                [typeRow]="'doubleRow'"
                [style]="'clear'"
                color="dark">
              </ButtonAtom>
            </div>
          </ng-container>
          <!-- NAVBAR-LINKS 1 -->
          <div class="navbar-links" *ngIf="item.submenu_one_title || item.submenu_two_title || item.submenu_three_title ||
            item.submenu_four_title || item.submenu_five_title || item.submenu_six_title"      [ngClass]="{'first': i === 0 || hasCardMethod(item), 'general': i !== 0 && !hasCardMethod(item)}">
            <div>
              <ul>
                <li *ngIf="item.submenu_one_title">
                  <a (click)="closeNavbarLinks($event)" [routerLink]="item.submenu_one_link?.url">
                    <div>
                      <Image [imageExternal]="item.submenu_one_img.url" type="icon" width="36px"></Image>
                    </div>
                    <div>
                      <ParagraphAtom [text]="item.submenu_one_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom [text]="item.submenu_one_subtitle" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
                <li *ngIf="item.submenu_two_title">
                  <a (click)="closeNavbarLinks($event)" [routerLink]="item.submenu_two_link?.url">
                    <div>
                      <Image [imageExternal]="item.submenu_two_img.url" type="icon" width="36px"></Image>
                    </div>
                    <div>
                      <ParagraphAtom [text]="item.submenu_two_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom [text]="item.submenu_two_subtitle" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
                <li *ngIf="item.submenu_three_title">
                  <a (click)="closeNavbarLinks($event)" [routerLink]="item.submenu_three_link?.url">
                    <div>
                      <Image [imageExternal]="item.submenu_three_img.url" type="icon" width="36px"></Image>
                    </div>
                    <div>
                      <ParagraphAtom [text]="item.submenu_three_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom [text]="item.submenu_three_subtitle" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
                <li *ngIf="item.submenu_four_title">
                  <a (click)="closeNavbarLinks($event)" [routerLink]="item.submenu_four_link?.url">
                    <div>
                      <Image [imageExternal]="item.submenu_four_img.url" type="icon" width="36px"></Image>
                    </div>
                    <div>
                      <ParagraphAtom [text]="item.submenu_four_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom [text]="item.submenu_four_subtitle" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
                <li *ngIf="item.submenu_five_title">
                  <a (click)="closeNavbarLinks($event)" [routerLink]="item.submenu_five_link?.url">
                    <div>
                      <Image [imageExternal]="item.submenu_five_img.url" type="icon" width="36px"></Image>
                    </div>
                    <div>
                      <ParagraphAtom [text]="item.submenu_five_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom [text]="item.submenu_five_subtitle" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
                <li *ngIf="item.submenu_six_title">
                  <a (click)="closeNavbarLinks($event)" [routerLink]="item.submenu_six_link?.url">
                    <div>
                      <Image [imageExternal]="item.submenu_six_img.url" type="icon" width="36px"></Image>
                    </div>
                    <div>
                      <ParagraphAtom [text]="item.submenu_six_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom [text]="item.submenu_six_subtitle" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="image-domotique card-methode-monabee" style="width:100%" *ngIf="item.submenu_card_img?.url && item.submenu_card_title && item.submenu_card_btn_link?.url && item.submenu_card_btn_text">
                  <Image type="image" borderRadius="radius" width="288px" height="143px" [imageExternal]="item.submenu_card_img.url" ></Image>
                  <div class="card-methode-monabee-paragraph">
                    <ParagraphAtom [text]="item.submenu_card_title" color="dark" weight="medium-weight" size="medium" align="left"></ParagraphAtom>
                  </div>
                  <div class="card-methode-monabee-button">
                    <ButtonAtom  [href]="item.submenu_card_btn_link.url"  size="small" [text]="item.submenu_card_btn_text" [style]="'stroked'" color="light"></ButtonAtom>
                  </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div *ngIf="!router.url.includes('simulateur-panneau-solaire') && !router.url.includes('recrutement/offres/postuler')" class="navbar-btn-container">
      <ButtonAtom href="/contact" size="small" text="Contact" [style]="'stroked'" color="light"></ButtonAtom>
      <ButtonAtom href="/simulateur-panneau-solaire" size="small" text="Simulateur" [style]="'basic'" color="yellow"></ButtonAtom>
    </div>

    <!-- SIMULATOR NAVBAR -->
    <div *ngIf="router.url.includes('simulateur-panneau-solaire')" class="navbar-list-simulator">
      <Image image="logo" type="image" width="158px" href="/" (click)="this.router.navigate(['/'])"></Image>
      <TitleAtom type="h1" size="tiny" weight="medium-weight" text="Simulateur de panneaux solaires" ></TitleAtom>
    </div>

    <!-- APPLYING NAVBAR -->
    <div *ngIf="router.url.includes('recrutement/offres/postuler')" class="navbar-list-simulator">
      <Image image="logo" type="image" width="158px" (click)="this.router.navigate(['/'])"></Image>
      <ButtonAtom [style]="'clear'" color="dark" iconRight="close-dark" text="Fermer" href="/recrutement"></ButtonAtom>
    </div>

  </div>
</div>


<!-- MOBILE NAVBAR -->
<div [ngClass]="this.sticky === true ? 'sticky' : ''" class="navbar-component-mobile" *ngIf="isMobile === true">

  <div class="navbar-mobile-main">
    <Image image="logo" type="image" width="131px" mobileWidth="131px" (click)="this.router.navigate(['/'])"></Image>
    <Image image="menu" type="icon" width="24px" (click)="openMenu()"></Image>
  </div>

</div>

<!-- MOBILE MENU -->
<div *ngIf="this.menuIsOpen === true" class="navbar-mobile-menu">
  <div class="menu-header">
    <TitleAtom size="small" weight="semi-bold" text="Menu"></TitleAtom>
    <Image image="close-dark" type="icon" width="14px" mobileWidth="14px" (click)="closeMenu()"></Image>
  </div>
  <ul class="menu-navigation">
    <li *ngFor="let item of cmsData?.data?.menu_items_list; let i = index">
      <div (click)="handleMainItemClick(i, item.menu_main_link.url)">
        <ButtonAtom [text]="item.menu_main_first_title" [text2]="item.menu_main_second_title" [typeRow]="'doubleRow'" [style]="'clear'" color="dark"></ButtonAtom>
        <Image type="icon" [image]="isMenuItemOpen(i) ? 'arrow-top-dark' : 'arrow-bottom-dark'" width="9px" mobileWidth="9px"></Image>
      </div>

      <!-- Submenus -->
      <ul *ngIf="(item.submenu_one_title || item.submenu_two_title || item.submenu_three_title ||
                  item.submenu_four_title || item.submenu_five_title || item.submenu_six_title) && isMenuItemOpen(i)">
        <li *ngIf="item.submenu_one_title">
          <a (click)="closeMenu()" [routerLink]="item.submenu_one_link?.url">
            <Image [imageExternal]="item.submenu_one_img.url" type="icon" width="36px" mobileWidth="36px"></Image>
            <div>
              <ParagraphAtom [text]="item.submenu_one_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom [text]="item.submenu_one_subtitle" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li *ngIf="item.submenu_two_title">
          <a (click)="closeMenu()" [routerLink]="item.submenu_two_link?.url">
            <Image [imageExternal]="item.submenu_two_img.url" type="icon" width="36px" mobileWidth="36px"></Image>
            <div>
              <ParagraphAtom [text]="item.submenu_two_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom [text]="item.submenu_two_subtitle" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li *ngIf="item.submenu_three_title">
          <a (click)="closeMenu()" [routerLink]="item.submenu_three_link?.url">
            <Image [imageExternal]="item.submenu_three_img.url" type="icon" width="36px" mobileWidth="36px"></Image>
            <div>
              <ParagraphAtom [text]="item.submenu_three_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom [text]="item.submenu_three_subtitle" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li *ngIf="item.submenu_four_title">
          <a (click)="closeMenu()" [routerLink]="item.submenu_four_link?.url">
            <Image [imageExternal]="item.submenu_four_img.url" type="icon" width="36px" mobileWidth="36px"></Image>
            <div>
              <ParagraphAtom [text]="item.submenu_four_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom [text]="item.submenu_four_subtitle" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li *ngIf="item.submenu_five_title">
          <a (click)="closeMenu()" [routerLink]="item.submenu_five_link?.url">
            <Image [imageExternal]="item.submenu_five_img.url" type="icon" width="36px" mobileWidth="36px"></Image>
            <div>
              <ParagraphAtom [text]="item.submenu_five_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom [text]="item.submenu_five_subtitle" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li *ngIf="item.submenu_six_title">
          <a (click)="closeMenu()" [routerLink]="item.submenu_six_link?.url">
            <Image [imageExternal]="item.submenu_six_img.url" type="icon" width="36px" mobileWidth="36px"></Image>
            <div>
              <ParagraphAtom [text]="item.submenu_six_title" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom [text]="item.submenu_six_subtitle" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
      </ul>

      <!-- Card submenu : hidden if submenu is closed -->
      <div class="image-domotique card-methode-monabee"
           *ngIf="isMenuItemOpen(i) && item.submenu_card_img?.url && item.submenu_card_title && item.submenu_card_btn_link?.url && item.submenu_card_btn_text">
        <Image type="image" borderRadius="radius" width="100%" height="auto" [imageExternal]="item.submenu_card_img.url"></Image>
        <div class="card-methode-monabee-paragraph">
          <ParagraphAtom [text]="item.submenu_card_title" color="dark" weight="medium-weight" size="medium" align="left"></ParagraphAtom>
        </div>
        <div class="card-methode-monabee-button">
          <ButtonAtom [href]="item.submenu_card_btn_link.url" size="small" [text]="item.submenu_card_btn_text" [style]="'stroked'" color="light"></ButtonAtom>
        </div>
      </div>
    </li>
  </ul>
</div>
