import {Component, Inject, Renderer2, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { CmsFaqType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import { StructuredDataService } from "../../services/structured-data.service";
import {isPlatformBrowser, DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqPageComponent implements OnInit {

  /** ID of the selected faq **/
  public selectedFaq: string = "A propos de Monabee";

  /** The faq from the CMS **/
  public faqs: Array<CmsFaqType> = [];

  /** The search query **/
  public search: string = "";

  /** The width of the user window */
  public windowWidth: number = 2000;

  /** If a search is in progress **/
  public isSearching: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private metaService: Meta,
    private cms: CmsService,
    private ngZone: NgZone,
    private structuredDataService: StructuredDataService,

  ) { }

  ngOnInit(): void {
    // Set title and meta description
    this.titleService.setTitle('FAQ panneaux solaires photovoltaiques en autoconsommation - Monabee');
    this.metaService.updateTag({ name: 'description', content: 'FAQ  sur nos installations de panneaux solaires photovoltaiques. Garantie, aides financières, autoconsommation... Découvrez les réponses à vos questions.' });

    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidth = window.innerWidth;
    }

    // Get faq
    this.cms.retrieve("[[at(document.type, \"faq\")]]", "[my.faq.title]", 1, 100).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.faqs = content.results;

        // Add structured data
        this.structuredDataService.addFaq(this.faqs);
      });
    });
  }

  public onSelectedValueChange(value: string): void {
    this.selectedFaq = value;
  }

  /**
   * Change the faq selected
   */
  public setSelectedFaq(id: string): void {
    this.selectedFaq = id;
  }

  /**
   * When the user search a word
   */
  public onSearch(search: string): void {
    this.search = search;
    this.isSearching = !!search;
  }

  /**
   * Check if the body of the faq contains the search
   * @param faq
   */
  public doesBodyContainSearch(faq: CmsFaqType): boolean {
    return faq.data.body.some(item => item.text && item.text.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
  }

}
