import { Component, NgZone, OnInit } from '@angular/core';
import { CmsGuideType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-guide-list',
  templateUrl: './guide-list.component.html',
  styleUrls: ['./guide-list.component.scss']
})
export class GuideListComponent implements OnInit {

  /* The current category */
  public guides: Array<CmsGuideType> | null = null;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private cms: CmsService,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
    // Set title and meta description
    this.titleService.setTitle("Nos guides sur l'énergie solaire - Monabee");
    this.metaService.updateTag({ name: 'description', content: "Guides à télécharger gratuitemenet sur l'énergie solaire : comment chauffer sa piscine et recharger son voiture électrique grâce à ses panneaux solaires."});

    // Get Guides
    this.cms.retrieve("[[at(document.type, \"guide\")]]", "[my.job.title]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.guides = content.results;
      });
    });
  }
}
