<div class="faq-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'FAQ', link: '/faq'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom [mobileSize]="16" type="h1" text="FAQ" color="dark" size="medium" weight="bold"></TitleAtom>
      <TitleAtom type="h3" text="Questions fréquentes" color="dark" size="heading" weight="semi-bold"></TitleAtom>
      <ParagraphAtom align="center" size="large" weight="regular" text="Découvrez les réponses à vos questions concernant votre projet solaire : service client, garanties et aides, autoconsommation, panneaux photovoltaïques..."></ParagraphAtom>

      <SearchSimulation type="faq" sizeBtn="{{this.windowWidth > 1024 ? 'tiny' : 'default'}}" icon="search-yellow" text="ok" placeholder="Rechercher" (searchEvent)="onSearch($event)"></SearchSimulation>

    </div>
  </div>

  <!-- BOTTOM PART -->
  <div class="faq-content">
    <!-- FAQ SELECTION -->
    <ul class="selection-desktop"  *ngIf="!isSearching">
      <li [ngClass]="selectedFaq === 'A propos de Monabee' ? 'yellow' : 'grey-light'" (click)="setSelectedFaq('A propos de Monabee')">
        <TitleAtom color="dark" type="h2" size="nano" weight="semi-bold" text="À propos de Monabee"></TitleAtom>
      </li>
      <li [ngClass]="selectedFaq === 'Service clients' ? 'yellow' : 'grey-light'" (click)="setSelectedFaq('Service clients')">
        <TitleAtom color="dark" type="h2" size="nano" weight="semi-bold" text="Service clients"></TitleAtom>
      </li>
      <li [ngClass]="selectedFaq === 'Garanties & aides financières' ? 'yellow' : 'grey-light'" (click)="setSelectedFaq('Garanties & aides financières')">
        <TitleAtom color="dark" type="h2" size="nano" weight="semi-bold" text="Garanties & aides financières"></TitleAtom>
      </li>
      <li [ngClass]="selectedFaq === 'Panneaux photovoltaïques & bornes' ? 'yellow' : 'grey-light'" (click)="setSelectedFaq('Panneaux photovoltaïques & bornes')">
        <TitleAtom color="dark" type="h2" size="nano" weight="semi-bold" text="Panneaux photovoltaïques & bornes"></TitleAtom>
      </li>
      <li [ngClass]="selectedFaq === 'Autoconsommation' ? 'yellow' : 'grey-light'" (click)="setSelectedFaq('Autoconsommation')">
        <TitleAtom color="dark" type="h2" size="nano" weight="semi-bold" text="Autoconsommation"></TitleAtom>
      </li>
    </ul>

    <!-- FAQ SELECTION MOBILE -->
    <SelectAtom class="selection-mobile"  *ngIf="!isSearching" [options]="[{value: 'A propos de Monabee', label: 'À propos de Monabee'}, {value: 'Service clients', label: 'Service clients'}, {value: 'Garanties & aides financières', label: 'Garanties & aides financières'}, {value: 'Panneaux photovoltaïques & bornes', label: 'Panneaux photovoltaïques & borne'}, {value: 'Autoconsommation', label: 'Autcoconsommation'}]" [(value)]="selectedFaq"></SelectAtom>

    <!-- FAQ -->
    <ul class="faq-list-container">
      <ng-container *ngFor="let faq of faqs">
        <li *ngIf="(faq.data.category === selectedFaq && search === '') || (search !== '' && faq.data.title.toLowerCase().indexOf(search.toLowerCase()) !== -1) || (search !== '' && doesBodyContainSearch(faq))">
          <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
        </li>
      </ng-container>
    </ul>

  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
