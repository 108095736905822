<div class="commitments-container">
  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Nos engagements'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom [mobileSize]="16" align="center" type="p" text="Nos engagements" color="dark" size="medium" weight="bold"></TitleAtom>
      <TitleAtom align="center" type="h1" text="Vous accompagner vers l’autonomie énergétique grâce à l’autoconsommation" color="dark" size="heading" weight="semi-bold"></TitleAtom>
    </div>
  </div>


  <!-- OUR REASON FOR BEING -->
  <div class="reason-container wrapper">
    <div class="reason-content">
      <div>
        <div class="reason-title">
          <TitleAtom [mobileSize]="16" text="Notre raison d’être" color="orange" size="tiny" weight="semi-bold"></TitleAtom>
          <TitleAtom [mobileSize]="22" type="h2" text="Monabee, une entreprise engagée pour un avenir meilleur" color="dark" size="x-large" weight="semi-bold"></TitleAtom>
        </div>
        <ParagraphAtom text="Nous développons des outils et services innovants pour rendre les consommateurs acteurs de la transition énergétique. Nous proposons des outils connectés à votre installation solaire. Ils vous permettent d'autoconsommer au mieux votre énergie produite. L'objectif ? Consommer moins, plus propre et local et se rapprocher au maximum de l'autonomie énergétique." color="dark" size="intermediate" weight="regular"></ParagraphAtom>
      </div>
      <VideoYoutubeAtom video="https://www.youtube.com/embed/FMc9oGKF35s" width="600px" height="325px" widthMobile="100%" heightMobile="194px"></VideoYoutubeAtom>
    </div>
  </div>

  <!-- OUR VALUES -->
  <div class="values-container wrapper">
    <TitleAtom [mobileSize]="16" align="center" text="Nos valeurs" color="orange" size="tiny" weight="semi-bold"></TitleAtom>
    <TitleAtom [mobileSize]="22" align="center" type="h2" text="Chez Monabee, nous incarnons des valeurs qui nous rendent uniques" color="dark" size="x-large" weight="semi-bold"></TitleAtom>
    <ul>
      <li>
        <div class="value-card-content">
          <Image type="icon" width="28px" image="medal"></Image>
          <TitleAtom [mobileSize]="24" weight="semi-bold" size="intermediate" text="Innovation"></TitleAtom>
          <ParagraphAtom weight="regular" size="intermediate" text="L’innovation est une façon d’être et de penser. Nous n'hésitons pas à sortir du cadre pour proposer des solutions inexploitées. Ce cheminement continu nous amène à faire preuve d’audace pour développer de nouveaux produits ou services pour nos clients tout en améliorant sans cesse l’existant."></ParagraphAtom>
        </div>
      </li>
      <li>
        <div class="value-card-content-large">
          <Image type="icon" width="28px" image="teamwork"></Image>
          <TitleAtom [mobileSize]="24" weight="semi-bold" size="intermediate" text="Bienveillance"></TitleAtom>
          <ParagraphAtom weight="regular" size="intermediate" text="Le respect de nos clients, collaborateurs et partenaires est essentiel. Pas de fausses promesses ni de coups bas, nous sommes responsables, transparents et honnêtes. Nous souhaitons développer et commercialiser des produits utiles et au prix juste."></ParagraphAtom>
        </div>
      </li>
      <li>
        <div class="value-card-content-large">
          <Image type="icon" width="28px" image="teamwork"></Image>
          <TitleAtom [mobileSize]="24" weight="semi-bold" size="intermediate" text="Collectif"></TitleAtom>
          <ParagraphAtom weight="regular" size="intermediate" text="Au siège et sur le terrain, nous sommes une équipe soudée. Nous valorisons l'entraide, le soutien et la confiance pour avancer. Notre mantra ? Personne n'a le monopole des bonnes idées."></ParagraphAtom>
        </div>
      </li>
      <li>
        <div class="value-card-content">
          <Image type="icon" width="28px" image="medal"></Image>
          <TitleAtom [mobileSize]="24" weight="semi-bold" size="intermediate" text="Exigence"></TitleAtom>
          <ParagraphAtom weight="regular" size="intermediate" text="Nos solutions sont innovantes, performantes, précises et permettent des rendements importants en terme d'autoconsommation solaire. L'exigence est aussi humaine. Nous visons un haut niveau de service et de satisfaction pour nos clients."></ParagraphAtom>
        </div>
      </li>
    </ul>
  </div>

  <!-- OUR COMMITMENTS -->
  <div class="engagements-container">

    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-left" image="apply-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-right" image="apply-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-left" image="apply-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-right" image="apply-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right-mobile"></Image>

    <TitleAtom [mobileSize]="24" align="center" type="h2" color="light" weight="semi-bold" size="x-large" text="Nos engagements"></TitleAtom>
    <ParagraphAtom align="center" color="light" weight="regular" size="intermediate" text="Monabee et ses salariés sont engagés. Des actions concrètes derrière les mots !"></ParagraphAtom>
    <ul>
      <li>
        <Image type="image" borderRadius="radius" width="176px" mobileWidth="253px" imageCloudinary="engagements/Nos-engagements_espace-client_bb2amk_qogofe.jpg"></Image>
        <div>
          <ParagraphAtom color="orange" weight="semi-bold" size="intermediate" text="Engagement N°1"></ParagraphAtom>
          <TitleAtom color="dark" weight="semi-bold" size="large" text="Faire ce qu'on dit et dire ce qu'on fait"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Dès notre premier contact, pas de fausses promesses. Lors de votre commande, l’offre et les produits sont détaillés, au prix juste, et nous nous engageons à installer uniquement dans des conditions optimales ! Nous plaçons nos clients au cœur de notre action en vous tenant informé de toutes les étapes de votre dossier. Dès la signature, vous avez accès à votre espace client où vous trouvez l’avancée de votre projet et tous vos documents."></ParagraphAtom>
        </div>
      </li>
      <li>
        <Image type="image" borderRadius="radius" width="176px" mobileWidth="253px" imageCloudinary="engagements/Nos-engagements_fanny_fsc8u9_jnwxg5.jpg"></Image>
        <div>
          <ParagraphAtom color="orange" weight="semi-bold" size="intermediate" text="Engagement N°2"></ParagraphAtom>
          <TitleAtom color="dark" weight="semi-bold" size="large" text="Considérer chaque humain derrière l'écran"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Nous travaillons avec personnalisation et considération. Derrière chaque échange, nos équipes internes prennent le temps de se pencher sur votre cas et de vous proposer une réponse sur-mesure. Tout au long de votre vie de producteur solaire, notre service client est à votre disposition pour vos questions administratives ou techniques. Ils sont joignables par mail, par téléphone, de votre espace client et en physique à notre siège à Lyon ! Vous pouvez même réserver le créneau de votre choix pour un appel privilégié avec l’un de nos techniciens !"></ParagraphAtom>
        </div>
      </li>
      <li>
        <Image type="image" borderRadius="radius" width="176px" mobileWidth="253px" imageCloudinary="engagements/Nos-engagements_formation-commerciale_exuwke_hgvskr.jpg"></Image>
        <div>
          <ParagraphAtom color="orange" weight="semi-bold" size="intermediate" text="Engagement N°3"></ParagraphAtom>
          <TitleAtom color="dark" weight="semi-bold" size="large" text="Promouvoir la culture du résultat"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Il est important pour nous d'être exigeants afin d'atteindre la performance. En conservant un niveau de bienveillance, nous permettons à chaque collaborateur de s'épanouir dans son travail. Nous laissons la place à la prise d’initiative. Afin de faire évoluer chaque collaborateur, nous proposons des formations régulières et diversifiées. La direction et les responsables sont à l’écoute de chacun. Nous sommes convaincus que si le premier maillon effectue bien son travail, le second pourra bien faire le sien, et cela est valable pour toute la ligne. En résulte des clients satisfaits."></ParagraphAtom>
        </div>
      </li>
      <li>
        <Image type="image" borderRadius="radius" width="176px" mobileWidth="253px" imageCloudinary="engagements/Nos-engagements_BE-dimensionnement_xpiuw4_g6hvow.jpg"></Image>
        <div>
          <ParagraphAtom color="orange" weight="semi-bold" size="intermediate" text="Engagement N°4"></ParagraphAtom>
          <TitleAtom color="dark" weight="semi-bold" size="large" text="Persévérer pour rendre accessible le solaire"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Nous sommes une entreprise agile qui fait preuve de persévérance pour porter une offre pertinente et innovante. Chercher des solutions fait partie de notre quotidien. Si une installation sur votre toit ne vous apporte pas un taux d’autoconsommation satisfaisant ou n’est techniquement pas faisable, notre Bureau d’Etudes fera tout pour vous apporter la solution la plus performante. Par exemple, en privilégiant une pose au sol, un carport solaire, une ombrière solaire."></ParagraphAtom>
        </div>
      </li>
      <li>
        <Image type="image" borderRadius="radius" width="176px" mobileWidth="253px" imageCloudinary="engagements/Nos-engagements_innovation_nem3xa_gwl2kk.jpg"></Image>
        <div>
          <ParagraphAtom color="orange" weight="semi-bold" size="intermediate" text="Engagement N°5"></ParagraphAtom>
          <TitleAtom color="dark" weight="semi-bold" size="large" text="Avoir un train d'avance"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="L’innovation est notre façon d’être et de penser. En 2017 lors de la sortie du décret sur l’autoconsommation, nous créons la gamme Optimee’z : 3 boitiers pour vous aider à optimiser votre production solaire. En 2021, nous créons la première borne de recharge solaire du marché ! Nous faisons preuve d’audace pour développer de nouveaux produits et services tout en améliorant sans cesse l’existant. Nous actualisons régulièrement notre offre pour vous proposer les produits dernière génération : panneaux, onduleurs. Cette capacité s’illustre aussi auprès de nos collaborateurs : nous nous intéressons à des profils atypiques, tant que nos valeurs sont partagées. Un de nos meilleurs Conseillers, qui s’occupe maintenant de la formation commerciale, ne connaissait pas le secteur solaire à son arrivée"></ParagraphAtom>
        </div>
      </li>
      <li>
        <Image type="image" borderRadius="radius" width="176px" mobileWidth="253px" imageCloudinary="engagements/Nos-engagements_assistant-lumineux_eutekp_vh6xgx.jpg"></Image>
        <div>
          <ParagraphAtom color="orange" weight="semi-bold" size="intermediate" text="Engagement N°6"></ParagraphAtom>
          <TitleAtom color="dark" weight="semi-bold" size="large" text="Responsabiliser nos consommations et productions"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Soucieux du bien-être d’autrui et de la Planète, nous privilégions la consommation locale et verte : notre gamme Optimee'z est 100% française, nous nous déplaçons avec un utilitaire électrique rechargé au solaire, et nos ordinateurs sont tous reconditionnés. Nous sommes engagés en faveur d'une industrie locale, française, créatrice d'emplois et vertueuse."></ParagraphAtom>
        </div>
      </li>
    </ul>
  </div>

  <!-- CERTIFICATIONS -->
  <div class="certifications-container">
    <div class="certifications-content wrapper">
      <div>
        <TitleAtom align="{{currentWidth > 1000 ? 'left' : 'center'}}" color="dark" size="large" weight="semi-bold" text="Nos labels et certifications"></TitleAtom>
        <ParagraphAtom align="{{currentWidth > 1000 ? 'left' : 'center'}}" color="dark" size="intermediate" weight="regular" text="Ils prouvent nos engagements et notre expertise au quotidien !"></ParagraphAtom>
      </div>
      <ul>
        <li>
          <Image type="icon" width="108px" mobileWidth="108px" image="certification-RGE"></Image>
        </li>
        <li>
          <Image type="icon" width="130px" mobileWidth="130px" image="certification-IRVE"></Image>
        </li>
        <li>
          <Image type="icon" width="74px" mobileWidth="74px" image="certification-lyon"></Image>
        </li>
        <li>
          <Image type="icon" width="84px" mobileWidth="84px" image="certification-abeilles"></Image>
        </li>
      </ul>
    </div>
  </div>

  <!-- SLIDER -->

  <!-- STEP 1 -->
  <ul class="slider-step">
    <ng-container *ngFor="let item of news; let i = index">
      <li class="slider-step1" *ngIf="stepCount === i || currentWidth < 1024">
        <div class="slider-content">
          <Image type="image" borderRadius="radius" width="100%" mobileWidth="100%" [imageExternal]="item.data.image.url" [alt]="item.data.image.alt"></Image>
          <div>
            <TitleAtom align="{{this.currentWidth > 1024 ? 'left' : 'center'}}" type="h3" color="dark" size="large" weight="semi-bold" text="{{ item.data.title }}"></TitleAtom>
            <CmsHtmlRendererAtom [data]="item.data.body" ></CmsHtmlRendererAtom>
          </div>

          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>

          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>
        </div>

        <ul>
          <li *ngFor="let item of news; let i = index">
            <Image type="icon" width="8px" image="{{ stepCount === i ? 'ellipsis-yellow' : 'ellipsis-grey' }}"></Image>
          </li>
        </ul>
      </li>
    </ng-container>

  </ul>

  <!-- LOCALISTATION -->
  <div class="commitments-localisation">
    <Image width="496px" mobileWidth="100%" type="image" imageCloudinary="recrutement/image_6_presence_kkltaw.png"></Image>
    <div>
      <TitleAtom color="orange" size="nano" weight="semi-bold" text="Au plus proche de vous"></TitleAtom>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Notre présence géographique"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Monabee est présent sur plus de 35 départements. Nous venons à votre rencontre autour de La Rochelle, Bordeaux, Toulouse, Marseille et Lyon."></ParagraphAtom>

      <div>
        <TitleAtom [mobileSize]="18" align="left" color="dark" size="small" weight="semi-bold" text="🏡 Vous souhaitez nous rendre visite ?"></TitleAtom>
        <ParagraphAtom align="left" color="dark" size="intermediate" weight="regular" text="Notre siège social se situe <strong><u>4 chemin des Hirondelles à Dardilly,<u></strong> près de Lyon. Si vous passez par là, venez boire un ☕️!"></ParagraphAtom>
      </div>
    </div>
  </div>

  <!-- JOIN THE TEAM -->
  <div class="commitments-hiring-container wrapper">
    <div class="commitments-hiring-card">
      <TitleAtom [mobileSize]="22" align="{{this.currentWidth > 1000 ? 'center' : 'left'}}" color="dark" weight="semi-bold" size="x-large" text="Rejoignez notre équipe !"></TitleAtom>
      <ParagraphAtom align="{{this.currentWidth > 1000 ? 'center' : 'left'}}" color="dark" weight="regular" size="intermediate" text="Contrairement à ce que dit Google, c’est très animé à toute heure chez Monabee ! « La Ruche », c’est le nom que nous avons donné à nos locaux. <strong>Notre quotidien :</strong> de l’énergie positive entre nous, avec nos clients et partenaires, des projets, une ambiance animée… Notre objectif : participer à la transition énergétique à notre échelle, et permettre à nos clients de produire leur propre électricité efficacement."></ParagraphAtom>
      <ButtonAtom class="join-team-btn" [style]="'stroked'" size="default" color="light" text="Découvrir nos offres" href="/recrutement"></ButtonAtom>

      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-top-left" image="join-team-top-left"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-top-right" image="join-team-top-right"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-bottom-left" image="join-team-bottom-left"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-bottom-right" image="join-team-bottom-right"></Image>

      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-top-left" image="join-team-top-left-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-top-right" image="join-team-top-right-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-bottom-left" image="join-team-bottom-left-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-bottom-right" image="join-team-bottom-right-mobile"></Image>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

</div>
