import { Component, OnInit, NgZone } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsGuideType, CmsResponseType } from "../../types/cms.types";
import { ActivatedRoute, Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { ApiService } from "../../services/api.service";
import {AnalyticsService} from "../../services/analytics.service";
import {StorageService} from "../../services/storage.service";

@Component({
    selector: 'app-guide-detail',
    templateUrl: './guide-detail.component.html',
    styleUrls: ['./guide-detail.component.scss']
})
export class GuideDetailPageComponent implements OnInit {

    /* The guide from the CMS */
    public guide: CmsGuideType | null = null;

    /* The email to download */
    public email: string = "";

    /* The consent to download and receive an email */
    public consent: boolean = false;

    /** Is download */
    public isDownload: boolean = false;

    /** Check if input is typing */
    public isEmailInputDirty: boolean = false;


    constructor(
        private storage: StorageService,
        private titleService: Title,
        private metaService: Meta,
        public cms: CmsService,
        public analytics: AnalyticsService,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private router: Router,
        private api: ApiService
    ) { }

    ngOnInit(): void {
        const guideSlug = this.route.snapshot.paramMap.get('guide');

        // Get a guide
        this.cms.retrieve(`[[at(document.type, \"guide\")][at(my.guide.slug, \"${guideSlug}\")]]`, "[my.guide.position]").then((content: CmsResponseType) => {
            this.ngZone.run(() => {
                if (content.results.length === 0) this.router.navigate(['/guides']);

                this.guide = content.results[0];

                // Set title and meta description
                this.titleService.setTitle(this.guide?.data.metadata_title || "");
                this.metaService.updateTag({ name: 'description', content: this.guide?.data.metadata_description || ""});
            });
        });
    }


    /**
     * Function to check if the guide can be downloaded
     */
    public canDownload() {
        return this.consent && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.email);
    }

    /**
     * Function to download file
     */
    public downloadFile() {
        if (!this.canDownload()) return;

        this.analytics.trackForm(this.guide?.data.gtm_id || 0);

        this.api.post(`contact/guide`, {
            email: this.email,
            type: this.guide?.data.mailchimp_id,
            campaign: this.storage.getValue('utm_campaign')
        }).subscribe(() => {
            this.isDownload = true;
            this.email = '';
            this.consent = false;

            setTimeout(() => {
                this.isDownload = false;
            }, 4000)
        });
    }

    /**
     * Function to check if the email is valid
     */
    public checkEmail(): boolean {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.email);
    }

    /** Check if input change */
    public onInputChange(number: number): void {
        if (number === 1) {
            this.isEmailInputDirty = true;
            this.checkEmail();
        }
    }
}
