<div class="simulator-container">

  <!-- NAVBAR -->
  <div>
    <Navbar></Navbar>
    <div class="stepper" *ngIf="stepCount !== 1 && stepCount !== 9">
      <div class="stepper-wrapper">
        <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
      </div>
    </div>
  </div>

  <!-- LEFT SECTION -->
  <div class="simulator-left-section">

    <!-- TOP -->

    <!-- STEP 1 : ENTER YOUR ADDRESS -->
    <div *ngIf="stepCount === 1">
      <!-- SLIDER -->
      <div class="slider">
        <span></span>
      </div>

      <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav">
        <div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span></span>
        </div>
      </div>

      <Image type="icon" image="address-green" width="64px"></Image>
      <TitleAtom [mobileSize]="22" align="center" color="dark" weight="semi-bold" size="intermediate" text="Commencez par nous indiquer votre adresse"></TitleAtom>
      <ParagraphAtom [mobileSize]="14" align="center" color="dark" weight="regular" text="Elle nous permet d’établir le potentiel d’ensoleillement de votre habitation."></ParagraphAtom>
      <div class="simulator-btn-validate">
        <InputAtom #inputAtom mapsAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" placeholder="Saisissez votre adresse" [style]="'basic'" color="light" name="address" [(value)]="formDatas.address" (onSelect)="onPlaceSelected($event)"></InputAtom>

        <div class="offer-warning" *ngIf="this.isPostalCodeAvailable === true">
          <div>
            <Image type="icon" width="32px" image="warning-orange"></Image>
          </div>
          <div class="warning-text">
            <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Monabee n’est pas encore présent dans votre ville. Vous pouvez néanmoins poursuivre votre simulation."></ParagraphAtom>
          </div>
        </div>
        <ButtonAtom [style]="'basic'" color="yellow" text="Valider mon adresse" [isDisabled]="checkAddressEmpty()" (click)="getUserGeocode(); nextStep()"></ButtonAtom>
      </div>
    </div>

    <!-- STEP 2 : PASS TO YOUR ROOF -->
    <ng-container *ngIf="stepCount === 2">
      <ng-container *ngIf="true; then executeFunction"></ng-container>

      <div>
        <!-- SLIDER -->
        <div class="slider">
          <span class="step-2"></span>
        </div>

        <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav">
          <div>
            <div class="mobile-nav-return-btn">
              <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
            </div>
            <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
            <div>
              <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
            </div>
          </div>
          <div class="mobile-slider">
            <span class="step-2"></span>
          </div>
        </div>

        <Image type="icon" image="roof-orange" width="64px"></Image>
        <TitleAtom [mobileSize]="20" align="center" color="dark" weight="semi-bold" size="intermediate" text="Passons à votre toit"></TitleAtom>
        <ParagraphAtom [mobileSize]="14" align="center" color="dark" weight="regular" text="Pour calculer ses dimensions et son orientation, cliquez aux 4 angles de votre toit pour calculer automatiquement sa superficie."></ParagraphAtom>
        <div class="simulator-btn-validate">
          <VideoAtom video="simulateur" format="mp4" width="391px" mobileWidth="100%" [autoplay]="true" ></VideoAtom>
          <div class="offer-warning" *ngIf="this.isPostalCodeAvailable === true">
            <div>
              <Image type="icon" width="32px" image="warning-orange"></Image>
            </div>
            <div class="warning-text">
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Monabee n’est pas encore présent dans votre ville. Vous pouvez néanmoins poursuivre votre simulation."></ParagraphAtom>
            </div>
          </div>
          <ButtonAtom [style]="'basic'" color="yellow" text="J'ai compris, je mesure"  (click)="nextStep()"></ButtonAtom>
        </div>
      </div>
    </ng-container>

    <ng-template #executeFunction>
      {{ clearMarkers() }}
    </ng-template>

    <!-- STEP 3 : MEASURE YOUR ROOF -->
    <div *ngIf="stepCount === 3">
      <!-- SLIDER -->
      <div class="slider">
        <span class="step-3"></span>
      </div>

      <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-3"></span>
        </div>
      </div>

      <Image type="icon" image="roof-orange" width="64px"></Image>
      <TitleAtom [mobileSize]="20" align="center" color="dark" weight="semi-bold" size="intermediate" text="Mesurez votre toit"></TitleAtom>
      <div class="user-address">
        <ParagraphAtom [mobileSize]="14" align="center" color="dark" weight="regular" size="intermediate" text="du <strong>{{formDatas.address}}</strong>"></ParagraphAtom>
      </div>
      <ParagraphAtom [mobileSize]="14" align="center" color="dark" weight="regular" text="Cliquez aux 4 angles de votre toit pour calculer automatiquement sa superficie."></ParagraphAtom>
      <div class="simulator-btn-validate">
        <!--<VideoAtom video="simulateur" format="mp4" width="391px" mobileWidth="100%" [autoplay]="true" ></VideoAtom>-->
        <google-map *ngIf="(stepCount === 3 || stepCount === 4) && this.currentWidth < 1024" height="290px"
                    width="100%"
                    [center]="center"
                    [zoom]="zoom"
                    [mapTypeId]="map"
                    [options]="mapOptions"
                    (mapClick)="onMapClick($event)"
                    (mapInitialized)="onMapReady($event)"
        >
          <div class="roof-area" *ngIf="(this.formDatas.roofArea !== 0) && this.stepCount === 3">
            <span>{{this.formDatas.roofArea}} m2</span>
            <div>
              <ButtonAtom [style]="'clear'" icon="little-close" (click)="clearMarkers()"></ButtonAtom>
            </div>
          </div>
          <div class="map-coordinates-indicator" *ngIf="this.stepCount !== 3">
            <span>Nord</span>
            <span>Est</span>
            <span>Ouest</span>
            <span>Sud</span>
          </div>
        </google-map>
        <ButtonAtom [style]="'basic'" color="yellow" text="Valider" [isDisabled]="this.formDatas.roofArea === 0" (click)="nextStep(); closeMarkers()"></ButtonAtom>
        <ButtonAtom [style]="'underline'" color="dark" text="Passer l'étape" (click)="nextStep()"></ButtonAtom>
      </div>
    </div>

    <!-- STEP 4 : THE ROOF SIDE -->
    <ng-container *ngIf="stepCount === 4">
      <div *ngIf="stepCount === 4">
        <!-- SLIDER -->
        <div class="slider">
          <span class="step-4"></span>
        </div>

        <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav">
          <div>
            <div class="mobile-nav-return-btn">
              <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
            </div>
            <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
            <div>
              <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
            </div>
          </div>
          <div class="mobile-slider">
            <span class="step-4"></span>
          </div>
        </div>

        <Image type="icon" image="roof-orange" width="64px"></Image>
        <TitleAtom [mobileSize]="20" align="center" color="dark" weight="semi-bold" size="intermediate" text="Quel côté de votre toit est le plus exposé au soleil ?"></TitleAtom>
        <div class="simulator-btn-validate">
          <SelectAtom [options]="[{ value: -180, label: 'Nord' }, { value: 0, label: 'Sud' }, { value: -90, label: 'Est' }, { value: 90, label: 'Ouest' }, { value: -45, label: 'Sud-Est' }, { value: -45, label: 'Est - Ouest' }, { value: 45, label: 'Sud - Ouest' }, { value: -135, label: 'Nord - Est' }, { value: 135, label: 'Nord - Ouest' }, { value: -45, label: 'Sud - Est' }]" (valueChange)="onSelectChange(1 ,$event)"></SelectAtom>
          <google-map *ngIf="this.currentWidth < 1024" height="290px"
            width="100%"
            [center]="center"
            [zoom]="zoom"
            [mapTypeId]="map"
            [options]="mapOptions"
            (mapClick)="onMapClick($event)"
            (mapInitialized)="onMapReady($event)"
          >
            <div class="map-coordinates-indicator">
              <span>Nord</span>
              <span>Est</span>
              <span>Ouest</span>
              <span>Sud</span>
            </div>
          </google-map>

          <ButtonAtom [style]="'basic'" color="yellow" text="Valider" (click)="nextStep()"></ButtonAtom>
          <ButtonAtom [style]="'underline'" color="dark" text="Passer l'étape" (click)="nextStep()"></ButtonAtom>
        </div>
      </div>
    </ng-container>

    <!-- STEP 5 : YOUR CONSUMPTION -->
    <div *ngIf="stepCount === 5">
      <!-- SLIDER -->
      <div class="slider">
        <span class="step-5"></span>
      </div>

      <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-5"></span>
        </div>
      </div>

      <Image type="icon" image="cost-orange" width="64px"></Image>
      <TitleAtom [mobileSize]="20" align="center" color="dark" weight="semi-bold" size="intermediate" text="Actuellement, quelle est votre consommation ?"></TitleAtom>
      <div class="simulator-btn-validate">
        <div class="consumption-choice">
          <InputAtom [style]="'basic'" type="number" color="light" name="userConsumption" [(value)]="formDatas.userConsumption" placeholder="Consommation en €"></InputAtom>
          <SelectAtom [options]="[{ value: 'Mensuel', label: 'Mensuel' }, { value: 'Annuel', label: 'Annuel' }]" (valueChange)="onSelectChange(2, $event)"></SelectAtom>
          <!-- <SelectAtom [options]="[{ value: 'Annuel', label: 'Annuel' }]" (selectedValueChange)="onSelectChange(2, $event)"></SelectAtom> -->
        </div>
        <ButtonAtom [style]="'basic'" color="yellow" text="Valider" [isDisabled]="formDatas.userConsumption === ''" (click)="nextStep()"></ButtonAtom>
        <ButtonAtom size="default" [style]="'stroked'" color="light" text="Je ne sais pas" (click)="nextStep()"></ButtonAtom>
      </div>
    </div>

    <!-- STEP 6 : NUMBER OF PEOPLE IN HOME -->
    <div *ngIf="stepCount === 6">
      <!-- SLIDER -->
      <div class="slider">
        <span class="step-6"></span>
      </div>

      <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-6"></span>
        </div>
      </div>

      <Image type="icon" image="family-orange" width="64px"></Image>
      <TitleAtom [mobileSize]="20" align="center" color="dark" weight="semi-bold" size="intermediate" text="Composition du foyer"></TitleAtom>
      <ParagraphAtom [mobileSize]="14" align="center"  weight="regular" size="intermediate" color="dark" text="Nombre de personne dans le foyer"></ParagraphAtom>
      <div class="simulator-btn-validate">
        <div class="simulator-peopleNumber-choice">
          <ul>
            <li>
              <ButtonAtom [style]="'stroked'" color="light" text="1" (click)="setPeopleInHome(1)"></ButtonAtom>
            </li>
            <li>
              <ButtonAtom [style]="'stroked'" color="light" text="2" (click)="setPeopleInHome(2)"></ButtonAtom>
            </li>
            <li>
              <ButtonAtom [style]="'stroked'" color="light" text="3" (click)="setPeopleInHome(3)"></ButtonAtom>
            </li>
            <li>
              <ButtonAtom [style]="'stroked'" color="light" text="4" (click)="setPeopleInHome(4)"></ButtonAtom>
            </li>
          </ul>
          <ButtonAtom size="default" [style]="'stroked'" color="light" text="Plus" (click)="setPeopleInHome(5)"></ButtonAtom>
        </div>
      </div>
    </div>

    <!-- STEP 7 : WHAT WAS YOUR EQUIPMENTS -->
    <div *ngIf="stepCount === 7 || stepCount === 8">
      <!-- SLIDER -->
      <div class="slider">
        <span class="step-7"></span>
      </div>

      <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-7"></span>
        </div>
      </div>

      <Image type="icon" image="charging-station" width="64px" mobileWidth="56px"></Image>
      <TitleAtom [mobileSize]="20" align="center" color="dark" weight="semi-bold" size="intermediate" text="Quels sont vos équipements ?"></TitleAtom>
      <ParagraphAtom [mobileSize]="14" align="center" weight="regular" size="intermediate" color="dark" text="Notre algorithme permet d’optimiser l’auto-consommation de vos équipements."></ParagraphAtom>
      <div class="simulator-btn-validate">
          <ul class="simulator-equipments-list">
            <li [ngClass]="this.electricCar === true ? 'checked' : ''">
              <div>
                <Image width="42px" type="icon" image="electric-car-blue"></Image>
                <ParagraphAtom [mobileSize]="14" color="dark" size="intermediata" weight="semi-bold" text="Véhicule électrique"></ParagraphAtom>
              </div>
              <Toggle (toggleClick)="setSelectedEquipments(1)"></Toggle>
            </li>
            <li [ngClass]="this.pool === true ? 'checked' : ''">
              <div>
                <Image width="42px" type="icon" image="pool-blue"></Image>
                <ParagraphAtom [mobileSize]="14" color="dark" size="intermediata" weight="semi-bold" text="Piscine"></ParagraphAtom>
              </div>
              <Toggle (toggleClick)="setSelectedEquipments(2)"></Toggle>
            </li>
            <li [ngClass]="this.heather === true ? 'checked' : ''">
              <div>
                <Image width="42px" type="icon" image="heater-blue"></Image>
                <ParagraphAtom [mobileSize]="14" color="dark" size="intermediata" weight="semi-bold" text="Chauffage électrique"></ParagraphAtom>
              </div>
              <Toggle (toggleClick)="setSelectedEquipments(3)"></Toggle>
            </li>
            <li [ngClass]="this.waterHeater === true ? 'checked' : ''">
              <div>
                <Image width="42px" type="icon" image="water-heater-blue"></Image>
                <ParagraphAtom [mobileSize]="14" color="dark" size="intermediata" weight="semi-bold" text="Chauffe-eau électrique"></ParagraphAtom>
              </div>
              <Toggle (toggleClick)="setSelectedEquipments(4)"></Toggle>
            </li>
          </ul>
          <ButtonAtom [style]="'basic'" color="yellow" text="Valider" (click)="nextStep()"></ButtonAtom>
          <ButtonAtom size="default" [style]="'stroked'" color="light" text="Je ne sais pas" (click)="nextStep()"></ButtonAtom>
      </div>
    </div>

    <!-- STEP 9 : SUGGESTIONS -->
    <div *ngIf="stepCount === 9">

      <div [ngClass]="this.sticky === true ? 'sticky' : ''" class="mobile-nav mobile-nav-lastStep">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Simuler mon installation"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" (click)="closeSimulator()"></ButtonAtom>
          </div>
        </div>
      </div>

      <Image class="estimate-img-end" type="icon" image="result-orange" width="64px"></Image>
      <TitleAtom [mobileSize]="20" align="center" color="dark" weight="semi-bold" size="intermediate" text="Nos recommandations pour votre besoin"></TitleAtom>
      <div class="estimate-result-info">
        <ParagraphAtom [mobileSize]="14"  align="center" weight="regular" size="intermediate" color="dark" text="Votre consommation actuelle est estimée à"></ParagraphAtom>
        <div>
          <ParagraphAtom [mobileSize]="14" align="center" weight="bold" size="intermediate" color="blue" text="{{ result.estimateConsumption }} Kwh"></ParagraphAtom>
          <ParagraphAtom [mobileSize]="14" align="center" weight="regular" size="intermediate" color="dark" text="pour un budget annuel"></ParagraphAtom>
          <ParagraphAtom [mobileSize]="14" align="center" weight="bold" size="intermediate" color="blue" text="{{ result.estimatePrice }} €"></ParagraphAtom>
        </div>
      </div>
      <ButtonAtom [style]="'underline'" color="dark" text="Modifier les critères" icon="edit" (click)="this.stepCount = 5"></ButtonAtom>
      <div class="simulator-btn-validate">
        <ul class="simulator-suggestions-list">
          <li>
            <Image type="icon" width="48px" image="solar-panel-blue"></Image>
            <div>
              <ParagraphAtom [mobileSize]="14" color="dark" size="intermediate" weight="regular" text="<strong>{{ result.solarPanel }} </strong> solaires photovoltaïques"></ParagraphAtom>
            </div>
          </li>
          <li>
            <Image type="icon" width="48px" image="energy-blue"></Image>
            <div>
              <ParagraphAtom [mobileSize]="14" color="dark" size="intermediate" weight="regular" text="Production annuelle des panneaux <strong>estimée à {{ result.solarPanelProduction }} Kwh</strong>"></ParagraphAtom>
            </div>
          </li>
          <li>
            <Image type="icon" width="48px" image="cost-blue"></Image>
            <div>
              <ParagraphAtom [mobileSize]="14" color="dark" size="intermediate" weight="regular" text="économies potentielles <strong>{{ result.savings }} € / an</strong>"></ParagraphAtom>
            </div>
          </li>
        </ul>
        <ButtonAtom [style]="'basic'" color="yellow" text="Retourner à l'accueil" href="/"></ButtonAtom>
      </div>
    </div>

    <!-- BOTTOM -->
    <div>
      <Image *ngIf="currentWidth > 1000" class="simulator-contact-card" type="icon" image="simulator-contact-card-bottom-right"></Image>
      <div class="simulator-call-title">
        <Image type="icon" width="43.2px" image="help-orange"></Image>
        <ParagraphAtom [mobileSize]="16" color="light" weight="semi-bold" size="intermediate" text="Besoin d’aide ?"></ParagraphAtom>
      </div>
      <ParagraphAtom color="light" size="intermediate" weight="regular" text="Nos Conseillers en Energie Solaire sont disponibles du lundi au vendredi de <strong>9h à 12h30</strong> et de <strong>14h à 17h</strong>"></ParagraphAtom>
      <ButtonAtom size="default" [style]="'stroked'" color="light" text="Être rappelé par un expert" (click)="userGetCall()"></ButtonAtom>
    </div>
  </div>

  <!-- RIGHT SECTION -->
  <div class="simulator-right-section">
    <Image class="simulator-image-illustration" *ngIf="stepCount !== 3 && stepCount !== 4 && stepCount !== 5 && currentWidth > 1024" width="732px" type="image" imageCloudinary="/simulateur/simulateur_nouveau-visuel_ydk529.jpg"></Image>

    <google-map *ngIf="(stepCount === 3 || stepCount === 4) && this.currentWidth > 1024" height="644px"
                width="688.4px"
                [center]="center"
                [zoom]="zoom"
                [mapTypeId]="map"
                [options]="mapOptions"
                (mapClick)="onMapClick($event)"
                (mapInitialized)="onMapReady($event)"
    >
      <div class="roof-area" *ngIf="this.formDatas.roofArea !== 0 && this.stepCount !== 4">
        <span>{{this.formDatas.roofArea}} m2</span>
          <div>
            <ButtonAtom [style]="'clear'" icon="little-close" (click)="clearMarkers()"></ButtonAtom>
          </div>
      </div>
      <div class="map-coordinates-indicator" *ngIf="this.stepCount === 4">
        <span>Nord</span>
        <span>Est</span>
        <span>Ouest</span>
        <span>Sud</span>
      </div>
    </google-map>

    <!-- STEP 5 : INVOICES SELECTION -->
    <div *ngIf="stepCount === 5" class="simulator-right-invoice-type">
     <Image *ngIf="currentWidth > 1000" type="icon" class="simulator-invoices-selection-top-left" image="simulator-invoices-selection-top-left"></Image>
     <Image *ngIf="currentWidth > 1000" type="icon" class="simulator-invoices-selection-top-right" image="simulator-invoices-selection-top-right"></Image>
     <Image *ngIf="currentWidth > 1000" type="icon" class="simulator-invoices-selection-bottom-left" image="simulator-invoices-selection-bottom-left"></Image>
     <Image *ngIf="currentWidth > 1000" type="icon" class="simulator-invoices-selection-bottom-right" image="simulator-invoices-selection-bottom-right"></Image>
      <div>
        <TitleAtom [mobileSize]="20" align="center" color="{{this.currentWidth > 1024 ? 'light' : 'dark'}}" size="medium" weight="semi-bold" text="Astuces !"></TitleAtom>
        <ParagraphAtom [mobileSize]="14" align="center" color="{{this.currentWidth > 1024 ? 'light' : 'dark'}}" size="intermediate" weight="regular" text="Sélectionnez votre fournisseur pour savoir où trouver le montant sur la facture"></ParagraphAtom>
      </div>
      <ul>
        <li (click)="getInvoiceExample(1)">
          <Image type="image" width="71px" image="edf-logo"></Image>
        </li>
        <li (click)="getInvoiceExample(2)">
          <Image type="image" width="104px" image="direct-energie-logo"></Image>
        </li>
        <li (click)="getInvoiceExample(3)">
          <Image type="image" width="131px" image="ekwateur-logo"></Image>
        </li>
        <li (click)="getInvoiceExample(4)">
          <Image type="image" width="115px" image="enercoop-logo"></Image>
        </li>
        <li (click)="getInvoiceExample(5)">
          <Image type="image" width="70px" image="planet-oui-logo"></Image>
        </li>
        <li (click)="getInvoiceExample(6)">
          <Image type="image" width="109px" image="urban-solar-logo"></Image>
        </li>
      </ul>
    </div>

    <!-- CALL-BTN-MOBILE -->
    <div class="mobile-call-container">
      <Image *ngIf="currentWidth < 1000" class="simulator-contact-card" type="icon" image="simulator-contact-card-bottom-right-mobile"></Image>
      <div class="simulator-call-title">
        <Image type="icon" width="43.2px" image="help-orange"></Image>
        <ParagraphAtom [mobileSize]="16" color="light" weight="semi-bold" size="intermediate" text="Besoin d’aide ?"></ParagraphAtom>
      </div>
      <ParagraphAtom color="light" size="intermediate" weight="regular" text="Nos Conseillers en Energie Solaire sont disponibles du lundi au vendredi de <strong>9h à 12h30</strong> et de <strong>14h à 17h</strong>"></ParagraphAtom>
      <ButtonAtom size="default" [style]="'stroked'" color="light" text="Être rappelé par un expert" (click)="userGetCall()"></ButtonAtom>
    </div>

    <!-- GARANTEE -->
    <div>
      <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="semi-bold" text="Monabee garantit votre sérénité"></ParagraphAtom>
      <ul>
        <li>
          <Image type="icon" width="43.2px" image="certif-blue"></Image>
          <ParagraphAtom [mobileSize]="15" color="dark" weight="semi-bold" size="intermediate" text="Installateurs certifiés QUALI PV RGE"></ParagraphAtom>
        </li>
        <li>
          <Image type="icon" width="43.2px" image="articles-blue"></Image>
          <ParagraphAtom [mobileSize]="15" color="dark" weight="semi-bold" size="intermediate" text="Gestion administrative"></ParagraphAtom>
        </li>
      </ul>
      <ul>
        <li>
          <Image type="icon" width="43.2px" image="garantee-blue"></Image>
          <ParagraphAtom [mobileSize]="15" color="dark" weight="semi-bold" size="intermediate" text="Panneaux garantis jusqu’a 25 ans"></ParagraphAtom>
        </li>
        <li>
          <Image type="icon" width="43.2px" image="charging-station-blue"></Image>
          <ParagraphAtom [mobileSize]="15" color="dark" weight="semi-bold" size="intermediate" text="Pilotage des équipements"></ParagraphAtom>
        </li>
      </ul>
    </div>
  </div>

  <!-- CALL MODAL-STEP-1 -->

  <ContactCall [(isHidden)]="!this.getCall"></ContactCall>

  <!-- INVOICE MODAL -->

  <!-- EDF -->
  <div *ngIf="showEdfInvoice === true" class="invoice-modal-container">

    <!-- MOBILE -->
    <div class="mobile-invoice-header" *ngIf="this.currentWidth < 1024">
      <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="medium-weight" text="Trouver sa facture"></ParagraphAtom>
      <div class="close" (click)="getInvoiceExample(1)">
        <Image type="icon" image="close-dark" width="14px" ></Image>
      </div>
    </div>

    <div class="invoice-example-section">

      <!-- DESKTOP -->
      <div class="desktop-invoice-header" *ngIf="this.currentWidth > 1024">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="getInvoiceExample(1)"></ButtonAtom>
      </div>

      <!-- MOBILE -->
<!--      <div class="mobile-invoice-header" *ngIf="this.currentWidth < 1024">
        <ParagraphAtom color="dark" size="large" weight="medium-weight" text="Trouver sa facture"></ParagraphAtom>
        <div class="close" (click)="getInvoiceExample(1)">
          <Image type="icon" image="close-dark" width="14px" ></Image>
        </div>
      </div>-->

      <Image width="518px" mobileWidth="100%" type="image" image="facture-edf-monabee"></Image>
    </div>
  </div>

  <!-- DIRECT ENERGIE -->
  <div *ngIf="showDirectInvoice === true" class="invoice-modal-container">

    <!-- MOBILE -->
    <div class="mobile-invoice-header" *ngIf="this.currentWidth < 1024">
      <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="medium-weight" text="Trouver sa facture"></ParagraphAtom>
      <div class="close" (click)="getInvoiceExample(2)">
        <Image type="icon" image="close-dark" width="14px" ></Image>
      </div>
    </div>

    <div class="invoice-example-section">

      <!-- DESKTOP -->
      <div class="desktop-invoice-header" *ngIf="this.currentWidth > 1024">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="getInvoiceExample(2)"></ButtonAtom>
      </div>

      <Image width="518px" mobileWidth="100%" type="image" image="facture-direct-energie-monabee"></Image>
    </div>
  </div>

  <!-- EKWATEUR -->
  <div *ngIf="showEkwateurInvoice === true" class="invoice-modal-container">

    <!-- MOBILE -->
    <div class="mobile-invoice-header" *ngIf="this.currentWidth < 1024">
      <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="medium-weight" text="Trouver sa facture"></ParagraphAtom>
      <div class="close" (click)="getInvoiceExample(3)">
        <Image type="icon" image="close-dark" width="14px" ></Image>
      </div>
    </div>

    <div class="invoice-example-section">

      <!-- DESKTOP -->
      <div class="desktop-invoice-header" *ngIf="this.currentWidth > 1024">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="getInvoiceExample(3)"></ButtonAtom>
      </div>

      <Image width="518px" mobileWidth="100%" type="image" image="facture-ekwateur-monabee"></Image>
    </div>
  </div>

  <!-- ENERCOOP -->
  <div *ngIf="showEnercoopInvoice === true" class="invoice-modal-container">

    <!-- MOBILE -->
    <div class="mobile-invoice-header" *ngIf="this.currentWidth < 1024">
      <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="medium-weight" text="Trouver sa facture"></ParagraphAtom>
      <div class="close" (click)="getInvoiceExample(4)">
        <Image type="icon" image="close-dark" width="14px" ></Image>
      </div>
    </div>

    <div class="invoice-example-section">

      <!-- DESKTOP -->
      <div class="desktop-invoice-header" *ngIf="this.currentWidth > 1024">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="getInvoiceExample(4)"></ButtonAtom>
      </div>

      <Image width="518px" mobileWidth="100%" type="image" image="facture-enercoop-monabee"></Image>
    </div>
  </div>

  <!-- PLANET -->
  <div *ngIf="showPlanetInvoice === true" class="invoice-modal-container">

    <!-- MOBILE -->
    <div class="mobile-invoice-header" *ngIf="this.currentWidth < 1024">
      <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="medium-weight" text="Trouver sa facture"></ParagraphAtom>
      <div class="close" (click)="getInvoiceExample(5)">
        <Image type="icon" image="close-dark" width="14px" ></Image>
      </div>
    </div>

    <div class="invoice-example-section">

      <!-- DESKTOP -->
      <div class="desktop-invoice-header" *ngIf="this.currentWidth > 1024">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="getInvoiceExample(5)"></ButtonAtom>
      </div>

      <Image width="518px" mobileWidth="100%" type="image" image="facture-planete-oui-monabee"></Image>
    </div>
  </div>

  <!-- URBAN -->
  <div *ngIf="showUrbanInvoice === true" class="invoice-modal-container">

    <!-- MOBILE -->
    <div class="mobile-invoice-header" *ngIf="this.currentWidth < 1024">
      <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="medium-weight" text="Trouver sa facture"></ParagraphAtom>
      <div class="close" (click)="getInvoiceExample(6)">
        <Image type="icon" image="close-dark" width="14px" ></Image>
      </div>
    </div>

    <div class="invoice-example-section">

      <!-- DESKTOP -->
      <div class="desktop-invoice-header" *ngIf="this.currentWidth > 1024">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="getInvoiceExample(6)"></ButtonAtom>
      </div>

      <Image width="518px" mobileWidth="100%" type="image" image="facture-urban-solar-monabee"></Image>
    </div>
  </div>

  <!-- STEP 8 : GET YOUR ESTIMATE -->
  <div *ngIf="stepCount === 8" class="estimate-container">
    <div class="estimate-section">

      <div>
        <!-- METTRE IMG -->
        <Image *ngIf="currentWidth > 1000" type="icon" class="estimate-top-left" image="estimate-top-left"></Image>
        <Image *ngIf="currentWidth > 1000" type="icon" class="estimate-top-right" image="estimate-top-right"></Image>
        <Image *ngIf="currentWidth > 1000" type="icon" class="estimate-bottom-left" image="estimate-bottom-left"></Image>
        <Image *ngIf="currentWidth > 1000" type="icon" class="estimate-bottom-right" image="estimate-bottom-right"></Image>

        <Image *ngIf="currentWidth < 1000" type="icon" class="estimate-top-left" image="estimate-top-left"></Image>
        <Image *ngIf="currentWidth < 1000" type="icon" class="estimate-top-right" image="estimate-top-right"></Image>
        <Image *ngIf="currentWidth < 1000" type="icon" class="estimate-bottom-left" image="estimate-bottom-left"></Image>
        <Image *ngIf="currentWidth < 1000" type="icon" class="estimate-bottom-right" image="estimate-bottom-right"></Image>
        <div class="estimate-close-btn">
          <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeEstimate()"></ButtonAtom>
        </div>
        <TitleAtom class="estimate-modal-title" [mobileSize]="20" align="center" color="light" size="large" weight="semi-bold" text="Obtenir mon estimation"></TitleAtom>
        <ParagraphAtom [mobileSize]="14" align="center" color="light" size="intermediate" weight="regular" text="Pour sauvegarder votre projet, nous vous ferons parvenir notre recommandation par email"></ParagraphAtom>
      </div>

      <ButtonRadio id="simulator-radio" name="simulator-estimate" [style]="'second'" [options]="this.currentWidth > 1024 ? [{value: 'particulier', label: 'Je suis un particulier'}, {value: 'professionnel', label: 'Je suis un professionel'}] : [{value: 'particulier', label: 'Particulier'}, {value: 'professionnel', label: 'Professionnel'}]" selectedOption="particulier" (selectedValueChange)="onSelectChange(3, $event)"></ButtonRadio>

      <ul>
        <li>
          <ParagraphAtom [mobileSize]="14" weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
          <InputAtom placeholder="Nom" [style]="'basic'" color="light" name="username" [(value)]="formDatas.name"></InputAtom>
        </li>
        <li>
          <ParagraphAtom [mobileSize]="14" weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
          <InputAtom (input)="onInputChange(1)" type="email" placeholder="email@example.com" [style]="'basic'" color="{{(formDatas.email === '' || checkEmail()) ? 'light' : 'red'}}" name="email" [(value)]="formDatas.email"></InputAtom>
          <ParagraphAtom [mobileSize]="14" *ngIf="!checkEmail() && isEmailInputDirty && formDatas.email !== ''" color="red" size="small" weight="medium" text="Email invalide."></ParagraphAtom>
        </li>
        <li *ngIf="this.formDatas.userType === 'professionnel'">
          <ParagraphAtom [mobileSize]="14" weight="semi-bold" size="intermediate" text="Votre société*"></ParagraphAtom>
          <InputAtom placeholder="Entreprise" [style]="'basic'" color="light" name="company" [(value)]="formDatas.company"></InputAtom>
        </li>
        <li>
          <ParagraphAtom [mobileSize]="14" weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
          <InputAtom (input)="onInputChange(2)" type="tel" [maxlength]="10" placeholder="0605040302" [style]="'basic'" color="{{formDatas.phone_number === '' || checkPhoneNumber() ? 'light' : 'red'}}" name="phoneNumber" [(value)]="formDatas.phone_number"></InputAtom>
          <ParagraphAtom [mobileSize]="14" *ngIf="!checkPhoneNumber() && isPhoneInputDirty && formDatas.phone_number !== ''" color="red" size="small" weight="medium" text="Numéro de téléphone invalide."></ParagraphAtom>
        </li>
      </ul>

      <div class="opposition-message">
        <ParagraphAtom color="dark" align="left" size="intermediate" weight="regular" text="Vous avez le droit de vous inscrire gratuitement sur la liste d'opposition au démarchage téléphonique."></ParagraphAtom>
      </div>

      <div class="estimate-expert-confirmation">
        <Toggle (toggleClick)="userAcceptedContact()"></Toggle>
        <ParagraphAtom [mobileSize]="14" color="dark" weight="regular" size="intermediate" text="J’autorise un expert Monabee à me contacter pour mon projet"></ParagraphAtom>
      </div>

      <div>
        <ButtonAtom [style]="'basic'" [isDisabled]="this.formDatas.userType === 'professionnel' ? this.formDatas.name === '' || this.formDatas.email === '' ||  checkEmail() === false || this.formDatas.phone_number.length < 10 || this.formDatas.company === '' || this.formDatas.acceptRecall === false :  this.formDatas.name === '' || this.formDatas.email === '' || checkEmail() === false || this.formDatas.phone_number.length < 10 || this.formDatas.acceptRecall === false" color="yellow" text="Voir mon estimation" (click)="nextStep()"></ButtonAtom>
        <ParagraphAtom [mobileSize]="12" color="medium-grey" size="small" weight="regular" text="En poursuivant, j’accepte de transmettre mes données à Monabee pour recevoir une estimation personnalisée concernant l’installation de panneaux solaires. Ces données seront conservées pendant la durée de traitement de votre demande et ne seront en aucun cas transmis à un tiers."></ParagraphAtom>
      </div>

    </div>
  </div>

</div>
