<div class="breadcrumb">
  <ul class="breadcrumb-component">
    <!-- Home -->
    <li class="{{color}}"><a href="/">Monabee</a></li>

    <!-- Links -->
    <ng-container *ngFor="let link of links; let i = index">
      <li><i>/</i></li>
      <li *ngIf="link.link !== undefined" class="{{color}} text"><a [routerLink]="link.link">{{ link.title }}</a></li>
      <li *ngIf="link.link === undefined" class="{{color}} text"><span>{{ link.title }}</span></li>
    </ng-container>
  </ul>
</div>

