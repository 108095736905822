import { Component, OnInit, Input, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";
import { StructuredDataService } from '../../services/structured-data.service';

@Component({
  selector: 'VideoAtom',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  /* The video */
  @Input() video: string = '';

  /* The width for video */
  @Input() width: string = '';

  /* The width for mobile version */
  @Input() mobileWidth: string = '';

  /* The height for video */
  @Input() height: string = '';

  /* The height for mobile version */
  @Input() mobileHeight: string = '';

  /* Format video : mp4, mov etc. */
  @Input() format: string = '';

  /* Border radius of the video */
  @Input() borderRadius: string = '';

  /* Border radius of the video */
  @Input() autoplay: boolean = false;

  /* Select element in the DOM */
  @ViewChild('videoElement') videoElement!: any;
  @ViewChild('playButton') playButton!: any;

  /** The width and height of the window user */
  public windowWidth = 2000;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private structuredDataService: StructuredDataService

  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidth = window.innerWidth;
    }

    this.structuredDataService.addVideo(this.video);

  }

  /* Run or Pause the video on click */
  onVideoClick() {
    if (this.videoElement.nativeElement.paused) {
      this.videoElement.nativeElement.play();
    } else {
      this.videoElement.nativeElement.pause();
    }
  }

  public get classes(): Array<string> {
    return [`video video-${this.borderRadius}`];
  }

}
