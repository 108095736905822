import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ButtonAtom',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  /** Text in Button */
  @Input() text: string = '';


  /** Text2 in Button */
  @Input() text2: string = '';

  @Input() typeRow: string = 'singleRow'; // 'singleRow' ou 'doubleRow'

  /** Image for button */
  @Input() icon: string = '';

  /** Image on the right of the button */
  @Input() iconRight: string = '';

  /** Style of button: clear, stroked, basic */
  @Input() style: string = '';

  /** Size of the button : small, default */
  @Input() size: string = '';

  /** Size of the button : small, default */
  @Input() sizeText: string = 'default';

  /** Color of element: light, dark, yellow, blue */
  @Input() color: string = '';

  /** Link to navigate */
  @Input() href: string = '';

  /** Link to navigate on section */
  @Input() fragmentLink: string = '';

  /** Target of the link */
  @Input() target: string = '_self';

  /** Button is disabled */
  @Input() isDisabled: boolean = false;

  /** Query params of the btn */
  @Input() queryParams: any;

  /** Type of the btn */
  @Input() type: string = '';

  @Input() shareIsHidden = true;

  /** share url */
  @Input() shareUrl: string = '';

  /** share text */
  @Input() shareText: string = 'J’ai trouvé cet article sur Monabee ! ';

  /** Classes for the button */
  public classes: string[] = [];

  safeHtml: SafeHtml | undefined;


  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.classes = this.style !== 'is-link' ? ['button', `${this.style}-${this.color} ${this.size}`, this.icon ? 'has-icon' : ''] : [this.style];
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.text);

  }

  public getShare() {
    this.shareIsHidden = !this.shareIsHidden;

    /* Get the current url */
    this.shareUrl = window.location.href;

  }

  /** Copy to clipboard the url */
  public copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = this.shareUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}
