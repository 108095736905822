import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

// Declare gTM dataLayer array.
declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable({
  providedIn: 'root'
})
export class CloudinaryService {

  constructor(
    private api: ApiService
  ) {
  }

  /**
   * Upload a new file
   * @param file The file to upload
   */
  public async upload(file: File): Promise<string> {
    return new Promise<string>((resolve) => {
      const body = new FormData();
      body.append('file', file);
      body.append('upload_preset', "awvvsc42");

      this.api.postForm(`https://api.cloudinary.com/v1_1/monabee/auto/upload`, body).subscribe((res) => {
        resolve(res.url);
      });
    });
  }

}
