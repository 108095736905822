<div class="apply-container">

  <!-- NAVBAR -->
  <div>
    <Navbar></Navbar>
  </div>

  <!--  BACKGROUND LEFT-->
  <div class="apply-background">
<!--
    <Image type="image" width="100%" image="apply-background"></Image>
-->
  </div>

  <ng-container *ngIf="job && jobs && jobs.length > 0">
    <!-- RIGHT SECTION -->
    <!-- STEP-1 -->
    <div *ngIf="this.stepCount === 1" class="apply-content-container">
      <div class="stepper">
        <span></span>
      </div>

      <div class="mobile-nav">
        <div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Postuler"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" href="/recrutement"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span></span>
        </div>
      </div>

      <div *ngIf="stepCount !== 1" class="return-btn">
        <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
      </div>

      <Image type="image" width="64px" mobileWidth="64px" image="apply-avatar"></Image>
      <TitleAtom [mobileSize]="24" color="dark" weight="semi-bold" size="intermediate" text="Bonjour !"></TitleAtom>
      <div class="apply-step-1">
        <ParagraphAtom align="center" color="dark" weight="medium" size="intermediate" text="Je m’appelle Laure et je suis en charge du recrutement chez Monabee"></ParagraphAtom>
        <div>
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Vous souhaitez postuler pour le poste de :"></ParagraphAtom>
          <SelectAtom *ngIf="this.showInput === false" [options]="arrayJobs" [(value)]="selectedJob" (valueChange)="onSelectChange($event)"></SelectAtom>
          <InputAtom *ngIf="this.showInput === true" [style]="'basic'" color="light" type="" name="job" [(value)]="selectedJob"></InputAtom>
        </div>
      </div>
      <ButtonAtom [isDisabled]="this.selectedJob === ''" [style]="'basic'" color="yellow" text="C'est parti !" (click)="nextStep()"></ButtonAtom>
    </div>

    <!-- STEP-2 -->
    <div *ngIf="this.stepCount === 2" class="apply-content-container">
      <div class="stepper">
        <span class="step-2"></span>
      </div>

      <div class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Postuler"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" href="/recrutement"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-2"></span>
        </div>
      </div>

      <div class="return-btn">
        <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
      </div>

      <Image type="image" width="64px" mobileWidth="64px" image="apply-avatar"></Image>
      <TitleAtom [mobileSize]="24" align="center" color="dark" weight="semi-bold" size="intermediate" text="Commençons par faire connaissance 🙂"></TitleAtom>
      <div class="apply-step-2">
        <ul>
          <li>
            <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre nom* :"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" type="name" name="username" [(value)]="formDatas.name"></InputAtom>
          </li>
          <li>
            <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre prénom* :"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" type="name" name="username" [(value)]="formDatas.firstName"></InputAtom>
          </li>
          <li>
            <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre email* :"></ParagraphAtom>
            <InputAtom (input)="onInputChange(1)" [style]="'basic'" color="{{(formDatas.email === '' || checkEmail()) ? 'light' : 'red'}}" type="email" name="email" [(value)]="formDatas.email"></InputAtom>
            <ParagraphAtom *ngIf="!checkEmail() && isEmailInputDirty && formDatas.email !== ''" color="red" size="small" weight="medium" text="Email invalide."></ParagraphAtom>
          </li>
        </ul>
      </div>
      <ButtonAtom [isDisabled]="this.formDatas.name === '' || this.formDatas.firstName === '' || this.formDatas.email === '' || !this.checkEmail()" [style]="'basic'" color="yellow" text="Continuer" (click)="nextStep()"></ButtonAtom>
    </div>

  <!-- STEP-3 -->
  <ng-container *ngFor="let question of job.data?.questions; let i = index">

    <!-- Question libre -->
    <div  *ngIf="this.stepCount === (3 + i) && question.question_type === 'Réponse libre'" class="apply-content-container">
      <div class="stepper">
        <span class="step-3"></span>
      </div>

      <div class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Postuler"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" href="/recrutement"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-3"></span>
        </div>
      </div>

      <div class="return-btn">
        <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
      </div>

      <Image type="image" width="64px" image="apply-avatar"></Image>
      <TitleAtom [mobileSize]="24" align="center" color="dark" weight="semi-bold" size="intermediate" text="{{question.question_title.replace('{firstName}', this.formDatas.firstName)}}"></TitleAtom>
      <div class="apply-step-3">
        <ParagraphAtom align="center" color="dark" weight="medium" size="intermediate" text="{{question.question_subtitle}}"></ParagraphAtom>

        <div>
          <TextareaAtom [(value)]="this.formDatas.questions[i]" [maxlength]="500" ></TextareaAtom>
        </div>
      </div>
      <ButtonAtom [isDisabled]="this.formDatas.questions[i] === ''" [style]="'basic'" color="yellow" text="Continuer" (click)="nextStep()"></ButtonAtom>
    </div>

    <!-- Question à choix multiple -->
    <div *ngIf="this.stepCount === (3 + i) && question.question_type === 'Choix multiple'" class="apply-content-container">
      <div class="stepper">
        <span class="step-3"></span>
      </div>

      <div class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Postuler"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" href="/recrutement"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-4"></span>
        </div>
      </div>

      <div class="return-btn">
        <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
      </div>

      <Image type="image" width="64px" mobileWidth="64px" image="apply-avatar"></Image>
      <TitleAtom align="center" color="dark" weight="semi-bold" size="intermediate" text="{{question.question_title.replace('{firstName}', this.formDatas.firstName)}}"></TitleAtom>
      <div class="apply-step-4">
        <ParagraphAtom align="center" color="dark" weight="medium" size="intermediate" text="{{question.question_subtitle}}"></ParagraphAtom>

        <ul>
          <li *ngFor="let value of question.question_values.split(';')" (click)="onMultipleChoice(i,value)" [class.selected]="formDatas.questions[i] && formDatas.questions[i].includes(value)">{{ value }}</li>
        </ul>

      </div>
      <ButtonAtom [isDisabled]="formDatas.questions[i] === undefined || formDatas.questions[i].length === 0" [style]="'basic'" color="yellow" text="Continuer" (click)="nextStep()"></ButtonAtom>
    </div>

    <!-- Question à choix unique -->
    <div *ngIf="this.stepCount === (3 + i) && question.question_type === 'Choix unique'" class="apply-content-container">
      <div class="stepper">
        <span class="step-3"></span>
      </div>

      <div class="mobile-nav">
        <div>
          <div class="mobile-nav-return-btn">
            <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
          </div>
          <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Postuler"></ParagraphAtom>
          <div>
            <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" href="/recrutement"></ButtonAtom>
          </div>
        </div>
        <div class="mobile-slider">
          <span class="step-5"></span>
        </div>
      </div>

      <div class="return-btn">
        <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
      </div>

      <Image type="image" width="64px" mobileWidth="64px" image="apply-avatar"></Image>
      <TitleAtom align="center" color="dark" weight="semi-bold" size="intermediate" text="{{ question.question_title.replace('{firstName}', this.formDatas.firstName) }}"></TitleAtom>
      <div class="apply-step-5">
        <ParagraphAtom align="center" color="dark" weight="medium" size="intermediate" text="{{ question.question_subtitle }}"></ParagraphAtom>

        <ul>
          <li *ngFor="let quality of question.question_values.split(';')" (click)="onSingleChoice(i,quality)" [class.selected]="formDatas.questions[i] && formDatas.questions[i].includes(quality)">{{ quality }}</li>
        </ul>

      </div>
      <ButtonAtom [isDisabled]="formDatas.questions[i] === undefined || formDatas.questions[i] === ''" [style]="'basic'" color="yellow" text="Continuer" (click)="nextStep()"></ButtonAtom>
    </div>
  </ng-container>

  <!-- STEP-7 -->
  <div *ngIf="this.stepCount === (3 + this.job.data.questions.length)" class="apply-content-container">
    <div class="stepper">
      <span class="step-6"></span>
    </div>

    <div class="mobile-nav">
      <div>
        <div class="mobile-nav-return-btn">
          <ButtonAtom [style]="'basic'" color="dark" icon="arrow-left-dark" (click)="previousStep()"></ButtonAtom>
        </div>
        <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Postuler"></ParagraphAtom>
        <div>
          <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" href="/recrutement"></ButtonAtom>
        </div>
      </div>
      <div class="mobile-slider">
        <span class="step-7"></span>
      </div>
    </div>

    <div class="return-btn">
      <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
    </div>

    <Image type="image" width="64px" mobileWidth="64px" image="apply-avatar"></Image>
    <TitleAtom [mobileSize]="24" align="center" color="dark" weight="semi-bold" size="intermediate" text="On y est presque !"></TitleAtom>
    <div class="apply-step-7">
      <ParagraphAtom align="center" color="dark" weight="medium" size="intermediate" text="Pour finaliser votre candidature, pensez à nous joindre votre CV"></ParagraphAtom>

      <input type="file" #cvFileInput style="display: none" (change)="onFileSelected(1, $event)"/>
      <ButtonAtom (click)="openFileSelector(1)" [style]="'stroked'" icon="upload" size="default" color="light" text="{{ formDatas.userCV !== '' ? 'CV ajouté' : 'Joindre votre CV' }}"></ButtonAtom>
      <div>
        <ParagraphAtom [mobileSize]="12" color="dark" weight="medium" size="small" text="Format .PDF ou .doc"></ParagraphAtom>
        <ParagraphAtom [mobileSize]="12" color="dark" weight="medium" size="small" text="2 mo max"></ParagraphAtom>
      </div>

      <div class="video-upload-container">
        <div>
          <Image type="icon" width="32px" mobileWidth="32px"  image="play"></Image>
          <div>
            <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Vous souhaitez nous en dire plus ?"></ParagraphAtom>
            <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Alors n’hésitez pas et dites nous tout au travers d’une courte vidéo !"></ParagraphAtom>
          </div>
        </div>
        <div>
          <input type="file" #videoFileInput style="display: none" (change)="onFileSelected(2, $event)"/>
          <Image (click)="openFileSelector(2)" type="image" width="344px" mobileWidth="312px"  image="upload-video"></Image>
        </div>

        <ParagraphAtom *ngIf="formDatas.userVideo !== ''" color="dark" weight="regular" size="intermediate" text="Vidéo ajoutée !"></ParagraphAtom>
      </div>

    </div>
    <ButtonAtom class="cv-validate-btn" [style]="'basic'" color="yellow" text="Je valide ma candidature" (click)="nextStep()"></ButtonAtom>
  </div>

  <!-- STEP-8 -->
  <div *ngIf="this.stepCount === (4 + this.job.data.questions.length)" class="apply-content-container">
    <div class="stepper">
      <span class="step-7"></span>
    </div>

    <div class="mobile-nav">
      <div>
        <ParagraphAtom [mobileSize]="16" color="dark" size="intermediate" weight="semi-bold" text="Postuler"></ParagraphAtom>
        <div>
          <ButtonAtom [style]="'clear'" color="dark" icon="close-dark" href="/recrutement"></ButtonAtom>
        </div>
      </div>
      <div class="mobile-slider">
        <span class="step-8"></span>
      </div>
    </div>

    <div class="return-btn">
      <ButtonAtom text="Retour" [style]="'clear'" color="dark" icon="arrow-left-yellow" (click)="previousStep()"></ButtonAtom>
    </div>

    <div class="validation-avatar">
      <Image type="image" width="64px" mobileWidth="64px" image="apply-avatar"></Image>
      <Image type="icon" width="28px" mobileWidth="28px" image="check-blue"></Image>
    </div>
    <TitleAtom [mobileSize]="24" align="center" color="dark" weight="semi-bold" size="intermediate" text="Merci {{formDatas.firstName}} ! Votre demande de candidature a bien été prise en compte"></TitleAtom>
    <div class="apply-step-8">
      <ParagraphAtom [mobileSize]="14" align="center" color="dark" weight="regular" size="intermediate" text="Notre équipe ressource humaine traite votre candidature au plus vite pour vous apporter une réponse."></ParagraphAtom>
    </div>
    <ButtonAtom [style]="'basic'" color="yellow" text="Retourner à l'accueil" (click)="router.navigate(['/'])"></ButtonAtom>
  </div>

  </ng-container>
</div>
