import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  /**
   * Set a new key into the local storage
   * @param key The key
   * @param value The key
   */
  public setValue(key: string, value: string): void {
      if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(key, value);
      }
  }

  /**
   * Get the value from local storage
   */
  public getValue(key: string): string {
      if (isPlatformBrowser(this.platformId)) {
          return localStorage.getItem(key) || '';
      }
      else return '';
  }
}
