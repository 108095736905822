<div class="history-container">
  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <div class="first-section">

    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Notre histoire'}]"></Breadcrumb>
    </div>

    <div class="herobanner-container">

      <Image *ngIf="currentWidth > 1000" type="icon" class="history-hero-middle-left" image="history-hero-middle-left"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="history-hero-middle-right" image="history-hero-middle-right"></Image>

      <Image *ngIf="currentWidth < 1000" type="icon" class="history-hero-bottom-left" image="history-hero-bottom-left-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="history-hero-bottom-right" image="history-hero-bottom-right-mobile"></Image>

      <TitleAtom class="history-hero-title" align="center" color="yellow" weight="semi-bold" size="small" text="Notre histoire"></TitleAtom>
      <TitleAtom type="h1" align="center" color="light" weight="semi-bold" size="heading" [mobileSize]="28" text="Monabee, une histoire qui s'écrit à chaque instant"></TitleAtom>
    </div>
  </div>

  <!-- 2010 - 2013 -->

  <!-- ILLUSTRATION -->
  <div class="history-illustration wrapper">
    <Image type="image" width="705px" mobileWidth="284px" borderRadius="radius" imageCloudinary="histoire/Notre-histoire_photo-clara-vianney_vz1u5g.jpg"></Image>
    <Image type="image" width="495px" mobileWidth="200px" borderRadius="radius" imageCloudinary="histoire/Notre-histoire_R_D_ngm06v_xnzhtw.jpg"></Image>
    <Image *ngIf="currentWidth < 1000" type="image" width="550px" mobileWidth="330px" borderRadius="radius" imageCloudinary="histoire/Notre-histoire_photo-boitier-clara-vianney-2_q33mcl.jpg"></Image>
  </div>

  <!-- CONTENT 2010-2013 -->
  <div class="history-2010-content wrapper">
    <Image *ngIf="currentWidth > 1000" type="image" borderRadius="radius" width="496px" mobileWidth="0px" imageCloudinary="histoire/Notre-histoire_photo-boitier-clara-vianney-2_q33mcl.jpg"></Image>
    <div>
      <TitleAtom type="h2" color="green" weight="semi-bold" size="xl-heading" [mobileSize]="28" text="2010-2013"></TitleAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="<strong>Vianney & Clara prennent la route ensemble vers le soleil.</strong> Chaque histoire débute par une rencontre. Clara & Vianney ont travaillé sur un projet commun, dans un grand groupe du secteur de l’énergie solaire. C’est le déclic. Ils décident de lancer ensemble Monabee."></ParagraphAtom>
      <TitleAtom color="dark" weight="semi-bold" size="large" [mobileSize]="22" text="+ 3500 heures de recherches pour créer notre différenciation... "></TitleAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="L’objectif à cette époque, et aujourd’hui encore, est de donner les moyens à nos clients de suivre la production de leur installation photovoltaïque. Après des milliers d’heures de R&D, et une première levée de fonds, nous créons notre premier prototype de mesure de consommation et de production solaire. Il porte le nom de Beenet."></ParagraphAtom>
    </div>
  </div>

  <div class="history-2010-info wrapper">
    <!-- CAMEMBERT BOX -->
    <div>
      <Image type="image" borderRadius="radius" width="403px" mobileWidth="230px" imageCloudinary="histoire/Notre-histoire_photo-boitier-camembert_kpjpjm.jpg"></Image>
    </div>

    <!-- QUOTE CARD -->
    <div>
      <div>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote"></Image>
        <ParagraphAtom align="center" color="dark" weight="medium" size="large" text="<i>Pour correspondre à nos valeurs écologiques, nous avions pris le parti de créer notre premier prototype de boîtier en bois. Nos investisseurs nous ont fait comprendre que notre boîtier ressemblait plus à une boîte de camembert qu’un boîtier de monitoring !</i>"></ParagraphAtom>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote-reverse"></Image>
      </div>
      <div>
        <Image type="image" width="48px" mobileWidth="48px" image="vianney-fichet-portrait"></Image>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="<i>Vianney Fichet, Co-fondateur de Monabee</i>"></ParagraphAtom>
      </div>
    </div>
  </div>

  <!-- 2013 - 2015 -->

  <!-- 2013-2015 BANNER -->
  <div class="history-2013-banner">
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2013-top-left" image="2013-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2013-top-right" image="2013-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2013-bottom-left" image="2013-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2013-bottom-right" image="2013-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2013-top-left" image="2013-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2013-top-right" image="2013-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2013-bottom-left" image="2013-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2013-bottom-right" image="2013-bottom-right-mobile"></Image>
    <div>
      <div>
        <TitleAtom type="h2" color="yellow" size="bigger" [mobileSize]="28" weight="semi-bold" text="2013-2015"></TitleAtom>
        <TitleAtom type="h2" color="light" size="large" [mobileSize]="22" weight="semi-bold" text="En 2013, nous équipons nos 100 premiers clients."></TitleAtom>
      </div>
      <div>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="A cette période où l’éco-délinquance est forte, de nombreux particuliers se retrouvent équipés de panneaux solaires sans solution. Nous décidons d’accompagner ces particuliers dans leur installation solaire, via notre solution de monitoring."></ParagraphAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Ils peuvent alors suivre la production de leurs panneaux photovoltaïques grâce à des outils simples et pédagogiques, compréhensibles par tous."></ParagraphAtom>
      </div>
    </div>
  </div>

  <!-- 2013-2015 ILLUSTRATION -->
  <div class="history-2013-illustration wrapper">
    <Image borderRadius="radius" type="image" width="496px" mobileWidth="190px" imageCloudinary="histoire/Nos-engagements_espace-client_bb2amk_s8vyjn.jpg"></Image>
    <Image borderRadius="radius" type="image" width="704px" mobileWidth="300px" imageCloudinary="histoire/Notre-histoire_photo-clara-vianney-3_yuo5zv.jpg"></Image>
    <Image *ngIf="currentWidth < 1000" borderRadius="radius" type="image" width="450px" mobileWidth="200px" imageCloudinary="histoire/Notre-histoire_Gibbs1b_ux8ktj.jpg"></Image>
  </div>

  <!-- 2013-2015 CONTENT -->
  <div class="history-2013-content wrapper">
    <!-- QUOTE CARD -->
    <div>
      <div>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote"></Image>
        <ParagraphAtom align="left" color="dark" weight="medium" size="large" text="<i>Pendant plusieurs années, les locaux n’étaient autres que la maison de Vianney. Et quel moment, lorsque Gibbs (un magnifique berger de podhale de 55 kg) décide de voler le courrier de Monabee sur la table… Pendant 20minutes, Vianney et Mathieu lui ont couru après pour récupérer le fameux courrier ! Qui n’était… qu’une pub.</i>"></ParagraphAtom>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote-reverse"></Image>
      </div>
      <div>
        <Image type="image" width="48px" mobileWidth="48px" image="clara-trevisiol-portrait"></Image>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="<i>Clara Trevisiol, Co-fondatrice de Monabee</i>"></ParagraphAtom>
      </div>
    </div>

    <Image *ngIf="currentWidth > 1000" borderRadius="radius" type="image" width="450px" mobileWidth="0px" imageCloudinary="histoire/Notre-histoire_Gibbs1b_ux8ktj.jpg"></Image>
  </div>


  <!-- 2015 - 2018 -->

  <!-- 2015-2018 BANNER -->
  <div class="history-2015-banner">
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2015-top-left" image="2015-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2015-top-right" image="2015-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2015-bottom-left" image="2015-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2015-bottom-right" image="2015-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2015-top-left" image="2015-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2015-top-right" image="2015-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2015-bottom-left" image="2015-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2015-bottom-right" image="2015-bottom-right-mobile"></Image>
    <div>
      <div>
        <TitleAtom type="h2" color="green" size="bigger" [mobileSize]="28" weight="semi-bold" text="2015-2018"></TitleAtom>
        <TitleAtom type="h2" color="light" size="large" [mobileSize]="22" weight="semi-bold" text="En 2016, nous investissons nos premiers locaux ! le moment de dire au revoir aux bêtises de Gibbs..."></TitleAtom>
      </div>
      <div>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="En 2017, l’autoconsommation d’électricité est dûment autorisée. Nous nous tournons vers une nouvelle clientèle, désireuse de devenir productrice d’énergie. Notre objectif est de promouvoir une vision simple et lisible de l’énergie. Nous souhaitons leur permettre de reprendre la main !"></ParagraphAtom>
      </div>
    </div>
  </div>

  <!-- 2015-2018 ILLUSTRATION -->
  <div class="history-2015-illustration wrapper">
    <Image borderRadius="radius" type="image" width="704px" mobileWidth="300px" imageCloudinary="histoire/Notre-histoire_photo-locaux_qr6mfh.jpg"></Image>
    <Image borderRadius="radius" type="image" width="495px" mobileWidth="190px" imageCloudinary="histoire/Notre-histoire_photo-locaux2_ddax4y_prqqoq.jpg"></Image>
    <Image *ngIf="currentWidth < 1000" borderRadius="radius" type="image" width="496px" mobileWidth="270px" imageCloudinary="histoire/Notre-histoire_Gibbs2_ik6ccq.jpg"></Image>
  </div>

  <!-- 2015-2018 CONTENT -->
  <div class="history-2015-content wrapper">
    <Image *ngIf="currentWidth > 1000" borderRadius="radius" type="image" width="496px" mobileWidth="0px" imageCloudinary="histoire/Notre-histoire_Gibbs2_ik6ccq.jpg"></Image>

    <!-- QUOTE CARD -->
    <div>
      <div>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote"></Image>
        <ParagraphAtom align="center" color="dark" weight="medium" size="large" text="<i>Gibbs, fauteur de troubles ambulant profite des dernières réunions chez Vianney pour manger une de mes paires de chaussures… Cadeau d’au revoir !</i>"></ParagraphAtom>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote-reverse"></Image>
      </div>
      <div>
        <Image type="image" width="48px" mobileWidth="48px" image="clara-trevisiol-portrait"></Image>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="<i>Clara Trevisiol, Co-fondatrice de Monabee</i>"></ParagraphAtom>
      </div>
    </div>
  </div>



  <!-- 2018 - 2020 -->
  <div class="history-2018-banner">
    <Image type="icon" width="510px" mobileWidth="344px" image="fifth"></Image>
    <div>
      <TitleAtom type="h2" color="yellow" weight="semi-bold" size="bigger" [mobileSize]="28" text="2018-2020"></TitleAtom>
      <TitleAtom type="h2" color="light" weight="semi-bold" size="large" [mobileSize]="22" text="Classé 5ème entreprise française et 1ère entreprise de la région Auvergne-Rhône-Alpes en termes d’indices de croissance par « Les Echos » en 2019."></TitleAtom>
    </div>
  </div>

  <div class="history-2018-text wrapper">
    <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Ces années-là sont placées sous le signe de la structuration. Nous obtenons des labels, dont « RGE QualiPV ». Il nous permet d’installer la partie électrique des systèmes photovoltaïques dans le respect des règles."></ParagraphAtom>
    <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Au cœur même des foyers, nous équipons nos clients de dispositifs performants et vulgarisés, à l’image de l’assistant lumineux qui permet de connaître immédiatement l’état de sa centrale solaire et d’identifier le moment le plus opportun pour mettre en route ses équipements."></ParagraphAtom>
  </div>

  <div class="history-2018-illustration wrapper">
    <Image borderRadius="radius" type="image" width="704px" mobileWidth="283px" imageCloudinary="histoire/Notre-histoire_photo-locaux3_ntqelu.jpg"></Image>
    <Image borderRadius="radius" type="image" width="495px" mobileWidth="200px" imageCloudinary="histoire/Notre-histoire_photo-locaux4_ws2pvm_igyent.jpg"></Image>
    <Image *ngIf="currentWidth < 1000" borderRadius="radius" type="image" width="496px" mobileWidth="160px" imageCloudinary="histoire/Notre-histoire_james-bond_duh0us_kx1t4l.jpg"></Image>
  </div>

  <div class="history-2018-quote wrapper">
    <Image *ngIf="currentWidth > 1000" borderRadius="radius" type="image" width="496px" mobileWidth="0px" imageCloudinary="histoire/Notre-histoire_james-bond_duh0us_kx1t4l.jpg"></Image>

    <!-- QUOTE CARD -->
    <div>
      <div>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote"></Image>
        <ParagraphAtom align="left" color="dark" weight="medium" size="large" text="<i>Nous avons recruté James Bond ! <br> Nous avions lancé une offre d’emploi, puis nous avions changé d’avis. Nous n’avions pas donné suite aux candidatures. Quelques mois plus tard, nous décidons finalement de réouvrir le poste. <br> Un candidat postule de nouveau, en falsifiant son identité, pensant que nous ne l’avions pas choisi une première fois. <br> Surprise et étonnement plus tard, nous l’avons recruté, sous sa vraie identité 😉 ! <br> Ce fut l’entretien le plus singulier de l’histoire de Monabee.</i>"></ParagraphAtom>
        <Image type="icon" width="40px" mobileWidth="32px" image="quote-reverse"></Image>
      </div>
      <div>
        <Image type="image" width="48px" mobileWidth="48px" image="clara-trevisiol-portrait"></Image>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="<i>Clara Trevisiol, Co-fondatrice de Monabee</i>"></ParagraphAtom>
      </div>
    </div>
  </div>

  <!-- 2021 - 2023 -->
  <div class="history-2021-banner">
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2021-top-left" image="2021-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2021-top-right" image="2021-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2021-bottom-left" image="2021-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="history-2021-bottom-right" image="2021-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2021-top-left" image="2021-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="history-2021-top-right" image="2021-top-right-mobile"></Image>

    <div class="history-2021-content-container wrapper">
      <!-- TITLE -->
      <div>
        <TitleAtom type="h2" color="light" size="bigger" [mobileSize]="28" weight="semi-bold" text="2021-2023"></TitleAtom>
        <TitleAtom type="h2" color="yellow" size="large" [mobileSize]="22" weight="semi-bold" text="Les 30 glorieuses de Monabee."></TitleAtom>
        <TitleAtom color="light" size="large" weight="semi-bold" [mobileSize]="22" text="Avec une nouvelle levée de fonds de +2M€, nous nous retrouvons propulsés dans une croissance rapide."></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Monabee s’inscrit dans un tournant de son aventure. Pour marquer ce changement, nous optons pour une nouvelle charte graphique et un nouveau logo, qui met en avant notre produit phare, l’assistant lumineux."></ParagraphAtom>
      </div>

      <ul>
        <li>
          <TitleAtom color="light" size="bigger" [mobileSize]="28" weight="semi-bold" text="+251 %"></TitleAtom>
          <ParagraphAtom [mobileSize]="16" color="light" size="large" weight="semi-bold" text="d’augmentation du nombre de poses réalisées"></ParagraphAtom>
        </li>
        <li>
          <TitleAtom color="light" size="bigger" [mobileSize]="28" weight="semi-bold" text="+159 %"></TitleAtom>
          <ParagraphAtom [mobileSize]="16" color="light" size="large" weight="semi-bold" text="de recrutements dans l’entreprise"></ParagraphAtom>
        </li>
        <li>
          <TitleAtom color="light" size="bigger" [mobileSize]="28" weight="semi-bold" text="+3"></TitleAtom>
          <ParagraphAtom [mobileSize]="16" color="light" size="large" weight="semi-bold" text="nouveaux postes stratégiques qui rejoignent le CODIR"></ParagraphAtom>
        </li>
        <li>
          <TitleAtom color="light" size="bigger" [mobileSize]="28" weight="semi-bold" text="+400%"></TitleAtom>
          <ParagraphAtom [mobileSize]="16" color="light" size="large" weight="semi-bold" text="de demandes entrantes"></ParagraphAtom>
        </li>
      </ul>
    </div>
  </div>

  <div class="history-2021-team">
    <Image borderRadius="radius" type="image" width="1015px" mobileWidth="100%" imageCloudinary="histoire/Notre-histoire_photo-e%CC%81quipe_xntfm9_xknwfs.jpg"></Image>
  </div>

  <!-- JOIN THE TEAM -->
  <div class="history-hiring-container wrapper">
    <div class="history-hiring-card">
      <TitleAtom [mobileSize]="22" align="{{this.currentWidth > 1000 ? 'center' : 'left'}}" color="dark" weight="semi-bold" size="x-large" text="Rejoignez notre équipe !"></TitleAtom>
      <ParagraphAtom align="{{this.currentWidth > 1000 ? 'center' : 'left'}}" color="dark" weight="regular" size="intermediate" text="Contrairement à ce que dit Google, c’est très animé à toute heure chez Monabee ! « La Ruche », c’est le nom que nous avons donné à nos locaux. Notre quotidien : de l’énergie positive entre nous, avec nos clients et partenaires, des projets, une ambiance animée… <strong>Notre objectif :</strong> participer à la transition énergétique à notre échelle, et permettre à nos clients de produire leur propre électricité efficacement."></ParagraphAtom>
      <ButtonAtom class="join-team-btn" [style]="'stroked'" size="default" color="light" text="Découvrir nos offres" href="/recrutement"></ButtonAtom>
      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-top-left" image="join-team-top-left"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-top-right" image="join-team-top-right"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-bottom-left" image="join-team-bottom-left"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="join-team-bottom-right" image="join-team-bottom-right"></Image>

      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-top-left" image="join-team-top-left-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-top-right" image="join-team-top-right-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-bottom-left" image="join-team-bottom-left-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="join-team-bottom-right" image="join-team-bottom-right-mobile"></Image>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
