<div class="call-container" *ngIf="isHidden === false">
  <!-- Step 1 -->
  <div class="meeting-section" *ngIf="isCalendar === true">
    <!-- HEADER -->
    <div class="meeting-top" *ngIf="isMobile === false">
      <div>
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="onHideModal()"></ButtonAtom>
      </div>

      <Image type="icon" class="contact-top-left" image="contact-top-left"></Image>
      <Image type="icon" class="contact-top-right" image="contact-top-right"></Image>
      <Image type="icon" class="contact-bottom-left" image="contact-bottom-left"></Image>
      <Image type="icon" class="contact-bottom-right" image="contact-bottom-right"></Image>

      <TitleAtom *ngIf="stepCount === 1" color="light" align="center" weight="semi-bold" size="large" text="Saisissez votre code postal"></TitleAtom>
      <TitleAtom *ngIf="stepCount === 2" color="light" align="center" weight="semi-bold" size="large" text="Sélectionnez une date et un horaire"></TitleAtom>
      <TitleAtom *ngIf="stepCount === 3 && isMobile === false" color="light" align="center" weight="semi-bold" size="large" text="Vos informations"></TitleAtom>
      <TitleAtom *ngIf="stepCount === 4 && isMobile === false" color="light" align="center" weight="semi-bold" size="large" text="Confirmation"></TitleAtom>
    </div>

    <div class="banner-mobile" *ngIf="isMobile === true">
      <Image type="icon" class="contact-top-left" image="contact-top-left-mobile"></Image>
      <Image type="icon" class="contact-bottom-left" image="contact-bottom-left-mobile"></Image>
      <Image type="icon" class="contact-bottom-right" image="contact-bottom-right-mobile"></Image>

      <div class="close-btn">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="onHideModal()"></ButtonAtom>
      </div>
      <TitleAtom *ngIf="stepCount === 1" color="light" align="center" weight="semi-bold" size="large" text="Saisissez votre code postal"></TitleAtom>
      <TitleAtom *ngIf="stepCount === 2" color="light" align="center" weight="semi-bold" size="large" text="Sélectionnez une date"></TitleAtom>
      <TitleAtom *ngIf="stepCount === 3" color="light" align="center" weight="semi-bold" size="large" text="Sélectionnez un horaire"></TitleAtom>
    </div>

    <!-- FORM & CALENDAR -->
    <div class="scrollable-content">
      <div class="meeting-bottom-mobile">

        <ng-container *ngIf="getTimeMobile === false">
          <!-- POSTAL CODE -->
          <div class="meeting-postal-code" *ngIf="stepCount === 1">
            <ParagraphAtom class="postal-code-text" weight="semi-bold" size="intermediate" text="Code postal*"></ParagraphAtom>
            <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" placeholder="Saisissez votre code postal" type="number" [style]="'basic'" color="{{isPostalCodeNotAvailable() || !isPostalCodeValid() && this.form.postal_code !== '' ? 'red' : 'light'}}" name="address" [(value)]="this.form.postal_code" (onSelect)="onPlaceSelected($event)"></InputAtom>
            <ParagraphAtom *ngIf="isPostalCodeNotAvailable() || !isPostalCodeValid() && this.form.postal_code !== ''" color="red" size="small" weight="medium" text="Monabee n’est pas encore présent dans votre ville."></ParagraphAtom>
          </div>
          <!-- CALENDAR & HOUR -->
          <div *ngIf="stepCount === 2" class="calendar-container {{ isPostalCodeNotAvailable() || this.form.postal_code === '' || !isPostalCodeValid() ? 'hide' : '' }}">
            <dp-day-calendar (ngModelChange)="getAvailableTimeslots($event)" [(ngModel)]="selectedDate" [theme]="'dp-material'" [config]="datePickerConfig"></dp-day-calendar>
            <div *ngIf="isMobile === false">
              <ParagraphAtom *ngIf="selectedDate === undefined" color="dark" size="intermediate" weight="semi-bold" text="Veuillez sélectionner une date"></ParagraphAtom>
              <ParagraphAtom align="center" *ngIf="selectedDate !== undefined" color="blue" size="intermediate" weight="semi-bold" text="{{selectedDate | date: 'dd MMMM yyyy' : '' : 'fr-FR'}}"></ParagraphAtom>
              <div *ngIf="selectedDate" class="time-container">
                <ButtonAtom size="default" class="time-navigation-btn-top" [style]="'stroked'" color="light" icon="arrow-top" (click)="showLessTimes()" *ngIf="timesIndex > 0"></ButtonAtom>
                <Image class="loader-desktop" *ngIf="isLoadingTimeslots" type="icon" width="30px" image="loader"></Image>
                <ul>
                  <ng-container *ngFor="let time of this.availableTimes; let i = index">
                    <li *ngIf="i > timesIndex && i < timesIndex + 6" (click)="onSelectChange(3, time)">
                      <button class="time-selection-btn" [ngClass]="{'selected-time': time === this.form.selectedTime}">{{time}}</button>
                    </li>
                  </ng-container>
                </ul>
                <ButtonAtom size="default" class="time-navigation-btn" [style]="'stroked'" color="light" icon="arrow-bottom" *ngIf="timesIndex < availableTimes.length - 1" (click)="showMoreTimes()"></ButtonAtom>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Get time on mobile -->
        <ng-container *ngIf="getTimeMobile === true && isMobile === true">
          <div *ngIf="selectedDate" class="time-container-mobile">
            <ParagraphAtom align="center" color="blue" weight="semi-bold" size="intermediate" [text]="(selectedDate ? (selectedDate | date: 'fullDate':'fr') : 'Date non sélectionnée')"></ParagraphAtom>
            <ButtonAtom class="time-navigation-btn" [style]="'stroked'" color="light" icon="arrow-top" (click)="showLessTimes()" *ngIf="timesIndex > 0"></ButtonAtom>
            <Image class="mobile-loader" *ngIf="isLoadingTimeslots" type="icon" mobileWidth="30px" image="loader"></Image>
            <ul>
              <ng-container *ngFor="let time of this.availableTimes; let i = index">
                <li *ngIf="i > timesIndex && i < timesIndex + 5" (click)="onSelectChange(3, time)">
                  <button class="time-selection-btn" [ngClass]="{'selected-time': time === this.form.selectedTime}">{{time}}</button>
                </li>
              </ng-container>
            </ul>
            <ButtonAtom size="large" class="time-navigation-btn" [style]="'stroked'" color="light" icon="arrow-bottom" *ngIf="timesIndex < availableTimes.length - 1" (click)="showMoreTimes()"></ButtonAtom>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="meeting-bottom">

      <!-- Get time on mobile -->
      <ng-container *ngIf="getTimeMobile === true && isMobile === true">
        <div *ngIf="selectedDate" class="time-container-mobile">
          <ParagraphAtom weight="semi-bold" size="intermediate" [text]="(selectedDate ? (selectedDate | date: 'fullDate':'fr') : 'Date non sélectionnée')"></ParagraphAtom>
          <ul>
            <li *ngFor="let time of getDisplayedTimesMobile()" (click)="onSelectChange(3, time)">
              <button class="time-selection-btn" [ngClass]="{'selected-time': time === this.form.selectedTime}">{{time}}</button>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>

    <!--  button for desktop  -->
    <ng-container *ngIf="isMobile === false">
    <div class="meeting-btn" [ngClass]="this.stepCount === 1 ? 'meeting-btn-center' : ''">
        <ButtonAtom *ngIf="stepCount !== 1" [style]="'stroked'" size="default" color="light" text="Retour" (click)="previous()"></ButtonAtom>
        <ButtonAtom *ngIf="stepCount === 1" [style]="'basic'" [isDisabled]="isPostalCodeNotAvailable() || !isPostalCodeValid()" color="yellow" text="Valider" (click)="next(1)"></ButtonAtom>
        <ButtonAtom *ngIf="stepCount === 2" [style]="'basic'" [isDisabled]="isPostalCodeNotAvailable() || !isPostalCodeValid() || this.form.selectedTime === undefined || this.form.selectedTime === ''" color="yellow" text="Valider" (click)="next(2)"></ButtonAtom>
      </div>
    </ng-container>

    <!--  button for mobile  -->
    <ng-container *ngIf="isMobile === true">
      <div class="meeting-btn">
        <ButtonAtom *ngIf="stepCount === 1" [style]="'basic'" [isDisabled]="isPostalCodeNotAvailable() || !isPostalCodeValid()" color="yellow" text="Suivant" (click)="nextMobile(1)"></ButtonAtom>
        <ButtonAtom *ngIf="stepCount === 2" [style]="'basic'" [isDisabled]="isPostalCodeNotAvailable() || !isPostalCodeValid() || selectedDate === undefined" color="yellow" text="Suivant" (click)="nextMobile(2)"></ButtonAtom>
        <ButtonAtom *ngIf="stepCount === 3" [style]="'basic'" [isDisabled]="this.form.selectedTime === ''" color="yellow" text="Suivant" (click)="nextMobile(3)"></ButtonAtom>
        <ButtonAtom *ngIf="stepCount !== 1" [style]="'stroked'" size="default" color="light" text="Retour" (click)="previousMobile()"></ButtonAtom>
      </div>
    </ng-container>
  </div>

  <!-- Step 2 -->
  <div class="meeting-form-section" *ngIf="isInformationUser === true">
    <div class="scrollable-content">
      <div class="meeting-form-top">
      <ng-container *ngIf="isMobile === false">
        <div class="meeting-form-header">
          <Image type="icon" class="contact-top-left" image="contact-top-left"></Image>
          <Image type="icon" class="contact-top-right" image="contact-top-right"></Image>
          <Image type="icon" class="contact-bottom-left" image="contact-bottom-left"></Image>
          <Image type="icon" class="contact-bottom-right" image="contact-bottom-right"></Image>

          <ButtonAtom class="close-dark-btn" [style]="'clear'" icon="close-dark" (click)="onHideModal()"></ButtonAtom>
          <TitleAtom color="light" size="large" weight="semi-bold" text="Vos informations"></TitleAtom>
        </div>
      </ng-container>
      <ng-container *ngIf="isMobile === true">
        <div class="meeting-form-header">
          <ButtonAtom [style]="'clear'" icon="close-dark" (click)="onHideModal()"></ButtonAtom>
          <TitleAtom [mobileSize]="18" color="light" size="medium" weight="semi-bold" text="Vos informations"></TitleAtom>

          <Image type="icon" class="contact-top-left" image="contact-top-left-mobile"></Image>
          <Image type="icon" class="contact-bottom-left" image="contact-bottom-left-mobile"></Image>
          <Image type="icon" class="contact-bottom-right" image="contact-bottom-right-mobile"></Image>
        </div>
      </ng-container>
      <ButtonRadio class="contact-meeting-radio-btn" name="contact-meeting" selectedOption="particulier" [style]="'second'" [options]="[{value: 'particulier', label: 'Je suis un particulier'}, {value: 'professionnel', label: 'Je suis un professionnel'}, ]" (selectedValueChange)="onSelectChange(1, $event)"></ButtonRadio>
      <ul>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" type="name" name="username" [(value)]="form.name"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
          <InputAtom (input)="onInputChange(1)" [style]="'basic'" color="{{(form.email === '' || checkEmail()) ? 'light' : 'red'}}" name="email" type="email" [(value)]="form.email"></InputAtom>
          <ParagraphAtom *ngIf="!checkEmail() && isEmailInputDirty && form.email !== ''" color="red" size="small" weight="medium" text="Email invalide."></ParagraphAtom>
        </li>
        <li *ngIf="this.form.userType === 'professionnel'">
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre société*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" name="company" [(value)]="form.company"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
          <InputAtom (input)="onInputChange(2)" type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="{{form.phone_number === '' || checkPhoneNumber() ? 'light' : 'red'}}" name="phoneNumber" [(value)]="form.phone_number"></InputAtom>
          <ParagraphAtom *ngIf="!checkPhoneNumber() && isPhoneInputDirty && form.phone_number !== ''" color="red" size="small" weight="medium" text="Numéro de téléphone invalide."></ParagraphAtom>
        </li>
      </ul>

      <div class="opposition-message">
        <ParagraphAtom color="dark" align="left" size="intermediate" weight="regular" text="Vous avez le droit de vous inscrire gratuitement sur la liste d'opposition au démarchage téléphonique."></ParagraphAtom>
      </div>
    </div>
    </div>

    <div class="meeting-btn">
      <ButtonAtom *ngIf="this.isMobile === false" size="default" [style]="'stroked'" color="light" text="Retour" (click)="previousMobile()"></ButtonAtom>
      <ButtonAtom [style]="'basic'" [isDisabled]="this.form.userType === 'professionnel' ? this.form.name === '' || !checkEmail() || this.form.phone_number.length < 10 || this.form.company === '' : this.form.name === '' || !checkEmail() || this.form.phone_number.length < 10 || this.form.postal_code === ''" color="yellow" text="Valider" (click)="onSubmit()"></ButtonAtom>
      <ButtonAtom *ngIf="this.isMobile === true" size="default" [style]="'stroked'" color="light" text="Retour" (click)="previousMobile()"></ButtonAtom>
    </div>
  </div>

  <!-- Step 3 -->
  <div class="call-validated" *ngIf="isConfirm === true">
      <div class="call-validated-header">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="onHideModal()"></ButtonAtom>

        <Image *ngIf="currentWidth > 1000" type="icon" class="contact-top-left" image="contact-top-left"></Image>
        <Image *ngIf="currentWidth > 1000" type="icon" class="contact-top-right" image="contact-top-right"></Image>
        <Image *ngIf="currentWidth > 1000" type="icon" class="contact-bottom-left" image="contact-bottom-left"></Image>
        <Image *ngIf="currentWidth > 1000" type="icon" class="contact-bottom-right" image="contact-bottom-right"></Image>

        <Image *ngIf="currentWidth < 1000" type="icon" class="contact-top-left" image="contact-top-left-mobile"></Image>
        <Image *ngIf="currentWidth < 1000" type="icon" class="contact-bottom-left" image="contact-bottom-left-mobile"></Image>
        <Image *ngIf="currentWidth < 1000" type="icon" class="contact-bottom-right" image="contact-bottom-right-mobile"></Image>

        <TitleAtom color="light" size="large" weight="semi-bold" text="Confirmation"></TitleAtom>
      </div>
<!--    <ng-container *ngIf="isMobile === false">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="onHideModal()"></ButtonAtom>
    </ng-container>-->
    <Image type="icon" image="check-blue" width="56px"></Image>
    <TitleAtom [mobileSize]="28" align="center" color="dark" size="large" weight="semi-bold" text="Votre prise de rendez-vous a bien été confirmée"></TitleAtom>
    <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="Un Conseiller en Energie Solaire vous rappellera"></ParagraphAtom>
    <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="le {{selectedDate | date: 'dd MMMM yyyy' : '' : 'fr-FR' }} à {{this.form.selectedTime}}"></ParagraphAtom>
    <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="onHideModal()"></ButtonAtom>
  </div>
</div>
