<div class="blog-container">

  <!-- TOP PART -->
  <Announcement></Announcement>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Nos actualités et conseils'}]"></Breadcrumb>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="blog-content">
    <div id="blogHerobanner" class="first-section">
      <TitleAtom type="h1" text="Nos conseils d'experts sur l'énergie solaire et l'autoconsommation" align="center" color="dark" size="x-large" weight="semi-bold"></TitleAtom>
      <ParagraphAtom size="intermediate" weight="regular" align="center" text="Consultez nos articles sur la production d'électricité verte grâce à ses panneaux photovoltaïques, l'actualité des prix de l'énergie en France, et tout ce que vous devez savoir sur l'autoconsommation solaire."></ParagraphAtom>
    </div>

    <!-- HEADLINE -->
    <div class="second-section" *ngIf="articleHeadline !== null">
      <div class="headline">
        <a routerLink="/blog/{{ articleHeadline.data.slug }}">
          <Image imageExternal="{{ articleHeadline.data.image.url }}" alt="{{ articleHeadline.data.image.alt }}" width="808px" mobileWidth="100%" borderRadius="radius"></Image>
          <TitleAtom type="p" text="{{ articleHeadline.data.title }}" color="light" size="large" weight="semi-bold"></TitleAtom>
        </a>
      </div>

      <div class="popular">
        <TitleAtom type="p" text="Articles populaires" color="dark" size="medium" weight="semi-bold"></TitleAtom>
        <ul>
          <ng-container *ngFor="let article of articlesPopular">
            <li>
              <a routerLink="/blog/{{ article.data.slug }}">
                <Image imageExternal="{{ article.data.image.url }}" alt="{{ article.data.image.alt }}" width="104px" mobileWidth="104px" borderRadius="radius-half "></Image>
                <TitleAtom type="p" text="{{ article.data.title }}" align="left" color="dark" size="tiny" weight="semi-bold"></TitleAtom>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <!-- All categories -->
    <ng-container *ngFor="let category of categories">
      <div class="third-section" *ngIf="category.data.total > 0" [id]="category.data.slug">
        <TitleAtom type="h2" [text]="category.data.title"  color="dark" size="large" weight="semi-bold"></TitleAtom>
        <div class="paragraph">
          <ParagraphAtom size="intermediate" weight="regular" text="{{ category.data.body.length > 0 ? category.data.body[0].text : '' }}"></ParagraphAtom>
        </div>
        <ul>
          <ng-container *ngFor="let article of articles">
            <li *ngIf="article.data.category.id === category.id">
              <a routerLink="/blog/{{ article.data.slug }}">
                <CardArticle imageExternal="{{ article.data.image.url }}" date="{{ article.data.published_at || '' | date: 'dd MMMM YYYY': '': 'fr-FR' }}" title="{{ article.data.title }}" text="{{ article.data.summary }}"></CardArticle>
              </a>
            </li>
          </ng-container>
        </ul>

        <div>
          <ButtonAtom href="/blog/categorie/{{ category.data.slug }}" [style]="'basic'" color="yellow" text="Découvrir tous nos articles"></ButtonAtom>
        </div>
      </div>
    </ng-container>

  </div>
</div>
<div class="footer-container">
  <Footer></Footer>
</div>
