<div class="contact-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Contact'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <Image type="image" width="131px" mobileWidth="131px" image="contact-team-avatar"></Image>
      <TitleAtom type="h1" align="center" text="Une question ? échangeons sur votre projet photovoltaïque" color="dark" size="heading" weight="semi-bold"></TitleAtom>
      <ParagraphAtom size="intermediate" align="center" weight="regular" text="Pour obtenir un devis sur l’installation de panneaux solaires, prenez contact avec nous."></ParagraphAtom>
    </div>
  </div>

  <!-- BOTTOM PART -->
  <div class="contact-content">
    <ul>
      <li style="display: none;">
        <CardInline [style]="'basic'" image="appointment" title="Prendre un rendez-vous téléphonique" btnText="Prendre rendez-vous" btnIcon="calendar" (btnClick)="handleMeetingModal()" text="Un appel dure 30 min en moyenne. Vous souhaitez être rappelé à une date/heure précise car vous avez des questions ? Un Conseiller en Energie Solaire vous répond et vous conseille sur votre projet."></CardInline>
      </li>
      <li style="display: none;">
        <CardInline [style]="'basic'" image="callback" title="Être rappelé immédiatement" btnText="Être rappelé" btnIcon="phone" (btnClick)="handleCallModal()" text="Vous souhaitez être rappelé immédiatement car vous avez des questions ? Un Conseiller en Energie Solaire vous répond."></CardInline>
      </li>
      <li>
        <CardInline [style]="'basic'" href="tel:0482537245" image="phone-contact"  title="Appelez-nous !" btnText="04 82 53 72 45" btnIcon="phone" text="Vous êtes disponible immédiatement ? Notre centre d’appel répond à vos premières interrogations et vous oriente vers un Conseiller en Energie Solaire si vous le désirez ensuite."></CardInline>
      </li>
      <li>
        <CardInline [style]="'basic'" image="message" title="Discutons ensemble" btnText="Envoyer un message" btnIcon="message-dark" (btnClick)="hideContactModal = false" text="Envoyez-nous votre message. Questions, demande de conseils sur votre projet photovoltaïque... Nous traitons votre demande pour vous conseiller au mieux."></CardInline>
      </li>
    </ul>
    <div>
      <TitleAtom [mobileSize]="32" color="dark" type="p" size="x-large" weight="semi-bold" text="Consulter notre FAQ"></TitleAtom>
      <ParagraphAtom color="dark" align="center" size="intermediate" weight="regular" text="Notre foire aux questions répond à beaucoup de sujets, que ce soit sur les aides pour les projets photovoltaïques, les produits Monabee... Vous ne trouvez pas la réponse ? Contactez-nous !"></ParagraphAtom>

      <ButtonAtom size="default" [style]="'basic'" href="/faq" color="yellow" text="Consulter notre FAQ"></ButtonAtom>
    </div>
  </div>


  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>

<ContactMessage cardType="message" title="Discutons ensemble" btnText="Envoyer ma demande" userType="particulier" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
<ContactMeeting [(isHidden)]="hideMeetingModal"></ContactMeeting>
<ContactCall [(isHidden)]="hideCallModal"></ContactCall>

