import { Component, OnInit, NgZone, Inject, PLATFORM_ID } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsArticleType, CmsResponseType } from "../../types/cms.types";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { StructuredDataService } from "../../services/structured-data.service";
import {isPlatformBrowser} from "@angular/common";
import {FORM_TYPE} from "../../constants/constants";

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailPageComponent implements OnInit {

  /* The article from the CMS */
  public article: CmsArticleType | null = null;

  /* The article from the CMS */
  public articlesDiscovery: Array<CmsArticleType> = [];

  /** Show the contact meeting modal **/
  public hideMeetingModal: boolean = true;

  /** The width and height of the window user */
  public isMobile: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    public cms: CmsService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private structuredDataService: StructuredDataService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }

    this.refresh();


  }

  /** Refresh the content */
  public refresh() {
    const articleSlug = this.route.snapshot.paramMap.get('article');
    const portfolioCats = this.route.snapshot.queryParamMap.get('portfolioCats');
    const structuredDataList: any[] = [];

    // Get Discovery articles
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"A découvrir également\"])]]", "[my.blog.published_at desc]", 1, 5).then((content: CmsResponseType) => {
      this.ngZone.run(() => {

        this.articlesDiscovery = content.results.filter((article: CmsArticleType) => article.data.slug !== articleSlug);

        // Add structured data for each article
        for (let article of this.articlesDiscovery) {
          structuredDataList.push(this.structuredDataService.createArticleStructuredData(article));  // Add this line
        }

      });
    });

    // Get an article
    this.cms.retrieve(`[[at(document.type, \"blog\")][at(my.blog.slug, \"${articleSlug}\")]]`, "[my.blog.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        if (content.results.length === 0) this.router.navigate(['/blog']);
        this.article = content.results[0];

        // Set title and meta description
        this.titleService.setTitle(this.article?.data.metadata_title || "");
        this.metaService.updateTag({ name: 'description', content: this.article?.data.metadata_description || ""});
        this.metaService.updateTag({ property: 'og:title', content: this.article?.data.metadata_title || "" });
        this.metaService.updateTag({ property: 'og:image', content: this.article?.data.image.url || "" });
        this.metaService.updateTag({ property: 'og:description', content: this.article?.data.metadata_description || "" });

        if (portfolioCats !== null) this.metaService.addTag({ name: 'robot', content: "noindex"});

        // Add structured data for the page
        this.structuredDataService.addNewsArticle(structuredDataList);
      });
    });
  }

  /** Handle the meeting modal */
  public handleMeetingModal() {
    this.hideMeetingModal = false;
    document.body.style.overflow = 'hidden';
  }

  protected readonly FORM_TYPE = FORM_TYPE;
}
