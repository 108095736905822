<ng-container *ngIf="image !== ''">
  <div class="image-component" [ngClass]="classes">
    <img loading="lazy" [ngClass]="classes" *ngIf="type === 'image'" [src]="'../../../assets/images/' + image + '.png'" alt="{{alt !== '' ? alt : image }}" [ngStyle]="{
    'width': this.windowWidth > 1024 ? width : mobileWidth,
    'height': this.height ? this.height : 'auto'
  }">
    <img loading="lazy" [ngClass]="classes" *ngIf="type === 'icon'" [src]="'../../../assets/images/icons/' + image + '.svg'" alt="{{alt !== '' ? alt : image }}" [ngStyle]="{
    'width': this.windowWidth > 1024 ? width : mobileWidth,
    'height': this.height ? this.height : 'auto'
  }">
  </div>
</ng-container>

<ng-container *ngIf="imageExternal !== ''">
  <div class="image-component" [ngClass]="classes">
    <img loading="lazy" [ngClass]="classes" [src]="imageExternal" alt="{{alt !== '' ? alt : image }}" [ngStyle]="{
      'width': this.windowWidth > 1024 ? width : mobileWidth,
      'height': this.height ? this.height : 'auto'
    }">
  </div>
</ng-container>

<ng-container *ngIf="imageCloudinary !== ''">
  <div class="image-component" [ngClass]="classes">
    <img loading="lazy" [ngClass]="classes" [src]="getCloudinaryUrl()" alt="{{alt !== '' ? alt : image }}"  [ngStyle]="{
      'width': this.windowWidth > 1024 ? width : mobileWidth,
      'height': this.height ? this.height : 'auto'
    }">
  </div>
</ng-container>

