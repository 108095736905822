<ul class="slider-component">
  <ng-container *ngFor="let item of items; let i = index">
    <li class="slider-step" *ngIf="i === step || isMobile === true">
      <div class="slider-btn-left">
        <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
      </div>

      <div class="slider-btn-right">
        <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
      </div>

      <div class="slider-content">
        <Image type="image" borderRadius="radius" width="600px" mobileWidth="100%" imageCloudinary="{{ item.imageCloudinary }}"></Image>
        <div>
          <TitleAtom [mobileSize]="18" type="h3" color="dark" size="large" weight="semi-bold" text="{{ item.title }}"></TitleAtom>
          <ParagraphAtom color="dark" size="intermediate" weight="regular" text="{{ item.text }}"></ParagraphAtom>
        </div>
      </div>

      <ul>
        <li *ngFor="let bullet of getBullets(); let j = index">
          <Image type="icon" width="8px" image="{{ j === step ? 'ellipsis-yellow' : 'ellipsis-grey' }}"></Image>
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
