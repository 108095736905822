<div class="card-inline-component" [ngClass]="classes">
  <Image *ngIf="this.image !== ''" width="64px" mobileWidth="48px" type="icon" image="{{image}}"></Image>
  <div class="card-inline-content">
    <TitleAtom [mobileSize]="18" type="p" color="dark" weight="semi-bold" size="medium" text="{{title}}"></TitleAtom>
    <ParagraphAtom color="dark" weight="regular" size="intermediate" text="{{text}}"></ParagraphAtom>
  </div>
  <div class="card-inline-btn" *ngIf="btnText">
    <ButtonAtom *ngIf="href === ''" size="default" [style]="'basic'" color="yellow" text="{{btnText}}" icon="{{btnIcon}}" (click)="btnClick.emit()"></ButtonAtom>
    <ButtonAtom *ngIf="href !== ''" [queryParams]="this.queryParams" size="default" [style]="'basic'" color="yellow" text="{{btnText}}" icon="{{btnIcon}}" href="{{href}}"></ButtonAtom>
  </div>
</div>
