import { Injectable } from '@angular/core';
import { CmsDataType } from "../types/cms.types";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class CmsService {

  /**
   * The base url
   * @private
   */
  private url: string = "https://monabee.cdn.prismic.io/api/v2";

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Get the last ref
   */
  public async getLastRef(): Promise<any> {
    const data: any = await this.http.get(this.url).toPromise();
    return data.refs[0].ref;
  }

  /**
   * Get the content
   * @param query The query to filter
   * @param ordering ordering of the content
   */
  public async retrieve(query: string = "", ordering: string = "[my.published_at.position]", page: number = 1, pageSize: number = 100): Promise<any> {
    const ref = await this.getLastRef();
    return await this.http.get(`${this.url}/documents/search?ref=${ref}&orderings=${ordering}&q=${query}&page=${page}&pageSize=${pageSize}`).toPromise();
  }

  /**
   * Add the text style based on the spans
   * @param data The json data from the CMS
   */
  // FONCTIONNE
  public textStyle(data: CmsDataType): string {
    let html = data.text;

    // Create a list of all the tags with their start and end indices.
    let tags = [];
    for (const span of data.spans) {
      let tagOpen = '';
      let tagClose = '';

      // Add bold
      if (span.type === "strong") {
        tagOpen = '<b>';
        tagClose = '</b>';
      }
      // Add italic
      else if (span.type === "em") {
        tagOpen = '<i>';
        tagClose = '</i>';
      }
      // Add hyperlink
      else if (span.type === "hyperlink") {
        tagOpen = `<a href="${span.data.url}" target="${span.data.target}">`;
        tagClose = '</a>';
      }

      // Add start and end tags to the list
      tags.push({index: span.start, tag: tagOpen, isStart: true});
      tags.push({index: span.end, tag: tagClose, isStart: false});
    }

    // Sort the list by index in descending order, putting end tags before start tags in case of a tie.
    tags.sort((a, b) => b.index - a.index || Number(a.isStart) - Number(b.isStart));

    // Iterate over the list from the end to create the final HTML.
    for (const tag of tags) {
      html = html.slice(0, tag.index) + tag.tag + html.slice(tag.index);
    }

    return html;
  }
}
