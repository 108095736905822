<div class="template-container" *ngIf="cmsData">
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <!-- HEROBANNER -->
  <div class="offer-herobanner" *ngIf="cmsData.data">

    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: cmsData.data!.breadcrumb}]"></Breadcrumb>
    </div>

    <div id="offer-produce-form" class="offer-herobanner-content">
      <div id="produceOffer" class="content">
        <TitleAtom [mobileSize]="18" color="yellow" weight="semi-bold" size="small" [text]="cmsData.data!.herobanner_title_yellow"></TitleAtom>
        <TitleAtom class="template-main-title" type="h1" color="light" weight="semi-bold" size="x-large" [text]="cmsData.data!.herobanner_title_h1"></TitleAtom>

        <ParagraphAtom color="light" weight="regular" size="intermediate" [text]="cmsData.data!.herobanner_text_one"></ParagraphAtom>

        <TitleAtom class="template-second-title" type="p" color="light" weight="medium-weight" size="small" [text]="cmsData.data!.herobanner_title_h3_first"></TitleAtom>
        <ParagraphAtom class="template-second-title-text" color="light" weight="regular" size="intermediate" [text]="cmsData.data!.herobanner_text_two"></ParagraphAtom>

        <TitleAtom class="template-third-title" [mobileSize]="16" color="light" weight="semi-bold" size="small" [text]="cmsData.data!.herobanner_title_h3_second"></TitleAtom>
        <ParagraphAtom class="template-third-title-text" color="light" weight="regular" size="intermediate" [text]="cmsData.data!.herobanner_text_three"></ParagraphAtom>

        <TitleAtom class="template-fourth-title" [mobileSize]="16" color="light" weight="semi-bold" size="tiny" [text]="cmsData.data!.herobanner_certification_title"></TitleAtom>

        <!-- Certification List -->
        <ul class="template-certifications-list" *ngIf="cmsData!.data.herobanner_certifications_list">
          <li class="offer-certification" *ngFor="let item of cmsData!.data.herobanner_certifications_list">
            <Image width="91px" type="image" [imageExternal]="item.herobanner_certification_list_img.url"></Image>
            <div *ngIf="item.herobanner_certification_list_text?.trim()">
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="item.herobanner_certification_list_text"></ParagraphAtom>
            </div>
          </li>
        </ul>
      </div>
      <ContactMessage *ngIf="cmsData!.data.herobanner_form_btn_text" [hideRadioBtn]="true" [disableUserTypeChange]="true" [title]="cmsData.data!.herobanner_form_title" [btnText]="cmsData.data!.herobanner_form_btn_text" userType="particulier"></ContactMessage>
    </div>

  </div>

  <!-- ADVANTAGES -->
  <div id="produceAdvantages" class="offer-advantages" *ngIf="cmsData.data">
    <TitleAtom class="offer-advantages-title" *ngIf="cmsData.data!.advantages_title" [mobileSize]="22" type="h2" color="dark" align="center" size="x-large" weight="semi-bold" [text]="cmsData.data!.advantages_title"></TitleAtom>
    <ul>
      <li *ngIf="(cmsData!.data.advantages_first_title?.trim() || '').trim() !== '' || hasValidItems(cmsData!.data.advantages_first_list)">
        <div class="offer-advantages-safe" *ngIf="cmsData!.data.advantages_first_title?.trim() !== '' || cmsData!.data.advantages_first_list.length > 0">
          <div class="offer-success" *ngIf="cmsData!.data.advantages_first_text_framed">
            <ParagraphAtom color="dark" weight="bold" size="intermediate" [text]="cmsData.data!.advantages_first_text_framed"></ParagraphAtom>
          </div>
          <TitleAtom [mobileSize]="18" type="p" color="dark" weight="semi-bold" size="large" [text]="cmsData.data!.advantages_first_title"></TitleAtom>
          <ul>
            <ng-container *ngFor="let item of cmsData.data!.advantages_first_list">
              <li *ngIf="(item.advantages_list_title && item.advantages_list_title.trim() !== '') || (item.advantages_list_text && item.advantages_list_text.trim() !== '')">
                <div class="offer-title" *ngIf="item.advantages_list_title && item.advantages_list_title.trim() !== ''">
                  <span class="point-success"></span>
                  <ParagraphAtom color="dark" weight="bold" size="intermediate" [text]="item.advantages_list_title"></ParagraphAtom>
                </div>
                <ParagraphAtom color="dark" weight="regular" size="intermediate" *ngIf="item.advantages_list_text && item.advantages_list_text.trim() !== ''" [text]="item.advantages_list_text"></ParagraphAtom>
              </li>
            </ng-container>
          </ul>
        </div>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData.data!.advantages_first_img.url"></Image>
      </li>
      <li *ngIf="cmsData.data!.advantages_second_img?.url?.trim() !== '' || cmsData.data!.advantages_second_title?.trim() !== '' || cmsData.data!.advantages_second_text?.trim() !== ''">
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData.data!.advantages_second_img.url"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="p" color="dark" weight="semi-bold" size="large" [text]="cmsData.data!.advantages_second_title"></TitleAtom>
          <div>
            <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData.data!.advantages_second_text"></ParagraphAtom>
          </div>
        </div>
      </li>
      <li *ngIf="cmsData.data!.advantages_third_title?.trim() !== '' || cmsData.data!.advantages_third_text?.trim() !== '' || cmsData.data!.advantages_third_img?.url?.trim() !== ''">
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" [text]="cmsData.data!.advantages_third_title"></TitleAtom>
          <div>
            <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData.data!.advantages_third_text"></ParagraphAtom>
            </div>
          </div>
        </div>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData.data!.advantages_third_img.url"></Image>
      </li>
      <li *ngIf="cmsData.data!.advantages_fourth_img?.url?.trim() !== '' || cmsData.data!.advantages_fourth_title?.trim() !== '' || cmsData.data!.advantages_fourth_text?.trim() !== '' || cmsData.data!.advantages_fourth_text_framed?.trim() !== '' || cmsData.data!.advantages_fourth_subtitle_framed?.trim() !== ''">
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData.data!.advantages_fourth_img.url"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" [text]="cmsData.data!.advantages_fourth_title"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData.data!.advantages_fourth_text"></ParagraphAtom>
          <div class="offer-warning" *ngIf="cmsData.data!.advantages_fourth_text_framed && cmsData.data!.advantages_fourth_text_framed.trim() !== ''">
            <Image type="icon" size="32px" image="warning-orange"></Image>
            <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData.data!.advantages_fourth_text_framed"></ParagraphAtom>
            </div>
          </div>
          <ParagraphAtom color="dark" size="small" weight="regular" [text]="cmsData.data!.advantages_fourth_subtitle_framed"></ParagraphAtom>
        </div>
      </li>
    </ul>
  </div>

  <!-- VIDEO -->
  <div class="video-container" *ngIf="cmsData?.data && ((cmsData.data.video_orange_title?.trim() || '') !== '' ||
                                      (cmsData.data.video_main_title?.trim() || '') !== '' ||
                                      (cmsData.data.video_text?.trim() || '') !== '' ||
                                      (cmsData.data.video_youtube_link?.trim() || '') !== '')">
    <TitleAtom class="video-orange-title" align="center" [mobileSize]="16" size="tiny" weight="semi-bold" color="orange" [text]="cmsData.data!.video_orange_title"></TitleAtom>
    <TitleAtom align="center" [mobileSize]="22" type="h2" size="x-large" weight="semi-bold" color="dark" [text]="cmsData.data!.video_main_title"></TitleAtom>
    <ParagraphAtom class="video-paragraph" align="center" color="dark" weight="regular" size="intermediate" [text]="cmsData.data!.video_text"></ParagraphAtom>

    <div class="video-youtube-container">
      <VideoYoutubeAtom *ngIf="cmsData?.data?.video_youtube_link" [video]="cmsData?.data?.video_youtube_link || ''" width="100%" widthMobile="100%" height="335px" heightMobile="192px"></VideoYoutubeAtom>
    </div>
  </div>

  <!-- 5 STEPS -->
  <div id="chargingSteps" class="offer-steps" *ngIf="cmsData.data && (cmsData!.data.steps_list.length > 0)">
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-left" image="offers-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-right" image="offers-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-left" image="offers-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-right" image="offers-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right-mobile"></Image>

    <TitleAtom class="offers-steps-title" [mobileSize]="22" align="center" type="h2" color="dark" weight="semi-bold" size="x-large" [text]="cmsData.data!.steps_title"></TitleAtom>
    <ParagraphAtom class="offer-steps-paragraph" align="center" color="dark" weight="regular" size="intermediate" [text]="cmsData.data!.steps_subtitle"></ParagraphAtom>

    <!-- STEPS LIST -->
    <ul *ngIf="cmsData!.data">
      <li *ngFor="let item of cmsData!.data.steps_list; let i = index">
        <CardInlineList [stepIndex]="(i + 1).toString()" [style]="'minimal'" [title]="item.step_title" [text]="item.step_text" [btnText]="item.step_btn_text" [href]="item.step_btn_link.url"></CardInlineList>
      </li>
    </ul>
    <ButtonAtom size="default" fragmentLink="offer-charging-form" [style]="'stroked'" color="light" [text]="cmsData.data!.steps_btn_text" (click)="scrollTop()"></ButtonAtom>
  </div>

  <!-- SETUP -->
  <!--<div id="produceSetup" class="offer-setup" *ngIf="cmsData.data">
    <TitleAtom [mobileSize]="22" align="center" type="p" color="dark" weight="semi-bold" size="x-large" [text]="cmsData.data!.slider_title"></TitleAtom>

    &lt;!&ndash; STEP 1 &ndash;&gt;
    <div class="slider-step1" *ngIf="this.stepCount === 1">

      <ng-lottie
        [width]="isMobile ? '100%' : '1220px'"
        [options]="{path: isMobile ? 'assets/animations/panneau-solaire-1-mobile/animation.json' : 'assets/animations/panneau-solaire-1/animation.json'}">
      </ng-lottie>

      <div class="slider-container">


        <div class="slider-title">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
          <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" [text]="cmsData.data!.slider_step_one_title"></TitleAtom>
          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>

        </div>
      </div>

      <div class="slider-content">
        <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" [text]="cmsData.data!.slider_step_one_subtitle"></ParagraphAtom>
      </div>

      <ul>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(1)">
            <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(2)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(3)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
      </ul>
    </div>

    &lt;!&ndash; STEP 3 &ndash;&gt;
    <div class="slider-step1" *ngIf="this.stepCount === 2">
      <ng-lottie
        [width]="isMobile ? '100%' : '1220px'"
        [options]="{path: isMobile ? 'assets/animations/panneau-solaire-2-mobile/animation.json' : 'assets/animations/panneau-solaire-2/animation.json'}">
      </ng-lottie>

      <div class="slider-container">

        <div class="slider-title">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
          <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" [text]="cmsData.data!.slider_step_two_title"></TitleAtom>
          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>
        </div>

      </div>

      <div class="slider-content">
        <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" [text]="cmsData.data!.slider_step_two_subtitle"></ParagraphAtom>
      </div>

      <ul>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(1)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(2)">
            <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(3)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
      </ul>
    </div>

    &lt;!&ndash; STEP 2 &ndash;&gt;
    <div class="slider-step1" *ngIf="this.stepCount === 3">
      <ng-lottie
        [width]="isMobile ? '100%' : '1220px'"
        [options]="{path: isMobile ? 'assets/animations/panneau-solaire-3-mobile/animation.json' : 'assets/animations/panneau-solaire-3/animation.json'}">
      </ng-lottie>

      <div class="slider-container">
        <div class="slider-title">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
          <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" [text]="cmsData.data!.slider_step_three_title"></TitleAtom>
          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>
        </div>
      </div>

      <div class="slider-content">
        <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" [text]="cmsData.data!.slider_step_three_subtitle"></ParagraphAtom>
      </div>

      <ul>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(1)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(2)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(3)">
            <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
          </a>
        </li>
      </ul>

    </div>
  </div>-->

  <!-- PRO -->
  <div id="producePro" class="offer-pro" [ngClass]="{'no-faq': !isFaqVisible }" *ngIf="(cmsData.data!.professional_title?.trim() || '') !== '' ||
                                              (cmsData.data!.professional_text?.trim() || '') !== '' ||
                                              (cmsData.data!.professional_yellow_btn_text?.trim() || '') !== '' ||
                                              (cmsData.data!.professional_light_btn_text?.trim() || '') !== '' ||
                                              (cmsData.data!.professional_img?.url?.trim() || '') !== ''">
    <div>
      <TitleAtom [mobileSize]="22" color="light" weight="semi-bold" size="x-large" [text]="cmsData.data!.professional_title"></TitleAtom>
      <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData.data!.professional_text"></ParagraphAtom>
      <div>
        <ButtonAtom size="default" [style]="'basic'" color="yellow" [text]="cmsData.data!.professional_yellow_btn_text" (click)="hideContactModal = false"></ButtonAtom>
        <ButtonAtom size="default" [style]="'stroked'" color="light" [text]="cmsData.data!.professional_light_btn_text" [href]="cmsData.data!.professional_light_btn_link.url" ></ButtonAtom>
      </div>
    </div>

    <Image borderRadius="radius" type="image" width="500px" mobileWidth="100%" [imageExternal]="cmsData.data!.professional_img.url"></Image>
  </div>

  <!-- FAQ -->
  <div id="produceFaq" class="faq-container" *ngIf="(cmsData!.data.faq_title?.trim() || '') !== '' ||
                                                 (cmsData!.data.faq_subtitle?.trim() || '') !== ''">
    <TitleAtom align="center" [mobileSize]="16" size="tiny" weight="semi-bold" color="orange" [text]="cmsData.data!.faq_title"></TitleAtom>
    <TitleAtom [mobileSize]="22" type="h2" size="x-large" weight="semi-bold" color="dark" [text]="cmsData.data!.faq_subtitle"></TitleAtom>
    <ul>
      <li *ngFor="let faq of faqs">
        <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
      </li>
    </ul>
  </div>

  <!-- FOOTER -->
  <div class="footer-container" *ngIf="cmsData.data">
    <Footer></Footer>
  </div>

</div>

<ContactMessage title="Demander un devis gratuitement" btnText="Demander un devis gratuitement" userType="professionnel" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
