<div class="blog-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Nos actualités et conseils', link: '/blog'}, {title: category?.data?.title}]"></Breadcrumb>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="blog-content">
    <ng-container *ngIf="category !== null && category !== undefined">
      <div class="first-section">
        <TitleAtom type="h1" text="{{ category.data.title }}" align="center" color="dark" size="x-large" weight="semi-bold"></TitleAtom>
        <ParagraphAtom size="intermediate" weight="regular" align="center" text="{{ category.data.body.length > 0 ? category.data.body[0].text : '' }}"></ParagraphAtom>
      </div>

      <!-- HEADLINE -->
      <div class="second-section" *ngIf="articleHeadline !== null">
        <div class="headline">
          <a routerLink="/blog/{{ articleHeadline.data.slug }}">
            <Image imageExternal="{{ articleHeadline.data.image.url }}" alt="{{ articleHeadline.data.image.alt }}" width="808px" mobileWidth="100%" borderRadius="radius"></Image>
            <TitleAtom type="p" text="{{ articleHeadline.data.title }}" color="light" size="large" weight="semi-bold"></TitleAtom>
          </a>
        </div>

        <div class="popular">
          <TitleAtom type="p" text="Articles populaires" color="dark" size="medium" weight="semi-bold"></TitleAtom>
          <ul>
            <ng-container *ngFor="let article of articlesPopular">
              <li>
                <a routerLink="/blog/{{ article.data.slug }}">
                  <Image imageExternal="{{ article.data.image.url }}" alt="{{ article.data.image.alt }}" width="104px" mobileWidth="104px" borderRadius="radius-half"></Image>
                  <TitleAtom type="h4" text="{{ article.data.title }}" align="left" color="dark" size="tiny" weight="semi-bold"></TitleAtom>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <div class="third-section" *ngIf="articles.length > 0">
        <ul>
          <ng-container *ngFor="let article of articles">
            <li *ngIf="article.data.category.id === category.id">
              <a routerLink="/blog/{{ article.data.slug }}">
                <CardArticle imageExternal="{{ article.data.image.url }}" date="{{ article.data.published_at || '' | date: 'dd MMMM YYYY': '': 'fr-FR' }}" title="{{ article.data.title }}" text="{{ article.data.summary }}"></CardArticle>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>

  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
