import {Component, OnInit, Input, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import { StructuredDataService } from '../../services/structured-data.service';


@Component({
  selector: 'VideoYoutubeAtom',
  templateUrl: './video-youtube.component.html',
  styleUrls: ['./video-youtube.component.scss']
})
export class VideoYoutubeComponent implements OnInit {

  /* The video */
  @Input() video: string = '';

  /* The width for video */
  @Input() width: string = '';

  /* The width for video */
  @Input() widthMobile: string = '';

  /* The height for video */
  @Input() height: string = '';

  /* The height for video */
  @Input() heightMobile: string = '';

  /** Source of the video */
  public src = '';

  /** The width and height of the window user */
  public isMobile = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private structuredDataService: StructuredDataService
  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1000;
    }
    this.structuredDataService.addVideo(this.video);
  }

  public get classes(): Array<string> {
    return [`video`];
  }

  public getSrc(): void {
    this.src = this.video;
  }

}
