import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {CmsDataType, CmsJobType} from "../../types/cms.types";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'CardHiring',
  templateUrl: './card-hiring.component.html',
  styleUrls: ['./card-hiring.component.scss']
})
export class CardHiringComponent implements OnInit, OnChanges {

  @Input() job: CmsJobType| null = null;

  // Style of the card
  @Input() style: string = '';

  // Image of the card
  @Input() image: string = '';

  // Second title
  @Input() secondTitle: string = '';

  // Main title of the card
  @Input() title : string = '';

  // Slug of the job
  @Input() slug : string = '';

  // Font weight of the card
  @Input() fontWeight: string = '';

  // Contract of the job
  @Input() contract: string = '';

  // Place of the job
  @Input() place: string = '';

  // Experience demand for the job
  @Input() experience: string = '';

  // Beginning date for the job
  @Input() date: string = '';

  // Fun fact about the job
  @Input() fact: string = '';

  // More info about the job
  @Input() moreInfo: Array<CmsDataType> = [];

  // Text of the button
  @Input() btnText: string = '';

  // To know if share is displayed
  @Input() showShareLinks = false;

  constructor(    private titleService: Title,
                  private metaService: Meta,
  ) { }

  ngOnInit(): void {

    // Update meta tags
    this.updateMetaTags();
  }

  /**
   * Function to update the meta tags when the job changes
   * @param changes - SimpleChanges : changes of the component
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['job'] && !changes['job'].firstChange) {
      this.updateMetaTags();
    }
  }

  /**
   * Function to update meta tags
   * @private
   */
  private updateMetaTags(): void {
    if (this.job) {
      this.titleService.setTitle(this.job.data.metadata_title || "");
      this.metaService.updateTag({ name: 'description', content: this.job.data.metadata_description || ""});

      /* General */
      this.metaService.updateTag({ property: 'og:title', content: this.job.data.metadata_title || "" });
      this.metaService.updateTag({ property: 'og:description', content: this.job.data.metadata_description || "" });
      this.metaService.updateTag({ property: 'og:image', content: this.job.data.image.url || "" });

      /* Linkedin */
      this.metaService.updateTag({ property: 'linkedin:title', content: this.job.data.metadata_title || "" });
      this.metaService.updateTag({ property: 'linkedin:image', content: this.job.data.image.url || "" });

      /* Twitter */
      this.metaService.updateTag({ name: 'twitter:card', content: "summary_large_image"});
      this.metaService.updateTag({ name: 'twitter:title', content: this.job.data.metadata_title || ""});
      this.metaService.updateTag({ name: 'twitter:description', content: this.job.data.metadata_description || ""});
      this.metaService.updateTag({ name: 'twitter:image', content: this.job.data.image.url || ""});
    }
  }

  /**
   * Function to get the classes of the card
   */
  public get classes(): Array<string> {
    return [`${this.style}`];
  }

  /**
   * Function to share the job
   * @param platform - string : platform to share
   */
  public share(platform: string) {
    const urls = this.getSocialMediaUrls(this.job?.data.title || '');
    window.open(urls[platform], '_blank');
  }

  /**
   * Function to get the social media urls
   * @param title - string : title of the job
   */
  public getSocialMediaUrls(title: string): any {
    const url = encodeURI(window.location.href);
    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}`,
      twitter: `https://twitter.com/intent/tweet?text=${title}&url=${url}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${title}&source=${url}`,
      mail: `mailto:?subject=${title}&body=${url}`,
      whatsapp: `https://api.whatsapp.com/send?text=${title} ${url}`
    };
  }

}
