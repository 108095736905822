import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'CustomerReview',
  templateUrl: './card-customer-review.component.html',
  styleUrls: ['./card-customer-review.component.scss']
})
export class CardCustomerReviewComponent implements OnInit {

  /* Color for initials background */
  @Input() initialsColor: string = '';

  /* Username */
  @Input() username: string = '';

  /* Text of the review */
  @Input() text: string = '';

  /* Rating of the review */
  @Input() rating: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Get the initial from the username
   */
  public getInitial(): string {
    const items = this.username.split(' ');
    if (items.length > 1) {
      return `${items[0][0].toUpperCase()}${items[1][0].toUpperCase()}`;
    } else if (items.length === 1) {
      // If only one first name, returns the first letter
      return items[0][0].toUpperCase();
    }
    return '';
  }

  /**
   * Get the rating stars
   */
  public getRatings(): Array<number> {
    return Array(this.rating).fill(1);
  }

}
