import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from "../../services/api.service";
import { isPlatformBrowser } from "@angular/common";
import {AnalyticsService} from "../../services/analytics.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'FormArticle',
  templateUrl: './form-article.component.html',
  styleUrls: ['./form-article.component.scss']
})
export class FormArticleComponent implements OnInit {

  /** Title  */
  @Input() title: string = '';

  /** Text of the button */
  @Input() btnText: string = '';

  /** Type of user */
  @Input() userType: string = 'particulier';

  /** Type of user */
  @Input() type: string = '';

  /** Type of card */
  @Input() cardType: string = '';

  /** If the modal is hidden */
  @Input() isHidden: boolean = false;
  @Output() isHiddenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** If we are in the professional page  */
  @Input() hideRadioBtn: boolean = false;

  /** If we disabled the user type change */
  @Input() disableUserTypeChange: boolean = false;


  /** Check if input is typing */
  public isEmailInputDirty: boolean = false;
 public isPhoneInputDirty: boolean = false;

 /** Check if modal is open */
  public isModalOpen: boolean = false;

  /** Form */
  public form = {
    phone_number: "",
    name: '',
    email: '',
    postal_code: '',
  };

  /** To know if message is sent */
  public isMessageSent: boolean = false;

  /** The width and height of the window user */
  public isMobile: boolean = false;

  /** Check if email is valid */
  public emailValid: boolean = true;

  /** The postal code not accepted by Monabee **/
  private postalCodeAvailable: string[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public analytics: AnalyticsService,
    public router: Router,
    public api: ApiService,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {

    this.getAvailablePostalCode();

    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }
  }

  /**
   * Function to close the modal
   */
  public onHideModal(): void {
    this.isHidden = true;
    this.isMessageSent = false;
    this.isHiddenChange.emit(true);
  }

  /**
   * Function to know if the postal code is valid
   */
  public isPostalCodeValid(): boolean {
    if (this.form.postal_code === '' || this.form.postal_code === null) {
      return false;
    }
    const regex = /^\d{2}[ ]?\d{3}$/;
    return regex.test(this.form.postal_code);
  }

  /**
   * Function to know if user cp correspond to the Monabee intervention
   */
  public isPostalCodeNotAvailable(): boolean {
    return !this.postalCodeAvailable.includes(this.form.postal_code.substring(0, 2)) && this.form.postal_code !== '';
  }

  /**
   * Function to get the handled postal code
   */
  public getAvailablePostalCode(): void {
    this.api.get(`availability/postalcode`).subscribe((response) => {
      this.postalCodeAvailable = response.data;
    });
  }

  /**
   * Function to change value on select change if is not disabled
   * @param value
   */
  public onSelectChange(value: any): void {
    if (!this.disableUserTypeChange) {
      this.userType = value;
    }
  }

  /**
   * Function to convert the address on the postal code
   * @param postalCode address selected
   */
  public onSelectCpChange(postalCode: string): void {
    this.form.postal_code = postalCode;
  }

  /**
   * Function to check if we can submit
   */
  public canSubmit(): boolean {
      return this.form.name !== "" && this.form.phone_number !== "" && this.form.phone_number.length >= 10  && this.isPostalCodeValid() && !this.isPostalCodeNotAvailable()
  }

  /**
   * Function to submit
   */
  public onSubmit(): void {
    this.analytics.trackForm(this.userType === 'particulier' ? 12737 : 11193);
    this.api.post(`contact/message`, this.form).subscribe(() => {
      this.isMessageSent = true;
      this.form = {
        phone_number: "",
        name: '',
        email: '',
        postal_code: '',
      }
    });
  }

  /** Check if input change */
  public onInputChange(number: number): void {
    if (number === 1) {
      this.isEmailInputDirty = true;
      this.checkEmail();
    }
    else if (number === 2) {
      this.isPhoneInputDirty = true;
      this.checkPhoneNumber();
    }
  }

  /**
   * Function to check if the email is valid
   */
  public checkEmail(): boolean {
    return this.emailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.email);
  }

  /**
   * Function to check if phone number is valid
   */
  public checkPhoneNumber(): boolean {
    return this.form.phone_number.length >= 10;
  }

  /**
   * Function to open modal on mobile
   */
  public openModal(): void {
    this.isModalOpen = !this.isModalOpen;
    if (!this.isModalOpen) {
      this.isMessageSent = false;
    }

    // Add menu open class to the body for disabled scroll on mobile when menu is open
    if (this.isModalOpen) {
      this.renderer.addClass(this.document.body, 'menu-open');
    } else {
      this.renderer.removeClass(this.document.body, 'menu-open');
    }
  }

}
