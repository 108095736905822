import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'CardArticle',
  templateUrl: './card-article.component.html',
  styleUrls: ['./card-article.component.scss']
})
export class CardArticleComponent implements OnInit {

  /* Image of the card-article */
  @Input() image: string = '';

  /* Image of the card-article */
  @Input() imageExternal: string = '';

  /* Date of the article */
  @Input() date: string | null = '';

  /* Link of the download */
  @Input() link: string | null = '';

  /* Title of the article */
  @Input() title: string = '';

  /* Description of the card-article */
  @Input() text: string = '';

  /* Style of the card-article */
  @Input() style: string = '';

  /* Button text of the card */
  @Input() btnText: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
