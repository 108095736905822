import { Component, OnInit, NgZone } from '@angular/core';
import { CmsAuthorType, CmsResponseType } from 'src/app/types/cms.types';
import { CmsService } from "../../services/cms.service";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.scss']
})
export class AuthorComponent implements OnInit {
  public authors: Array<CmsAuthorType>= [];


  constructor(
    public cms: CmsService,
    private ngZone: NgZone,
    private titleService: Title,
    private metaService: Meta,
  ) { }
      
  ngOnInit(): void {

    // Set title and meta description
    this.titleService.setTitle("Découvrez notre équipe de rédaction");
    this.metaService.updateTag({ name: 'description', content: "" });

    // Get Popular articles
    this.cms.retrieve("[[at(document.type, \"author\")]]", "[my.author.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.authors = content.results;
      });
    });


  }
}
