import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { Input } from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import { Router } from '@angular/router';

@Component({
  selector: 'TitleAtom',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {

  /** Text in Title */
  @Input() text: string = '';

  /** Type of the title : H1, H2, H3, H4, P */
  @Input() type: string = 'p';

  /** Size of Title */
  @Input() size: string = '';

  /** Margin of Title: empty, auto */
  @Input() margin: string = '';

  /** Color of Title */
  @Input() color: string = '';

  /** Weight of Title */
  @Input() weight: string = '';

  /** Text align: center, left, right */
  @Input() align: string = 'left';

  /** Specific font size for mobile */
  @Input() mobileSize: number = 0;

  /** Href  */
  @Input() href: any = null;

  /** The width and height of the window user */
  public isMobile = false;

  public get classes(): Array<string> {
    const baseClasses = [`title`, `title-style`, this.color, this.size, this.weight, this.align, `margin-${this.margin}`];
    if (this.href) {
      baseClasses.push('clickedLink');
    }
    return baseClasses;
  }
  

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,private router: Router
  ) {}

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1000;

      if (this.isMobile && this.mobileSize == 0) {
        this.mobileSize = 16;
      }
    }
  }

  rem(pxValue: number): string {
    const fontSize = 16;
    return `${pxValue / fontSize}rem`;
  }


}

