import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'SearchSimulation',
  templateUrl: './search-simulation.component.html',
  styleUrls: ['./search-simulation.component.scss']
})
export class SearchSimulationComponent implements OnInit {

  /** Icon of search bar */
  @Input() icon: string = '';

  /** Text on search */
  @Input() searchText: string = '';

  /** CP on search */
  @Input() searchCP: string = '';

  /** Text on search button */
  @Input() text: string = '';

  /** Text on placeholder */
  @Input() placeholder: string = '';

  /** Size of the button */
  @Input() sizeBtn: string = '';

  /** The link of the btn */
  @Input() btnLink: string = '';

  /** The type of Search Simulation component */
  @Input() type: string = '';

  /* Event to notify when searching */
  @Output() searchEvent = new EventEmitter<string>();

  /** Classes for the search-simulation components */
  public classes: string[] = [];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.classes = [`${this.sizeBtn}`];
  }

  /** Function to check if user can submit  */
  public canSubmit(): boolean {
    const digitCount = (this.searchText.match(/\d/g) || []).length;
    return digitCount >= 5;
  }

  /** Function to navigate to simulator with query params */
  public navigateToSimulator() {
    this.router.navigate(['/simulateur-panneau-solaire'], {queryParams: {address: this.searchText, postal_code: this.searchCP}});
  }

  /**
   * Function to get address selected in google recommendation and cp
   * @param string address selected
   */
  public onPlaceSelected(placeData: { formatted_address: string; postal_code: string }) {
    this.searchText = placeData.formatted_address;
    this.searchCP = placeData.postal_code;
  }

  /**
   * Function to search terms
   */
  public onSearch() {
    this.searchEvent.emit(this.searchText)
  }

}
