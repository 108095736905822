<div class="select-atom-container">
  <input type="hidden" [(ngModel)]="selectedValue" />

  <div class="select-atom-display" (click)="toggleDropdown()">
    {{ selectedLabel }}
    <div>
      <Image width="7px" type="icon" image="arrow-bottom-dark"></Image>
    </div>
  </div>

  <ul *ngIf="isDropdownVisible && searchOptions === false">
    <li *ngFor="let option of options" (click)="selectOption(option)">
      <span>{{ option.label }}</span>
    </li>
  </ul>
  <ul *ngIf="isDropdownVisible && searchOptions === true">
    <li>
      <input class="select-search-input" type="text" [(ngModel)]="filterText" (keyup)="filterOptions()" placeholder="Recherchez votre ville...">
    </li>
    <li *ngFor="let option of filteredOptions" (click)="selectOption(option)">
      <span>{{ option.label }}</span>
    </li>
  </ul>
</div>
