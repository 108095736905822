import { Component, NgZone, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { CmsJobType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-hr-detail',
  templateUrl: './hr-detail.component.html',
  styleUrls: ['./hr-detail.component.scss']
})
export class HrDetailComponent implements OnInit {

  /* The job from the CMS */
  public job: CmsJobType| null = null;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private cms: CmsService
  ) { }

  ngOnInit() {
    const jobSlug = this.route.snapshot.paramMap.get('job');

    // Get a job
    this.cms.retrieve(`[[at(document.type, \"job\")][at(my.job.slug, \"${jobSlug}\")]]`, "[my.job.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        if (content.results.length === 0) this.router.navigate(['/recrutement']);
        this.job = content.results[0];

        // Set title and meta description
        this.titleService.setTitle(this.job?.data.metadata_title || "");
        this.metaService.updateTag({ name: 'description', content: this.job?.data.metadata_description || ""});
      });
    });
  }
}
