import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';


declare const google: any;

interface PlaceData {
  formatted_address: string;
  postal_code: string;
}

@Directive({
  selector: '[mapsAutocomplete]'
})
export class MapsAutocompleteDirective implements OnInit {
  @Input() inputElement!: HTMLInputElement;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  private autocomplete: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.autocomplete = new google.maps.places.Autocomplete(this.inputElement, {
        fields: ['address_components', 'geometry', 'formatted_address'],
        types: ['address'],
        componentRestrictions: { country: 'fr' }
      });

      this.autocomplete.addListener('place_changed', () => {
        setTimeout(() => {
          const place = this.autocomplete.getPlace();

          const postal_code = place.address_components.find(
            (component: any) => component.types.indexOf('postal_code') !== -1
          )?.short_name;

          const placeData: PlaceData = {
            formatted_address: place.formatted_address,
            postal_code
          };

          this.onSelect.emit(placeData);
        }, 0);
      });
    }
  }
}
