<div class="card-hiring-container" [ngClass]="classes">
  <Image type="image" width="392px" mobileWidth="100%" imageExternal="{{image}}"></Image>
  <div>
    <TitleAtom [mobileSize]="16" *ngIf="secondTitle !== ''" color="orange" weight="semi-bold" size="tiny" text="{{secondTitle}}"></TitleAtom>
    <TitleAtom [mobileSize]="24" color="dark" weight="semi-bold" size="intermediate" text="{{title}}"></TitleAtom>
    <ul class="hiring-list-icon">
      <li *ngIf="contract !== ''">
        <Image type="icon" width="20px" image="cdi"></Image>
        <ParagraphAtom color="dark" weight="{{fontWeight === 'bold' ? 'semi-bold' : 'regular'}}" size="intermediate" text="{{contract}}"></ParagraphAtom>
      </li>
      <li *ngIf="place !== ''">
        <Image type="icon" width="20px" image="geoloc"></Image>
        <ParagraphAtom [style]="'underline'" color="dark" weight="{{fontWeight === 'bold' ? 'semi-bold' : 'regular'}}" size="intermediate" text="{{place}}"></ParagraphAtom>
      </li>
      <li *ngIf="experience !== ''">
        <Image type="icon" width="20px" image="medal"></Image>
        <ParagraphAtom color="dark" weight="{{fontWeight === 'bold' ? 'semi-bold' : 'regular'}}" size="intermediate" text="Expérience : {{experience}}"></ParagraphAtom>
      </li>
      <li *ngIf="date !== ''">
        <Image type="icon" width="20px" image="calendar-blue"></Image>
        <div>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="<b>Début de mission :</b> {{date}}"></ParagraphAtom>
        </div>
      </li>
      <li *ngIf="fact !== ''">
        <Image type="icon" width="20px" image="star"></Image>
        <ParagraphAtom color="dark" weight="{{fontWeight === 'bold' ? 'semi-bold' : 'regular'}}" size="intermediate" text="{{fact}}"></ParagraphAtom>
      </li>
    </ul>
    <div class="card-hiring-more" *ngIf="moreInfo.length > 0">
      <ParagraphAtom color="dark" size="intermediate" weight="semi-bold" text="Informations supplémentaires :"></ParagraphAtom>
      <CmsHtmlRendererAtom [data]="moreInfo"></CmsHtmlRendererAtom>
<!--      <ul>-->
<!--        <li *ngFor="let info of moreInfo">-->
<!--          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="{{info}}"></ParagraphAtom>-->
<!--        </li>-->
<!--      </ul>-->
    </div>
    <div class="card-hiring-btn" *ngIf="btnText !== ''">
      <ButtonAtom [style]="'basic'" color="yellow" text="{{btnText}}" href="/recrutement/offres/postuler" [queryParams]="{slug: slug}"></ButtonAtom>
    </div>
    <!-- MEDIA SHARING -->
    <div class="hiring-share-media" *ngIf="showShareLinks">
      <ParagraphAtom color="dark" size="intermediate" weight="semi-bold" text="Partager l'offre :"></ParagraphAtom>
      <ul>
        <li>
          <ButtonAtom type="clear" color="blue" icon="facebook-dark" (click)="share('facebook')"></ButtonAtom>
        </li>
        <li>
          <ButtonAtom type="clear" color="blue" icon="twitter-dark" (click)="share('twitter')"></ButtonAtom>
        </li>
        <li>
          <ButtonAtom type="clear" color="blue" icon="linkedin-dark" (click)="share('linkedin')"></ButtonAtom>
        </li>
        <li>
          <ButtonAtom type="clear" color="blue" icon="email-dark" (click)="share('mail')"></ButtonAtom>
        </li>
        <li>
          <ButtonAtom type="clear" color="blue" icon="whatsapp-dark" (click)="share('whatsapp')"></ButtonAtom>
        </li>
      </ul>
    </div>
  </div>
</div>
