import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent {

  /* The width of the user window */
  public currentWidth = 2000;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
  ) {
  }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.currentWidth = window.innerWidth;
    }

    // Set title and meta description
    this.titleService.setTitle("Histoire de Monabee,  acteur du photovoltaique depuis plus de 10 ans");
    this.metaService.updateTag({ name: 'description', content: "Découvrez l'histoire de l'entreprise Monabee, spécialiste de l'énergie solaire depuis 2012. Entreprise engagée pour l'autoconsommation"});
  }

}
