<!-- For Simulation redirection with address -->
<div class="search-simulation-component" *ngIf="type === 'simulation'">
  <InputAtom #inputAtomSearch mapsAutocomplete [inputElement]="inputAtomSearch.inputRef.nativeElement" [(value)]="searchText" [style]="'clear'" icon="{{icon}}" color="light" placeholder="{{placeholder}}" (onSelect)="onPlaceSelected($event)"></InputAtom>
  <ButtonAtom [ngClass]="classes" [isDisabled]="!canSubmit()" size="{{sizeBtn}}" [style]="'basic'" color="yellow" text="{{text}}" (click)="navigateToSimulator()"></ButtonAtom>
</div>

<!-- For FAQ search -->
<div class="search-simulation-component" *ngIf="type === 'faq'">
  <InputAtom [style]="'clear'" icon="{{icon}}" color="light" placeholder="{{placeholder}}" [(value)]="searchText"></InputAtom>
  <ButtonAtom [ngClass]="classes" size="{{sizeBtn}}" href="{{btnLink}}" [style]="'basic'" color="yellow" text="{{text}}" (click)="onSearch()"></ButtonAtom>
</div>


<div class="search-simulation-component-mobile" *ngIf="type === 'simulation'">
  <InputAtom #inputAtomSearchMobile mapsAutocomplete [inputElement]="inputAtomSearchMobile.inputRef.nativeElement" [(value)]="searchText" [style]="'basic'" icon="{{icon}}" color="light" placeholder="{{placeholder}}" (onSelect)="onPlaceSelected($event)"></InputAtom>
  <ButtonAtom [ngClass]="classes" [isDisabled]="!canSubmit()" size="{{sizeBtn}}" height="default" [style]="'basic'" color="yellow" text="{{text}}" (click)="navigateToSimulator()"></ButtonAtom>
</div>

<div class="search-simulation-component-mobile" *ngIf="type === 'faq'">
  <InputAtom [style]="'basic'" icon="{{icon}}" color="light" placeholder="{{placeholder}}" [(value)]="searchText"></InputAtom>
  <ButtonAtom [ngClass]="classes" size="{{sizeBtn}}" height="default" [style]="'basic'" color="yellow" text="{{text}}" (click)="onSearch()"></ButtonAtom>
</div>


