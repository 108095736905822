import { Component, OnInit, NgZone } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsArticleType, CmsCategoryType, CmsResponseType } from "../../types/cms.types";
import { ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-blog-category',
  templateUrl: './blog-category.component.html',
  styleUrls: ['./blog-category.component.scss']
})
export class BlogCategoryPageComponent implements OnInit {

  /* The current category */
  public category: CmsCategoryType | null = null;

  /* The articles from the CMS */
  public articles: Array<CmsArticleType> = [];

  /* The articles choose for the headline from the CMS */
  public articleHeadline: CmsArticleType | null = null;

  /* The popular articles from the CMS */
  public articlesPopular: Array<CmsArticleType> = [];

  constructor(
    private titleService: Title,
    private metaService: Meta,
    public cms: CmsService,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Get headline
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"Mis en avant\"])]]", "[my.blog.published_at desc]", 1, 1).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articleHeadline = content.results[0];
      });
    });

    // Get Popular articles
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"Populaire\"])]]", "[my.blog.published_at desc]", 1, 5).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articlesPopular = content.results;
      });
    });

    // Get the current category slug
    const categorySlug = this.route.snapshot.paramMap.get('category');

    // Get categories and articles
    this.cms.retrieve(`[[at(document.type, \"blog_category\")][at(my.blog_category.slug, \"${categorySlug}\")]]`, "[my.blog_category.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.category = content.results[0];

        // Set title and meta description
        this.titleService.setTitle(this.category?.data.metadata_title || "");
        this.metaService.updateTag({ name: 'description', content: this.category?.data.metadata_description || ""});

        // Retrieve the categories
        this.cms.retrieve(`[[at(document.type, \"blog\")][at(my.blog.category, \"${this.category?.id}\")]]`, "[my.blog.published_at desc]").then((content: CmsResponseType) => {
          this.ngZone.run(() => {
            this.articles = [...content.results, ...this.articles];
          });
        });
      });
    });
  }
}
