import { Component, OnInit, NgZone } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsArticleType, CmsPageType, CmsResponseType } from "../../types/cms.types";
import { ActivatedRoute, Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { StructuredDataService } from "../../services/structured-data.service";

@Component({
  selector: 'app-content-cms',
  templateUrl: './content-cms.component.html',
  styleUrls: ['./content-cms.component.scss']
})
export class ContentCmsPageComponent implements OnInit {


  /**
   *  The page from the CMS
   */
  public page: CmsPageType | null = null;

  /**
   *  The article from the CMS *
   */
  public articlesDiscovery: Array<CmsArticleType> = [];


  constructor(
    private titleService: Title,
    private metaService: Meta,
    public cms: CmsService,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private structuredDataService: StructuredDataService
  ) { }

  ngOnInit(): void {

    const structuredDataList: any[] = [];
    const pageSlug = this.route.snapshot.paramMap.get('page');

    // Get Discovery articles
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"A découvrir également\"])]]", "[my.blog.published_at desc]", 1, 5).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articlesDiscovery = content.results;

        // Add structured data for each article
        for (let article of this.articlesDiscovery) {
          structuredDataList.push(this.structuredDataService.createArticleStructuredData(article));  // Add this line
        }
      });
    });

    // Get page
    this.cms.retrieve(`[[at(document.type, \"page\")][at(my.page.slug, \"${pageSlug}\")]]`, "[my.page.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        if (content.results.length === 0) this.router.navigate(['/blog']);
        this.page = content.results[0];

        // Set title and meta description
        this.titleService.setTitle(this.page?.data.metadata_title || "");
        this.metaService.updateTag({ name: 'description', content: this.page?.data.metadata_description || ""});

        // Add structured data for the page
        this.structuredDataService.addNewsArticle(structuredDataList);
      });
    });
  }
}
