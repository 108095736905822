<div class="card-article-component" [ngClass]="this.style">
  <Image size="{{this.style === 'card' ? '' : 'full'}}" borderRadius="{{this.style === 'card' ? '' : 'radius'}}" width="{{this.style === 'card' ? '100%' : '300px'}}" mobileWidth="100%" type="image" image="{{image}}" imageExternal="{{imageExternal}}"></Image>
  <div>
    <ParagraphAtom color="dark" weight="regular" size="intermediate" text="{{date}}"></ParagraphAtom>
    <ParagraphAtom color="dark" weight="bold" size="large" text="{{title}}"></ParagraphAtom>
  </div>
  <ParagraphAtom align="left" color="dark" weight="regular" size="intermediate" text="{{text}}"></ParagraphAtom>
  <div *ngIf="btnText !== ''" class="card-content-download">
    <ButtonAtom *ngIf="link !== ''" [style]="'clear'" color="dark" text="{{btnText}}" icon="download" href="{{ link }}" target="_blank"></ButtonAtom>
    <ButtonAtom *ngIf="link === ''" [style]="'clear'" color="dark" text="{{btnText}}" icon="download"></ButtonAtom>
  </div>
</div>
