import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, NgZone, Inject, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'GoogleRatingAtom',
  templateUrl: './google-rating.component.html',
  styleUrls: ['./google-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleRatingComponent implements OnInit {

  /* Style of the review */
  @Input() style: string = "";

  /* If the google logo appears */
  @Input() hasIcon: boolean = true;

  /* Google rating */
  public rating: string = '4,6';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getGoogleReviews();
  }

  /**
   * Get the google reviews
   */
  public getGoogleReviews(): void {
    if (isPlatformBrowser(this.platformId)) {
      const placeId = 'ChIJYTlcBKzs9EcRkK7WiWxzT7w';
      const placesService = new google.maps.places.PlacesService(document.createElement('div'));

      // Request place details including reviews
      placesService.getDetails({ placeId: placeId, fields: ['rating'], language: 'fr-fr' }, (place: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.ngZone.run(() => {
            this.rating = String(place.rating).replace('.', ',');
            this.cd.markForCheck();
          });
        }
      });
    }
  }

  /**
   * Redirect to the google review
   */
  public redirect() {
    window.open("https://www.google.com/search?q=monabee+#rlimm=13569191112296803984", '_blank');
  }
}
