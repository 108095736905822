import {Component, OnInit, Input, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";


@Component({
  selector: 'Notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  /** The width and height of the window user */
  public isMobile = false;

  /** Text in notification */
  @Input() public text: string = "";

  /** Type of notification */
  @Input() public type: string = "";

  /** Notification is hidden */
  @Input() public isActive: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }
  }

}
