import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { CmsFaqType, CmsArticleType } from '../types/cms.types';
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class StructuredDataService {

  private renderer2: Renderer2;

  constructor(
    private rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  public addVideo(videoName: string) {
    const script = this.renderer2.createElement('script');
    script.type = `application/ld+json`;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": videoName,
      "thumbnailUrl": "../assets/images/logo.png",
      "uploadDate": new Date().toISOString(),
      "embedUrl": videoName
    };

    // Convert the 'structuredData' JavaScript object into a JSON string
    script.text = JSON.stringify(structuredData);

    this.renderer2.appendChild(this.document.head, script);
  }

  public addNewsArticle(structuredDataList: any[]) {
    const script = this.renderer2.createElement('script');
    script.type = `application/ld+json`;

    script.text = JSON.stringify(structuredDataList);

    this.renderer2.appendChild(this.document.head, script);
  }

  public createArticleStructuredData(article: CmsArticleType) {
    return {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": `https://monabee.com/blog/${article.data.slug}`
      },
      "headline": article.data.title,
      "image": [
        article.data.image.url
      ],
      "datePublished": new Date(article.data.published_at).toISOString(),
      "dateModified": new Date(article.data.published_at).toISOString(),
      "publisher": {
        "@type": "Organization",
        "name": "Monabee",
        "logo": {
          "@type": "ImageObject",
          "url": "../assets/images/logo.png"
        }
      },
      "description": article.data.summary
    };
  }

  public addBreadcrumb(breadcrumbList: Array<any>) {

    // Filter out any items that do not have a 'title' property
    breadcrumbList = breadcrumbList.filter(item => item.title !== null && item.title !== undefined);

    const script = this.renderer2.createElement('script');
    script.type = `application/ld+json`;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": breadcrumbList.map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": item.title,
        "item": item.link
      }))
    };

    // Convert the 'structuredData' JavaScript object into a JSON string
    script.text = JSON.stringify(structuredData);

    this.renderer2.appendChild(this.document.head, script);
  }

  public addFaq(faqs: Array<CmsFaqType>) {

    const script = this.renderer2.createElement('script');
    script.type = `application/ld+json`;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": faqs.map((faq) => ({
        "@type": "Question",
        "name": faq.data.title,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.data.body[0].text
        }
      }))
    };

    // Convert the 'structuredData' JavaScript object into a JSON string
    script.text = JSON.stringify(structuredData);

    this.renderer2.appendChild(this.document.head, script);
  }

}
