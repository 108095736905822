<div class="offer-pro-container">
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <!-- HEROBANNER -->
  <div class="offer-herobanner">

    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Professionnels'}]"></Breadcrumb>
    </div>

    <div id="offer-pro-form" class="offer-herobanner-content">
      <div id="proOffer" class="content">
        <TitleAtom [mobileSize]="18" color="yellow" weight="semi-bold" size="small" [text]="cmsData?.data?.herobanner_title_yellow || ''"></TitleAtom>
        <TitleAtom type="h1" color="light" weight="semi-bold" size="x-large" [text]="cmsData?.data?.herobanner_title_h1 || ''"></TitleAtom>

        <ParagraphAtom color="light" weight="regular" size="intermediate" [text]="cmsData?.data?.herobanner_text_one || ''"></ParagraphAtom>

        <ParagraphAtom color="light" weight="regular" size="intermediate" [text]="cmsData?.data?.herobanner_text_two || ''"></ParagraphAtom>
        <br>
        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="tiny" [text]="cmsData?.data?.herobanner_certification_title || ''"></TitleAtom>
        <div>
          <div class="offer-certification">
            <Image width="91px" type="image" [imageExternal]="cmsData?.data?.herobanner_rge_img?.url || ''"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData?.data?.herobanner_rge_text || ''"></ParagraphAtom>
            </div>
          </div>
          <div class="offer-certification">
            <Image width="81px" type="image" [imageExternal]="cmsData?.data?.herobanner_authorization_img?.url || ''"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData?.data?.herobanner_authorization_text || ''"></ParagraphAtom>
            </div>
          </div>
        </div>
      </div>

      <ContactMessage *ngIf="cmsData?.data?.herobanner_form_btn_text" [hideRadioBtn]="true" [disableUserTypeChange]="true" [title]="cmsData?.data?.herobanner_form_title || ''" [btnText]="cmsData?.data?.herobanner_form_btn_text || ''" userType="professionnel"></ContactMessage>
      <div class="offer-banner"></div>
    </div>

  </div>

  <!-- FORM VALIDATION MODAL -->
  <div *ngIf="this.formIsValidated === true" class="offer-form-validation-modal-container">
    <div class="offer-form-validation-modal">
      <div>
        <TitleAtom color="light" weight="semi-bold" size="x-heading" text="Merci !"></TitleAtom>
        <div class="close-btn">
          <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeValidateForm()"></ButtonAtom>
        </div>
      </div>
      <div>
        <ParagraphAtom color="dark" weight="regular" size="large" text="Votre demande de devis a bien été transférée à nos équipes Monabee. Vous allez rapidement être recontacté par l’un de nos conseillers."></ParagraphAtom>
        <ButtonAtom [style]="'basic'" color="yellow" text="Fermer" (click)="closeValidateForm()"></ButtonAtom>
      </div>
    </div>
  </div>

  <!-- ADVANTAGES -->
  <div id="proAdvantages" class="professional-advantages" *ngIf="(cmsData?.data?.advantages_list?? []).length > 0">
    <div id="advantageList" class="advantage-list">
      <div class="advantage-content">
        <div>
          <div class="advantage-title">
            <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" [text]="cmsData?.data?.advantages_title || ''"></TitleAtom>
          </div>
          <ul>
            <li *ngFor="let item of cmsData?.data?.advantages_list">
              <div class="advantage-item">
                <div class="advantage-icon">
                  <Image type="icon" width="64px" [imageExternal]="item.advantage_img?.url || ''"></Image>
                </div>
                <div class="advantage-content">
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" [text]="item.advantage_title || ''"></TitleAtom>
                  <ParagraphAtom color="dark" size="medium" weight="regular" [text]="item.advantage_text || ''"></ParagraphAtom>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- OUR-SERVICES -->
  <div id="ourServices" class="our-services-container" *ngIf="(cmsData?.data?.services_list ?? []).length > 0">
    <div id="serviceList" class="service-list">
      <div class="service-content">
        <div>
          <div class="service-title">
            <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" [text]="cmsData?.data?.services_title || ''"></TitleAtom>
          </div>
          <ul>
            <li *ngFor="let item of cmsData?.data?.services_list">
              <div class="service-item" [routerLink]="item.service_link">
                <Image width="288px" mobileWidth="100%" type="image" [imageExternal]="item.service_img?.url || ''" borderRadius="radius"></Image>

                <div class="discover-link">
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" [text]="item.service_title || ''"></TitleAtom>
                  <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- YOU-ARE -->
  <div id="youAre" class="you-are-container" *ngIf="(cmsData?.data?.you_list ?? []).length > 0">
    <div id="youAreList" class="you-are-list">
      <div class="you-are-content">
        <div>
          <div class="you-are-title">
            <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" [text]="cmsData?.data?.you_title || ''"></TitleAtom>
            <ParagraphAtom color="dark" align="center" size="large" weight="regular" [text]="cmsData?.data?.you_text || ''"></ParagraphAtom>
          </div>
          <ul>
            <li *ngFor="let item of cmsData?.data?.you_list">
              <div class="you-are-item">
                <Image type="icon" width="64px" [imageExternal]="item.you_img?.url || ''"></Image>
                <div class="you-are-details">
                  <TitleAtom [mobileSize]="16" type="h3" color="dark" size="medium" weight="semi-bold" [text]="item.you_list_title || ''"></TitleAtom>
                  <div class="discover-link" [routerLink]="item.you_list_link">
                    <ParagraphAtom color="dark" size="medium" weight="bold" [text]="item.you_list_btn_text || ''"></ParagraphAtom>
                    <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="middle-btn-devis" *ngIf="cmsData?.data?.you_btn_text">
    <ButtonAtom [style]="'basic'" height="default" color="yellow" [text]="cmsData?.data?.you_btn_text || ''" (click)="scrollToInquiry()"></ButtonAtom>
  </div>

  <!-- ADDITIONAL SERVICES -->
  <div id="additionalServices" class="additional-services-container" *ngIf="(cmsData?.data?.slider_steps_list ?? []).length > 0">
    <div class="additional-services-header">
      <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" [text]="cmsData?.data?.slider_title || ''"></TitleAtom>
      <ParagraphAtom color="dark" size="medium" align="center" weight="regular" [text]="cmsData?.data?.slider_subtitle || ''"></ParagraphAtom>
    </div>

    <div class="additional-services-content">
      <div *ngFor="let step of cmsData?.data?.slider_steps_list; let i = index" [ngClass]="{'slider-active': stepCount === i + 1}" class="slider-step" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
        <div class="slider-container">
          <div class="slider-content">
            <div class="slider-btn-left">
              <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
            </div>

            <div class="additional-services-image">
              <Image type="image" width="100%" mobileWidth="344px" [imageExternal]="step.slider_step_img?.url || ''" borderRadius="radius"></Image>
            </div>

            <div class="additional-services-description">
              <div class="additional-services-title">
                <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" [text]="step.slider_step_title || ''"></TitleAtom>
              </div>
              <div class="additional-services-subtitle">
                <ParagraphAtom color="dark" size="medium" weight="regular" [text]="step.slider_step_subtitle || ''"></ParagraphAtom>
              </div>
              <div>
                <ParagraphAtom color="dark" size="medium" weight="regular" [text]="step.slider_step_content || ''"></ParagraphAtom>
              </div>
            </div>

            <div class="slider-btn-right">
              <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
            </div>
          </div>

          <div class="slider-pagination">
            <ul>
              <li *ngFor="let step of cmsData?.data?.slider_steps_list; let j = index" (click)="goToStep(j + 1)">
                <Image type="icon" width="8px" [image]="stepCount === j + 1 ? 'ellipsis-yellow' : 'ellipsis-grey'"></Image>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- CUSTOMER REVIEWS -->
  <div id="customersReviews" class="customer-review-container" *ngIf="cmsData?.data?.reviews_title">
    <TitleAtom align="center" color="dark" size="x-large" [mobileSize]="22" weight="semi-bold" [text]="cmsData?.data?.reviews_title || ''"></TitleAtom>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-top-left" type="icon" image="customer-reviews-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-bottom-left" type="icon" image="customer-reviews-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-bottom-right" type="icon" image="customer-reviews-bottom-right"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-top-right" type="icon" image="customer-reviews-top-right"></Image>

    <Image *ngIf="currentWidth < 1000" class="customer-reviews-top-left" type="icon" image="customer-reviews-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-bottom-left" type="icon" image="customer-reviews-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-bottom-right" type="icon" image="customer-reviews-bottom-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-top-right" type="icon" image="customer-reviews-top-right-mobile"></Image>
    <div class="scroll-parent">
      <ul class="scroll-element first">
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>
      </ul>
    </div>
    <GoogleRatingAtom [style]="'dark'"></GoogleRatingAtom>
  </div>

  <!-- OUR workTestimonials -->
  <div id="workTestimonials" class="work-container" *ngIf="(cmsData?.data?.achievements_list ?? []).length > 0">
    <div>
      <TitleAtom type="h2" size="tiny" [mobileSize]="16" align="center" weight="semi-bold" color="orange" [text]="cmsData?.data?.achievements_orange_title || ''"></TitleAtom>
      <TitleAtom type="p" size="x-large" [mobileSize]="22" align="center" weight="semi-bold" color="dark" [text]="cmsData?.data?.achievements_title || ''"></TitleAtom>
    </div>
    <ul>
      <li *ngFor="let testimonial of cmsData?.data?.achievements_list">
        <a [routerLink]="['/blog', testimonial.achievement_slug]">
          <Content
            type="image"
            [imageExternal]="testimonial.achievement_img?.url || ''"
            [title]="testimonial.achievement_title || ''"
            [textHeadline]="testimonial.achievement_label || ''"
            [text]="testimonial.achievement_subtitle || ''"
            [name]="''">
          </Content>
        </a>
      </li>
    </ul>
    <ButtonAtom [style]="'underline'" [href]="cmsData?.data?.achievements_btn_link || ''" color="dark" [text]="cmsData?.data?.achievements_btn_text || ''"></ButtonAtom>
  </div>

  <!-- PROJECT INQUIRY -->
  <div id="projectInquiry" class="project-inquiry-container" *ngIf="cmsData?.data?.contact_title && cmsData?.data?.contact_btn_text">
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-top-left" type="icon" image="customer-reviews-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-bottom-left" type="icon" image="customer-reviews-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-bottom-right" type="icon" image="customer-reviews-bottom-right"></Image>
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-top-right" type="icon" image="customer-reviews-top-right"></Image>

    <Image *ngIf="currentWidth < 1000" class="project-inquiry-top-left" type="icon" image="customer-reviews-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="project-inquiry-bottom-left" type="icon" image="customer-reviews-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="project-inquiry-bottom-right" type="icon" image="customer-reviews-bottom-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="project-inquiry-top-right" type="icon" image="customer-reviews-top-right-mobile"></Image>
    <div class="project-inquiry-form">
      <ContactMessage [hideRadioBtn]="true" type="contact-pro-light" [disableUserTypeChange]="true" [title]="cmsData?.data?.contact_title || ''" [btnText]="cmsData?.data?.contact_btn_text || ''" userType="professionnel"></ContactMessage>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

<!-- CONTACT MODAL -->
  <div *ngIf="this.getContactModal === true" class="offer-contact-modal-container">
    <div class="offer-contact-modal">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeContactModal()"></ButtonAtom>
      <TitleAtom color="dark" weight="semi-bold" size="large" text="Choisissez votre mode de contact"></TitleAtom>
      <ul>
        <li>
          <CardInline [style]="'basic'" image="appointment" title="Prendre un rendez-vous téléphonique" btnText="Prendre rendez-vous" btnIcon="calendar" (btnClick)="userGetMeeting()" text="Un appel dure 30 min en moyenne. Vous souhaitez être rappelé à une date/heure précise car vous avez des questions ? Un Conseiller en Energie Solaire vous répond et vous conseille sur votre projet."></CardInline>
        </li>
        <li>
          <CardInline [style]="'basic'" image="callback" title="Être rappelé immédiatement" btnText="Être rappelé" btnIcon="phone" (btnClick)="userGetCall()" text="Vous souhaitez être rappelé immédiatement car vous avez des questions ? Un Conseiller en Energie Solaire vous répond."></CardInline>
        </li>
        <li>
          <CardInline [style]="'basic'" image="phone-contact" title="Appelez-nous !" btnText="04 82 53 72 45" btnIcon="phone" text="Vous êtes disponible immédiatement ? Notre centre d’appel répond à vos premières interrogations et vous oriente vers un Conseiller en Energie Solaire si vous le désirez ensuite."></CardInline>
        </li>
        <li>
          <CardInline [style]="'basic'" image="message" title="Discutons ensemble" btnText="Envoyer un message" btnIcon="message-dark" (btnClick)="userGetMessage()" text="Envoyez-nous votre message. Questions, demande de conseils sur votre projet photovoltaïque... Nous traitons votre demande pour vous conseiller au mieux."></CardInline>
        </li>
      </ul>
    </div>
  </div>

  <!-- MEETING MODAL -->

  <!-- MEETING-STEP-1 -->
  <div *ngIf="this.getMeeting === true" class="call-container">
    <div class="meeting-section">
      <!-- HEADER -->
      <div class="meeting-top">
        <div>
          <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMeetingModal()"></ButtonAtom>
        </div>
        <TitleAtom color="light" weight="semi-bold" size="large" text="Vous souhaitez prendre un rendez-vous téléphonique"></TitleAtom>
      </div>

      <!-- FORM & CALENDAR -->
      <div class="meeting-bottom">
        <div>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
          <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" placeholder="Saisissez votre adresse" [style]="'basic'" color="{{isPostalCodeNotAvailable() ? 'red' : 'light'}}" name="address" [(value)]="this.formDatas.cp" (onSelect)="onPlaceSelected($event)"></InputAtom>
          <ParagraphAtom *ngIf="isPostalCodeNotAvailable()" color="red" size="small" weight="semi-bold" text="Monabee n’est pas encore présent dans votre ville."></ParagraphAtom>
        </div>
        <div class="calendar-container">
          <dp-day-calendar [(ngModel)]="selectedDate" [theme]="'dp-material'" [config]="datePickerConfig"></dp-day-calendar>
          <div>
            <ParagraphAtom *ngIf="selectedDate === undefined" color="dark" size="intermediate" weight="semi-bold" text="Veuillez sélectionner une date"></ParagraphAtom>
            <ParagraphAtom *ngIf="selectedDate !== undefined" color="dark" size="intermediate" weight="semi-bold" text="{{getFormattedDate(selectedDate)}}"></ParagraphAtom>
            <div *ngIf="selectedDate" class="time-container">
              <ButtonAtom class="time-navigation-btn" [style]="'stroked'" color="light" icon="arrow-top" (click)="showLessTimes()" *ngIf="timesIndex > 0"></ButtonAtom>
              <ul>
                <li *ngFor="let time of getDisplayedTimes()" (click)="onSelectChange(4, time)">
                  <button class="time-selection-btn" [ngClass]="{'selected-time': time === this.formDatas.meetingHour}">{{time}}</button>
                </li>
              </ul>
              <ButtonAtom class="time-navigation-btn" [style]="'stroked'" color="light" icon="arrow-bottom" *ngIf="timesIndex < 3" (click)="showMoreTimes()"></ButtonAtom>
            </div>
          </div>
        </div>
      </div>
      <div class="meeting-btn">
        <ButtonAtom [style]="'basic'" [isDisabled]="isPostalCodeNotAvailable() || !isPostalCodeValid() || this.formDatas.meetingDate === undefined || this.formDatas.meetingHour === ''" color="yellow" text="Envoyer ma demande" (click)="validateMeeting(1)"></ButtonAtom>
      </div>
    </div>
  </div>

  <!--MEETING-STEP-2-->
  <div *ngIf="this.meetingDateIsValidated === true" class="call-container">
    <div class="meeting-form-section">
      <div class="meeting-form-top">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMeetingModal()"></ButtonAtom>
        <ButtonAtom [style]="'clear'" icon="arrow-left-dark" (click)="userGetMeeting()"></ButtonAtom>
        <TitleAtom color="dark" size="large" weight="semi-bold" text="Vos informations"></TitleAtom>
        <ButtonRadio name="pro-meeting" [style]="'second'" [options]="this.currentWidth > 1024 ? [{value: 'particulier', label: 'Je suis un particulier'}, {value: 'pro', label: 'Je suis un professionnel'}] : [{value: 'particulier', label: 'Particulier'}, {value: 'pro', label: 'Professionnel'}]" (selectedValueChange)="onSelectChange(3, $event)"></ButtonRadio>
        <ul>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.name"></InputAtom>
          </li>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" name="email" type="email" [(value)]="formDatas.email"></InputAtom>
          </li>
          <li *ngIf="this.formDatas.userType2 === 'pro'">
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre société*"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" name="company" [(value)]="formDatas.company"></InputAtom>
          </li>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
            <InputAtom type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.phoneNumber"></InputAtom>
          </li>
        </ul>
      </div>
      <div class="meeting-btn">
        <ButtonAtom [style]="'basic'" [isDisabled]="this.formDatas.userType2 === 'pro' ? this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.company === '' : this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.cp === ''" color="yellow" text="Valider" (click)="validateMeeting(3)"></ButtonAtom>
      </div>
    </div>
  </div>

  <!-- MEETING-STEP-3 -->
  <div *ngIf="this.meetingIsValidated === true" class="call-container">
    <div class="call-isValidate">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMeetingModal()"></ButtonAtom>
      <Image type="icon" image="check-blue" width="56px"></Image>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Votre prise de rendez-vous a bien été confirmée"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Un Conseiller en Energie Solaire vous rappellera"></ParagraphAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="le {{getFormattedDate(selectedDate)}} à {{this.formDatas.meetingHour}}"></ParagraphAtom>
      <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="closeMeetingModal()"></ButtonAtom>
    </div>
  </div>

  <!-- CALL MODAL -->

  <!-- CALL MODAL-STEP-1 -->
  <div *ngIf="this.getCall === true && this.callIsValidated === false" class="call-container">
    <div class="call-section">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeCallModal()"></ButtonAtom>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Vous souhaitez être rappelé(e) par un Conseiller"></TitleAtom>
      <div>
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Téléphone*"></ParagraphAtom>
        <InputAtom type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.phoneNumber"></InputAtom>
      </div>
      <ButtonAtom [style]="'basic'" [isDisabled]="this.formDatas.phoneNumber.length < 10" color="yellow" text="Valider" (click)="validateCall()"></ButtonAtom>
    </div>
  </div>

  <!-- CALL MODAL-STEP-2 -->
  <div *ngIf="this.getCall === false && this.callIsValidated === true" class="call-container">
    <div class="call-isValidate">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeCallModal()"></ButtonAtom>
      <Image type="icon" image="check-blue" width="56px"></Image>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Votre demande a bien été prise en compte"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Un Conseiller en Energie Solaire vous rappelle dès que possible."></ParagraphAtom>
      <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="closeCallModal()"></ButtonAtom>
    </div>
  </div>

  <!-- SEND MESSAGE MODAL -->

  <!-- SEND-MESSAGE-STEP-1 -->
  <div *ngIf="this.openMessage === true && this.messageIsValidated === false" class="call-container">
    <div class="message-section">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMessageModal()"></ButtonAtom>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Discutons ensemble"></TitleAtom>
      <ButtonRadio name="pro-message" [style]="'second'" [options]="this.currentWidth > 1024 ? [{value: 'particulier', label: 'Je suis un particulier'}, {value: 'pro', label: 'Je suis un professionnel'}] : [{value: 'particulier', label: 'Particulier'}, {value: 'pro', label: 'Professionnel'}]" (selectedValueChange)="onSelectChange(5, $event)"></ButtonRadio>
      <ul>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" name="name" [(value)]="formDatas.name"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
          <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" placeholder="Saisissez votre adresse" [style]="'basic'" color="light" name="address" [(value)]="this.formDatas.cp" (onSelect)="onPlaceSelected($event)"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" name="email" type="email" [(value)]="formDatas.email"></InputAtom>
        </li>
        <li *ngIf="this.formDatas.userType3 === 'pro'">
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre société*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" name="company" [(value)]="formDatas.company"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
          <InputAtom type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.phoneNumber"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre message"></ParagraphAtom>
          <div class="textarea-container">
            <TextareaAtom [(value)]="this.formDatas.message"></TextareaAtom>
          </div>
        </li>
      </ul>
      <div>
        <ButtonAtom [style]="'basic'" [isDisabled]="this.formDatas.userType3 === 'pro' ? this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.cp === '' || this.formDatas.company === '' : this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.cp === ''" color="yellow" text="Envoyer ma demande" (click)="validateMessage()"></ButtonAtom>
      </div>
    </div>
  </div>

  <!-- SEND-MESSAGE-STEP-2 -->
  <div *ngIf="this.openMessage === false && this.messageIsValidated === true" class="call-container">
    <div class="call-isValidate">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMessageModal()"></ButtonAtom>
      <Image type="icon" image="check-blue" width="56px"></Image>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Votre message a bien été envoyé !"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Nous vous répondrons dans les plus brefs délais"></ParagraphAtom>
      <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="closeMessageModal()"></ButtonAtom>
    </div>
  </div>

</div>
