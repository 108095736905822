import {Component, OnInit, Input, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'Content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss']
})
export class CardContentComponent implements OnInit {

  /* Date of the content card */
  @Input() date: string = '';

  /* Title of the content card */
  @Input() title: string = '';

  /* Text of the content card */
  @Input() text: string = '';

  /* Text of the content card */
  @Input() textHeadline: string = '';

  /* Name in the content card */
  @Input() name: string = '';

  /* Video of the content card */
  @Input() video: string = '';

  /* Image of the content card */
  @Input() image: string = '';

  /* External Image of the content card */
  @Input() imageExternal: string = '';

  /* Format of the content card video */
  @Input() format: string = '';

  /* Type of the content card */
  @Input() type: string = '';

  /* Size of the content card */
  @Input() size: string = '';

  /* Size of the content card */
  @Input() href: string = '';

  /* Width of the image */
  public imageWidth: string = '';

  /** The width and height of the window user */
  public isMobile = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }

    this.imageWidth = this.size === 'small' ? '393px' : '575px';
  }

}
