import { Component, OnInit, NgZone } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsArticleType, CmsCategoryType, CmsResponseType } from "../../types/cms.types";
import { Meta, Title } from "@angular/platform-browser";
import { StructuredDataService } from "../../services/structured-data.service";

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListPageComponent implements OnInit {

  /* The categories from the CMS */
  public categories: Array<CmsCategoryType> = [];

  /* The articles from the CMS */
  public articles: Array<CmsArticleType> = [];

  /* The articles choose for the headline from the CMS */
  public articleHeadline: CmsArticleType | null = null;

  /* The popular articles from the CMS */
  public articlesPopular: Array<CmsArticleType> = [];

  constructor(
    public cms: CmsService,
    private ngZone: NgZone,
    private titleService: Title,
    private metaService: Meta,
    private structuredDataService: StructuredDataService
  ) { }

  ngOnInit(): void {

    const structuredDataList: any[] = [];

    // Set title and meta description
    this.titleService.setTitle("Conseils et actualités sur l'énergie solaire et l'autoconsommation");
    this.metaService.updateTag({ name: 'description', content: "Découvrez les actualités et conseils de Monabee dans le secteur de l'énergie solaire, du photovoltaïque et de l'autoconsommation, pour être bien informé." });

    // Get headline
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"Mis en avant\"])]]", "[my.blog.published_at desc]", 1, 1).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articleHeadline = content.results[0];
      });
    });

    // Get Popular articles
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"Populaire\"])]]", "[my.blog.published_at desc]", 1, 5).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articlesPopular = content.results;
      });
    });

    // Get categories and articles
    this.cms.retrieve("[[at(document.type, \"blog_category\")]]", "[my.blog_category.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.categories = content.results;

        let promises = [];

        for (let category of this.categories) {
          // Retrieve the categories
          let promise = this.cms.retrieve(`[[at(document.type, \"blog\")][at(my.blog.category, \"${category.id}\")]]`, "[my.blog.published_at desc]", 1, 4).then((content: CmsResponseType) => {
            this.ngZone.run(() => {
              this.articles = [...content.results, ...this.articles];
              category.data.total = content.results.length;

              // Add structured data for each article
              for (let article of this.articles) {
                structuredDataList.push(this.structuredDataService.createArticleStructuredData(article));
              }
            });
          });

          promises.push(promise);
        }

        Promise.all(promises).then(() => {
          // Add structured data for the page
          this.structuredDataService.addNewsArticle(structuredDataList);
        });
      });
    });


  }
}
