import { Component, OnInit, NgZone } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsArticleType, CmsPageType, CmsResponseType } from "../../types/cms.types";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-content-cms-2',
  templateUrl: './content-cms-2.component.html',
  styleUrls: ['./content-cms-2.component.scss']
})
export class ContentCms2PageComponent implements OnInit {


  /**
   *  The page from the CMS
   */
  public page: CmsPageType | null = null;

  /**
   *  The article from the CMS *
   */
  public articlesDiscovery: Array<CmsArticleType> = [];


  constructor(
    private titleService: Title,
    private metaService: Meta,
    public cms: CmsService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.refresh();

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.refresh();
      }
    });
  }

  /** Refresh the content */
  public refresh() {
    const pageSlug = this.route.snapshot.url[0].path;

    // Get Discovery articles
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"A découvrir également\"])]]", "[my.blog.published_at desc]", 1, 5).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articlesDiscovery = content.results;
      });
    });

    // Get an article
    this.cms.retrieve(`[[at(document.type, \"page\")][at(my.page.slug, \"${pageSlug}\")]]`, "[my.page.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.page = content.results[0];

        // Set title and meta description
        this.titleService.setTitle(this.page?.data.metadata_title || "");
        this.metaService.updateTag({ name: 'description', content: this.page?.data.metadata_description || ""});
      });
    });
  }
}
