<div class="blog-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb *ngIf="article !== null" [links]="[{title: 'Nos actualités et conseils', link: '/blog'}, {title: article.data.title}]"></Breadcrumb>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="blog-content">
    <div class="blog-left-container">
      <ng-container *ngIf="article !== null">
        <TitleAtom class="blog-title" type="h1" text="{{ article.data.title }}" align="left" color="dark" size="x-large" weight="semi-bold"></TitleAtom>

        <div>
          <div class="section-left">
            <div class="header">
              <div>
                <a routerLink="/auteurs"><ParagraphAtom size="intermediate" weight="regular" align="left" text="{{ article.data.author.length > 0 ? cms.textStyle(article.data.author[0]) : '' }}"></ParagraphAtom></a>
                <ParagraphAtom *ngIf="!article.data.updated_at" size="intermediate" weight="regular" align="left" text="Publié le {{ article.data.published_at | date: 'dd MMMM YYYY': '': 'fr-FR' }}"></ParagraphAtom>
                <ParagraphAtom *ngIf="article.data.updated_at" size="intermediate" weight="regular" align="left" text="Mis à jour le {{ article.data.updated_at | date: 'dd MMMM YYYY': '': 'fr-FR' }}"></ParagraphAtom>
              </div>

              <div>
                <ButtonAtom type="share" [style]="'stroked'" icon="share" size="small" color="light" text="Partager"></ButtonAtom>
              </div>
            </div>

            <div class="body">
              <div>
                <Image imageExternal="{{ article.data.image.url }}" alt="{{ article.data.image.alt }}" width="800px" mobileWidth="100%" borderRadius="radius"></Image>
              </div>
              <CmsHtmlRendererAtom
                *ngIf="article.data.body.length > 0"

                [data]="article.data.body"
                [headline]="article.data.headline || []"
                [array1]="article.data.array_1"
                [array2]="article.data.array_2"
                [array3]="article.data.array_3"
                [array4]="article.data.array_4"
                [array5]="article.data.array_5"
                [array6]="article.data.array_6"
                [array7]="article.data.array_7"
                [array8]="article.data.array_8"
                [array9]="article.data.array_9"
                [array10]="article.data.array_10"
              ></CmsHtmlRendererAtom>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- FORM -->
      <div class="prismic-form-bottom" *ngIf="article !== null && article!.data.select === FORM_TYPE.CONTACT">
        <TitleAtom align="center" *ngIf="isMobile" color="dark" weight="semi-bold" size="intermediate" text="Discutons ensemble"></TitleAtom>
        <ContactMessage title="Je veux être contacté" btnText="Envoyer ma demande"></ContactMessage>
      </div>
      <div  class="prismic-form-bottom" *ngIf="article !== null && article!.data.select === FORM_TYPE.MEETING">
        <CardInline [style]="'basic'" image="appointment" title="Prendre un rendez-vous téléphonique" btnText="Prendre rendez-vous" btnIcon="calendar" (btnClick)="handleMeetingModal()" text="Un appel dure 30 min en moyenne. Vous souhaitez être rappelé à une date/heure précise car vous avez des questions ? Un Conseiller en Energie Solaire vous répond et vous conseille sur votre projet."></CardInline>
        <ContactMeeting [(isHidden)]="hideMeetingModal"></ContactMeeting>
      </div>
      <div class="prismic-form-bottom" *ngIf="article !== null && article!.data.select === FORM_TYPE.SIMULATOR">
        <SearchSimulation type="simulation" icon="pinned" text="Simuler mon installation solaire" placeholder="Saisissez votre adresse"></SearchSimulation>
      </div>
    </div>
    <div class="blog-right-container">
      <FormArticle btnText="Demander un devis" title="Étudier mon projet"></FormArticle>
    </div>
  </div>

  <div class="section-right">
    <TitleAtom type="p" text="A découvrir également" color="dark" size="medium" weight="semi-bold"></TitleAtom>

    <ul class="blog-discover-list">
      <ng-container *ngFor="let article of articlesDiscovery.slice(0, 4)">
        <li>
          <a routerLink="/blog/{{ article.data.slug }}">
            <CardArticle imageExternal="{{ article.data.image.url }}" date="{{ article.data.published_at || '' | date: 'dd MMMM YYYY': '': 'fr-FR' }}" title="{{ article.data.title }}" text="{{ article.data.summary }}"></CardArticle>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>

