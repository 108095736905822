<div class="job-offer-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section" *ngIf="job !== null && job !== undefined">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Nous rejoindre', link: '/recrutement'}, {title: job.data.title}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom align="center" type="h1" text="{{ job.data.title }}" color="dark" size="heading" weight="semi-bold"></TitleAtom>
      <div>
        <div>
          <Image type="icon" width="15px" image="cdi"></Image>
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="{{ job.data.contract_type }}"></ParagraphAtom>
        </div>
        <div>
          <Image type="icon" width="15.56px" image="geoloc"></Image>
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="{{ job.data.address }}"></ParagraphAtom>
        </div>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="job-offer-content" *ngIf="job !== null && job !== undefined">
    <div class="offer-content-left">
      <CmsHtmlRendererAtom [data]="job.data.body"></CmsHtmlRendererAtom>
    </div>
    <div class="offer-card-apply">
      <CardHiring [showShareLinks]="true" [style]="'shadow'" [job]="job" image="{{ job.data.image.url }}" secondTitle="Le poste" slug="{{ job.data.slug }}" title="{{ job.data.title }}" btnText="Je postule" contract="{{ job.data.contract_type }}" place="{{ job.data.address }}" experience="{{ job.data.experiences }}" date="{{ job.data.started_at }}" fact="{{ job.data.fun_fact }}" [moreInfo]="job.data.more_details"></CardHiring>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
