<div class="press-list-container">
  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Monabee dans la presse', link: '/presse'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom type="h1" align="center" text="Monabee dans la presse" color="dark" size="medium" weight="semi-bold"></TitleAtom>
      <TitleAtom type="p" align="center" text="Nos dernières actualités" color="dark" size="heading" weight="semi-bold"></TitleAtom>
    </div>
  </div>

  <!-- PRESS -->
  <div id="pressTv" class="press-list-articles-container wrapper" *ngIf="page">
    <TitleAtom [mobileSize]="22" type="h2" align="left" color="dark" weight="semi-bold" size="x-large" text="On passe à la TV !"></TitleAtom>
    <ul>
      <li *ngFor="let item of page.data.tv_list">
        <VideoYoutubeAtom video="{{ item.tv_video }}" width="576px" widthMobile="100%" height="327px" heightMobile="195px"></VideoYoutubeAtom>
        <ParagraphAtom [mobileSize]="14" weight="semi-bold" size="x-large" text="{{ item.tv_title }}"></ParagraphAtom>
      </li>
    </ul>
    <ButtonAtom size="default" [style]="'stroked'" color="light" text="{{this.showAll === false ? 'Voir plus' : 'Voir moins'}}" (click)="toggleAll(1)"></ButtonAtom>
  </div>

  <!-- TALK ABOUT US -->
  <div id="pressAbout" class="press-about-container wrapper" *ngIf="page">
    <TitleAtom [mobileSize]="22" type="h2" align="left" color="dark" weight="semi-bold" size="x-large" text="On parle de nous"></TitleAtom>
    <ul>
      <ng-container *ngFor="let item of page.data.about_us_list; let i = index">
        <li *ngIf="showAboutAll === false && i < 8 || showAboutAll === true">
          <a href="{{ item.about_us_link }}" target="_blank" (click)="onClick()">
            <CardArticle imageExternal="{{ item.about_us_image.url }}" date="{{ item.about_us_published_at }}" title="{{ item.about_us_title }}" text="{{ item.about_us_body }}"></CardArticle>
          </a>
        </li>
      </ng-container>
    </ul>
    <ButtonAtom size="default" [style]="'stroked'" color="light" text="{{this.showAboutAll === false ? 'Voir plus' : 'Voir moins'}}" (click)="toggleAll(2)"></ButtonAtom>
  </div>

  <div class="separator"></div>

  <!-- PRESS RELEASES -->
  <div id="pressReleases" class="press-releases-container wrapper" *ngIf="page">
    <TitleAtom [mobileSize]="22" type="h2" align="left" color="dark" weight="semi-bold" size="x-large" text="Communiqués de presse"></TitleAtom>
    <ul>
      <ng-container *ngFor="let item of page.data.pr_list; let i = index">
        <li *ngIf="showReleasesAll === false && i < 6 || showReleasesAll === true">
          <a href="{{ item.pr_pdf.url }}" target="_blank" (click)="onClick()">
            <CardArticle [style]="'card'" imageExternal="{{ item.pr_image.url }}" date="{{ item.pr_published_at }}" title="{{ item.pr_title }}" ></CardArticle>
          </a>
        </li>
      </ng-container>
    </ul>
    <ButtonAtom size="default" [style]="'stroked'" color="light" text="{{this.showReleasesAll === false ? 'Voir plus' : 'Voir moins'}}" (click)="toggleAll(3)"></ButtonAtom>
  </div>

  <div class="separator"></div>

  <!-- BRAND CONTENT -->
  <div id="pressBrand" class="press-brand-container wrapper" *ngIf="page">
    <TitleAtom [mobileSize]="22" type="h2" align="left" color="dark" weight="semi-bold" size="x-large" text="Nos contenus marque"></TitleAtom>
    <ul>
      <li *ngFor="let item of page.data.brand_list" (click)="onClick()">
        <CardArticle btnText="Télécharger" [style]="'card'" imageExternal="{{ item.brand_image.url }}" date="{{ item.brand_published_at }}" title="{{ item.brand_title }}" link="{{ item.brand_document.url }}" ></CardArticle>
      </li>
    </ul>
  </div>

  <!-- PRESS CONTACT -->
  <div class="press-contact-container" *ngIf="page">
    <div id="pressContact" class="press-contact-card">

      <Image *ngIf="currentWidth > 1000" type="icon" class="press-top-left" image="press-top-left"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="press-top-right" image="press-top-right"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="press-bottom-left" image="press-bottom-left"></Image>
      <Image *ngIf="currentWidth > 1000" type="icon" class="press-bottom-right" image="press-bottom-right"></Image>

      <Image *ngIf="currentWidth < 1000" type="icon" class="press-top-left" image="press-top-left-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="press-top-right" image="press-top-right-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="press-bottom-left" image="press-bottom-left-mobile"></Image>
      <Image *ngIf="currentWidth < 1000" type="icon" class="press-bottom-right" image="press-bottom-right-mobile"></Image>

      <TitleAtom class="press-contact-card-text" [mobileSize]="16" align="center" color="orange" size="tiny" weight="semi-bold" text="Contact Presse"></TitleAtom>
      <TitleAtom class="press-contact-card-text" [mobileSize]="24" align="center" color="light" size="x-large" weight="semi-bold" text="{{ page.data.contact_name }}"></TitleAtom>
      <TitleAtom class="press-contact-card-text" [mobileSize]="24" align="center" color="yellow" size="x-large" weight="semi-bold" text="{{ page.data.contact_phone }}"></TitleAtom>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
