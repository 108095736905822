import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CmsAuthorArticlesHeadlineType, CmsDataType} from 'src/app/types/cms.types';

@Component({
  selector: 'CardAuthor',
  templateUrl: './card-author.component.html',
  styleUrls: ['./card-author.component.scss']
})

export class CardAuthorComponent implements OnInit {

  // The image name
  @Input() image: string = '';

  // The title of the card
  @Input() title: string = '';

  // The paragraph of the text
  @Input() body: Array<CmsDataType> = [];

  // The style of the card
  @Input() style: string = '';

  // The most famous articles
  @Input() articles: Array<CmsAuthorArticlesHeadlineType> = [];

  // The size of the card
  @Input() size: string = '';

  // The href of the card
  @Input() href: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  public get classes(): Array<string> {
    return [`${this.style} ${this.size}`];
  }

}
