import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";
import { CmsNewsCommitmentType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-commitments',
  templateUrl: './commitments.component.html',
  styleUrls: ['./commitments.component.scss']
})
export class CommitmentsComponent {

  /* The categories from the CMS */
  public news: Array<CmsNewsCommitmentType> = [];

  /* Step count for slider */
  public stepCount: number = 0;

  /* Width of the user window */
  public currentWidth = 2000;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cms: CmsService,
    private ngZone: NgZone,
    private titleService: Title,
    private metaService: Meta,
  ) {
  }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.currentWidth = window.innerWidth;
    }

    // Set title and meta description
    this.titleService.setTitle("Entreprise conseil et mise en place d'installation photovoltaique - Monabee");
    this.metaService.updateTag({ name: 'description', content: "Monabee, entreprise française, 100 % engagée pour l'autoconsommation produite par les panneaux photovoltaiques. Découvrez l'entreprise."});

    // Get headline
    this.cms.retrieve("[[at(document.type, \"news_commitments\")]]", "[my.news_commitments.position]", 1, 10).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.news = content.results;
      });
    });
  }

  /**
   * Function to up the step in slider
   */
  public nextStep(): void {
    if (this.stepCount < this.news.length - 1) {
      this.stepCount++;
    } else {
      this.stepCount = 0;
    }
  }

  /**
   * Function to return to the previous step in slider
   */
  public previousStep(): void {
    if (this.stepCount > 0) {
      this.stepCount--;
    } else {
      this.stepCount = this.news.length - 1;
    }
  }
}
