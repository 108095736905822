import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'Toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Output() toggleClick = new EventEmitter<void>();


  constructor() { }

  ngOnInit(): void {
  }

  /**
  * Function to get the click on toggle
  */
  public onToggleClick(): void {
    this.toggleClick.emit();
  }

}
