import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsService } from "../../services/cms.service";
import { CmsJobType, CmsResponseType } from "../../types/cms.types";
import { ApiService } from "../../services/api.service";
import { AnalyticsService } from "../../services/analytics.service";
import {CloudinaryService} from "../../services/cloudinary.service";

interface formData {
  job?: string,
  name: string,
  firstName: string,
  email: string,
  userCV: string,
  userVideo: string,
  questions: Array<any>,
}

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit {

  public arrayJobs: any;

  @ViewChild('cvFileInput') cvFileInput!: ElementRef;
  @ViewChild('videoFileInput') videoFileInput!: ElementRef;

  /** Check if input is typing */
  public isEmailInputDirty: boolean = false;

  /* User datas */
  public formDatas: formData = {
    job: "",
    name: "",
    firstName: "",
    email: "",
    userCV: "",
    userVideo: "",
    questions: [],
};

  /* The current category */
  public jobs: Array<CmsJobType> | null = [];

  /* The current category */
  public job: CmsJobType | null = null;

  // Steps count
  public stepCount: number = 1;

  // Character count
  public remaining: number = 500;
  public remainingMotivation: number = 500;

  // Values for user to select
  public values: {id: number, name: string, selected: boolean}[] = [];
  public selectedValues: string[] = [];

  // Qualities for user to select
  public qualities: {id: number, name: string, selected: boolean}[] = [];
  public selectedQualities: string[] = [];

  /** Check if email is valid */
  public emailValid: boolean = false;

  constructor(
    public  router: Router,
    private ngZone: NgZone,
    public analytics: AnalyticsService,
    private cms: CmsService,
    private api: ApiService,
    private route: ActivatedRoute,
    private cloudinary: CloudinaryService
  ) { }

  public selectedJob: string = "";
  public showInput: boolean = false;


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.showInput = params['libre'] === 'true';
      if (this.showInput) {
        this.selectedJob = '';
      } else {
        this.selectedJob = params['slug'] || '';
      }
    });

    // Initialize the values
    let valueLabels = ["Innovation", "Bienveillance", "Intégrité", "Collectif", "Exigence", "Innovation", "Intégrité", "Engagement", "Agilité", "Agilité", "Ouverture", "Collectif", "Responsabilité", "Authenticité", "Responsabilité"];
    for (let i = 0; i < valueLabels.length; i++) {
      this.values.push({id: i, name: valueLabels[i], selected: false});
    }

    let qualityLabels = ["Réponse A", "Réponse B", "Réponse C"];
    for (let i = 0; i < qualityLabels.length; i++) {
      this.qualities.push({id: i, name: qualityLabels[i], selected: false});
    }

    // Get jobs
    this.cms.retrieve("[[at(document.type, \"job\")]]", "[my.job.title]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.jobs = content.results;
        this.arrayJobs = this.getListOfJobs();
      });
    });

    this.selectedJob = this.route.snapshot.queryParams['slug'] || "candidat-libre";
    this.getJobQuestions();
  }

  /* NAVIGATION FUNCTIONS */
  public nextStep(): void {
    this.stepCount++;

    if (this.stepCount === 2) {
      this.getJobQuestions();
    }

    // Last step
    if (this.job && this.stepCount >= 4 + this.job.data.questions.length) {
      this.analytics.trackForm(4975);
      this.api.post(`contact/apply`, this.formDatas).subscribe(() => {
        this.formDatas = {
          name: "",
          firstName: "",
          email: "",
          userCV: "",
          userVideo: "",
          questions: [],
        }
      });
    }
  }

  public previousStep(): void {
    this.stepCount--;
  }

  // Function to toggle selection
  public onSingleChoice(step: number, value: string) {
    if (this.formDatas.questions[step] == undefined) this.formDatas.questions[step] = '';

    const index = this.formDatas.questions[step].indexOf(value);

    if (index > -1) {
      // if value is selectionned, remove it
      this.formDatas.questions[step] = '';
    } else {
      // if value is not selectionned and limit is not reached, add it
      this.formDatas.questions[step] = value;
    }
  }

  // Function to toggle selection
  public onMultipleChoice(step: number, value: string) {
    if (this.formDatas.questions[step] == undefined) this.formDatas.questions[step] = [];

    const index = this.formDatas.questions[step].indexOf(value);

    if (index > -1) {
      // if value is selectionned, remove it
      this.formDatas.questions[step].splice(index, 1);
    } else {
      // if value is not selectionned and limit is not reached, add it
      this.formDatas.questions[step].push(value);
    }
  }

  // Function to toggle selection
  public toggleSelection(value: string) {
    const index = this.selectedValues.indexOf(value);
    if (index > -1) {
      // if value is selectionned, remove it
      this.selectedValues.splice(index, 1);
    } else {
      // if value is not selectionned and limit is not reached, add it
      if (this.selectedValues.length < 3) {
        this.selectedValues.push(value);
      }
    }
  }

  // Function to toggle selection
  toggleSelectionQuality(value: string) {
    const index = this.selectedQualities.indexOf(value);
    if (index > -1) {
      // if value is selectionned, remove it
      this.selectedQualities.splice(index, 1);
    } else {
      // if value is not selectionned and limit is not reached, add it
      if (this.selectedQualities.length < 1) {
        this.selectedQualities.push(value);
      }
    }
  }

  public openFileSelector(id: number): void {
    if (id === 1) {
      this.cvFileInput.nativeElement.click();
    }
    else if (id === 2) {
      this.videoFileInput.nativeElement.click();
    }
  }

  public async onFileSelected(id: number, event: any): Promise<void> {
    if (id === 1) {
      console.log(event.target.files[0]);
      if (event.target.files[0].size <= 2000000)
        this.formDatas.userCV = await this.cloudinary.upload(event.target.files[0]);
      else
        this.formDatas.userCV = "";
    }
    else if (id === 2) {
      if (event.target.files[0].size <= 2000000)
        this.formDatas.userVideo = await this.cloudinary.upload(event.target.files[0]);
      else
        this.formDatas.userVideo = "";
    }
  }

  public onSelectChange(value: any): void {
    this.selectedJob = value;
    this.getJobQuestions();
  }

  public getListOfJobs(): Array<{value: string, label: string}> {
    const jobs = [];

    if (this.jobs == undefined) return [];

    for (const job of this.jobs) {
      jobs.push({
        value: job.data?.slug,
        label: job.data?.title
      })
    }

    return jobs;
  }

  /*
  * Check if email is valid
  */
  public checkEmail(): boolean {
    return this.emailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.formDatas.email);
  }

  /**
   * Get the question of a job
   */
  public getJobQuestions() {
    this.cms.retrieve(`[[at(document.type, \"job\")][at(my.job.slug, \"${this.selectedJob}\")]]`, "[my.blog.position]", 1, 1).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.job = content.results[0];
        this.formDatas.job = this.job?.data.title;
      });
    });
  }

  /** Check if input change */
  public onInputChange(number: number): void {
    if (number === 1) {
      this.isEmailInputDirty = true;
      this.checkEmail();
    }
  }

}
