<div class="hiring-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Nous rejoindre'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom [mobileSize]="16" type="h1" align="center" text="Nous rejoindre" color="dark" size="medium" weight="bold"></TitleAtom>
      <TitleAtom type="h2" align="center" text="Bienvenue dans la ruche Monabee" color="dark" size="heading" weight="semi-bold"></TitleAtom>
      <ParagraphAtom  align="center" text="Notre logo, vous l’aurez remarqué, c’est une abeille : elle symbolise l’intelligence collective. « La ruche », c’est le nom que nous avons donné à nos locaux. Notre quotidien : de l’énergie positive et une ambiance animée entre nous, avec nos clients et partenaires." size="intermediate" weight="regular"></ParagraphAtom>
      <ButtonAtom [style]="'basic'" (click)="scrollTo('offres')" color="yellow" text="Découvrir nos offres d’emploi"></ButtonAtom>
    </div>
  </div>
  
  <!-- OFFERS -->
  <div id="offres" class="hiring-offers-container">

    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-left" image="apply-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-right" image="apply-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-left" image="apply-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-right" image="apply-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right-mobile"></Image>

    <TitleAtom [mobileSize]="22" type="h2" align="center" color="light" weight="semi-bold" size="x-large" text="Nos offres d’emploi"></TitleAtom>
    <ParagraphAtom class="offers-paragraph" color="light" align="center" weight="regular" size="intermediate" text="Ce qui compte chez nous, c’est la personnalité avant tout !Prouvez-nous que vous êtes motivé(e), engagé(e) et débrouillard(e)."></ParagraphAtom>
    <ul>
      <ng-container *ngFor="let job of jobs">
        <li *ngIf="job.data.slug !== 'candidat-libre'">
          <a [routerLink]="['/recrutement', job.data.slug]">
            <CardHiring [style]="'basic'" fontWeight="bold" image="{{ job.data.image.url }}" title="{{ job.data.title }}" contract="{{ job.data.contract_type }}" place="{{ job.data.address }}" fact="{{ job.data.fun_fact }}"></CardHiring>
          </a>
        </li>
      </ng-container>
    </ul>
    <CardInline size="big" image="articles" [style]="'minimal'" title="Candidature spontanée" text="En quoi la citation «Soyez vous-même, les autres sont déjà pris» d’Oscar Wilde est une belle leçon de vie sur le courage et l’acceptation de soi ? Vous avez 4 heures !😉" btnText="Postuler librement" href="/recrutement/offres/postuler" [queryParams]="{libre: 'true'}"></CardInline>
  </div>


  <!-- PRESENTATION -->
  <div class="hiring-presentation-container">

    <div class="presentation-mission">
      <Image borderRadius="radius" type="image" width="600px" mobileWidth="100%" imageCloudinary="recrutement/image_1_mission_vcbirt.jpg"></Image>
      <div class="presentation-mission-content">
        <TitleAtom weight="semi-bold" size="small" color="orange" text="Notre mission"></TitleAtom>
        <TitleAtom [mobileSize]="22" weight="semi-bold" size="x-large" color="dark" text="Accompagner nos clients vers l’autonomie énergétique"></TitleAtom>
        <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Selon l’ADEME*, 2/3 de la consommation électrique des foyers français pourraient être couverte par la production photovoltaïque sur les toitures. Aujourd’hui, nous avons l’audace de dire que Monabee permet au plus grand nombre de devenir acteur de sa consommation et production énergétique."></ParagraphAtom>
        <ParagraphAtom color="dark" weight="regular" size="intermediate" text="*Agence De la Transition Ecologique"></ParagraphAtom>
      </div>
    </div>

    <div class="presentation-about">
      <div class="presentation-about-content">
        <TitleAtom weight="semi-bold" size="small" color="orange" text="A propos"></TitleAtom>
        <TitleAtom [mobileSize]="22" weight="semi-bold" size="x-large" color="dark" text="Monabee en chiffres"></TitleAtom>
        <ParagraphAtom color="dark" weight="regular" size="intermediate" text="En 1 an, nous nous retrouvons projetés à travers une croissance très forte. Et pour répondre à nos objectifs ambitieux, nous continuons de recruter de nombreux talents… Chez Monabee, vous avez la liberté d’entreprendre, l’amour du travail bien fait et une bonne dose d’esprit d’équipe. C’est un concentré d’énergie positive, à consommer sans modération !"></ParagraphAtom>
        <ul>
          <li>
            <TitleAtom [mobileSize]="32" color="dark" weight="semi-bold" size="x-large" text="+159%"></TitleAtom>
            <ParagraphAtom color="dark" weight="medium" size="intermediate" text="de recrutements en 1 an"></ParagraphAtom>
          </li>
          <li>
            <TitleAtom [mobileSize]="32" color="dark" weight="semi-bold" size="x-large" text="60"></TitleAtom>
            <ParagraphAtom color="dark" weight="medium" size="intermediate" text="collaborateurs survoltés"></ParagraphAtom>
          </li>
          <li>
            <TitleAtom [mobileSize]="32" color="dark" weight="semi-bold" size="x-large" text="2843%"></TitleAtom>
            <ParagraphAtom color="dark" weight="medium" size="intermediate" text="de croissance en 4 ans"></ParagraphAtom>
          </li>
        </ul>
      </div>
      <Image borderRadius="radius" type="image" width="600px" mobileWidth="100%" imageCloudinary="recrutement/image_2_apropos_yj9wej.jpg"></Image>
    </div>
  </div>


  <!-- TEAM -->
  <div class="hiring-team">
    <TitleAtom [mobileSize]="22" align="center" color="dark" size="heading" weight="semi-bold" text="Ils incarnent Monabee au quotidien"></TitleAtom>
    <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="Les collaborateurs sont comme les abeilles… Ils sont solidaires et au service de la reine des abeilles : le/la client(e)."></ParagraphAtom>

    <Slider [items]="items" ></Slider>

    <ul>
      <li>
        <VideoYoutubeAtom video="https://www.youtube.com/embed/FR5XUK_3dnQ" width="600px" widthMobile="100%" height="337px" heightMobile="215"></VideoYoutubeAtom>
      </li>
      <li>
        <VideoYoutubeAtom video="https://www.youtube.com/embed/F0fzHPz9m9M" width="600px" widthMobile="100%" height="337px" heightMobile="215"></VideoYoutubeAtom>
      </li>
      <li>
        <VideoYoutubeAtom video="https://www.youtube.com/embed/suo1olgNsKY" width="600px" widthMobile="100%" height="337px" heightMobile="215"></VideoYoutubeAtom>
      </li>
      <li>
        <VideoYoutubeAtom video="https://www.youtube.com/embed/K7JsLHz9m_k" width="600px" widthMobile="100%" height="337px" heightMobile="215"></VideoYoutubeAtom>
      </li>
    </ul>
  </div>

  <!-- LOCALISTATION -->
  <div class="hiring-localisation">
    <Image width="496px" mobileWidth="100%" type="image" imageCloudinary="recrutement/image_6_presence_kkltaw.png"></Image>
    <div>
      <TitleAtom color="orange" size="small" weight="semi-bold" text="Au plus proche de vous"></TitleAtom>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Notre présence géographique"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Monabee est présent sur plus de 35 départements. Nous venons à votre rencontre autour de La Rochelle, Bordeaux, Toulouse, Marseille et Lyon."></ParagraphAtom>

      <div>
        <TitleAtom [mobileSize]="18" color="dark" size="small" weight="semi-bold" text="🏡 Vous souhaitez nous rendre visite ?"></TitleAtom>
        <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Notre siège social se situe <b>4 chemin des Hirondelles à Dardilly</b>, près de Lyon. Si vous passez par là, venez boire un ☕️!"></ParagraphAtom>
      </div>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

</div>
