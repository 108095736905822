<div class="guide-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Nos guides', link: '/guides'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom type="h1" align="center" text="Nos guides sur le photovoltaïque et énergie solaire" color="dark" size="heading" weight="semi-bold"></TitleAtom>
      <ParagraphAtom align="center" text="Découvrez nos ressources gratuites sur les panneaux photovoltaïques. Dans ces guides, retrouvez les calculs des économies réalisées grâce à l'autoconsommation de votre électricité verte." size="intermediate" weight="regular"></ParagraphAtom>
    </div>
  </div>

  <!-- OFFERS -->
  <div class="guide-list-container">
    <TitleAtom type="h2" align="center" color="dark" weight="semi-bold" size="x-large" text="Nos guides à télécharger"></TitleAtom>
    <ul>
      <li *ngFor="let guide of guides">
        <a routerLink="/guides/{{ guide.data.slug }}">
          <Content type="guide" size="small" imageExternal="{{ guide.data.image.url }}" title="{{ guide.data.title }}" text="{{ guide.data.summary }}"></Content>
        </a>
      </li>
    </ul>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

</div>
