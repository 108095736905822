import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'Slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  /* Items */
  @Input() items: Array<{image?: string, imageCloudinary?: string, title: string, text: string}> = [];

  /* Image of the list-link */
  public step: number = 0;

  /** If it's in mobile view */
  public isMobile: boolean = false;


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }
  }

  /**
   * Function to up the step in slider
   */
  public nextStep(): void {
    if (this.step < this.items.length - 1) {
      this.step++;
    } else {
      this.step = 0;
    }
  }

  /**
   * Function to return to the previous step in slider
   */
  public previousStep(): void {
    if (this.step > 0) {
      this.step--;
    } else {
      this.step = this.items.length - 1;
    }
  }

  /**
   * Get the bullets total
   */
  public getBullets(): Array<number> {
    return Array(this.items.length).fill(1);
  }

}
