import { Component, OnInit, Input } from '@angular/core';
import { CmsDataType } from "../../types/cms.types";

@Component({
  selector: 'Faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  /** The text of the question */
  @Input() title: string = '';

  /** The text of the answer */
  @Input() text: Array<CmsDataType> = [];

  /** If answer is hidden */
  @Input() isHidden: boolean = true;

  constructor() { }

  ngOnInit(): void {

  }

  /**
  * Function to hide or display the answer
   */
  public onHide() {
    this.isHidden = !this.isHidden;
  }

}
