<div class="error-container">
  <!-- NAVBAR -->
  <div class="navbar-container">
    <Navbar></Navbar>
    <Breadcrumb [links]="[{title: '404', link: '/404'}]"></Breadcrumb>
  </div>

  <div class="error-content">
    <TitleAtom align="center" type="h1" color="blue" text="404" weight="bold" size="x-bigger"></TitleAtom>
    <ParagraphAtom align="center" color="dark" weight="semi-bold" size="medium" text="Cette page n’existe pas ou n’existe plus"></ParagraphAtom>
    <ButtonAtom [style]="'basic'" color="yellow" size="default" text="Retourner vers la page d’accueil" href="/"></ButtonAtom>
  </div>

  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
