import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { IDatePickerConfig } from "ng2-date-picker";
import { isPlatformBrowser } from "@angular/common";
import { ApiService } from "../../services/api.service";
import * as dayjs from "dayjs";
import {AnalyticsService} from "../../services/analytics.service";
import {StorageService} from "../../services/storage.service";

@Component({
    selector: 'ContactMeeting',
    templateUrl: './contact-meeting.component.html',
    styleUrls: ['./contact-meeting.component.scss']
})
export class ContactMeetingComponent implements OnInit {

    public stepCount: number = 1;

    /** If the modal is hidden */
    @Input() isHidden: boolean = false;
    @Output() isHiddenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Check if input is typing */
    public isEmailInputDirty: boolean = false;
    public isPhoneInputDirty: boolean = false;

    /* HOURS SELECTION */
    public availableTimes: string[] = [];
    public timesIndex: number = 0;

    /** If the form as been validated **/
    public isValidated: boolean = false;

    /** The postal code not accepted by Monabee **/
    private postalCodeAvailable: string[] = [];

    /** If pop up calendar is open or close */
    public isCalendar: boolean = true;

    /** If pop up information user is open or close */
    public isInformationUser: boolean = false;

    /** If use confirm his information */
    public isConfirm: boolean = false;

    /** Is mobile */
    public isMobile: boolean = false;

    /** Select time on mobile*/
    public getTimeMobile: boolean = false;

    /** If the timeslots is loading */
    public isLoadingTimeslots: boolean = false;

    /* CALENDAR CONFIGURATION */
    public selectedDate?: Date;

    public datePickerConfig: IDatePickerConfig = {
        firstDayOfWeek: 'mo',
        disableKeypress: false,
        allowMultiSelect: false,
        closeOnSelect: false,
        closeOnSelectDelay: 100,
        onOpenDelay: 0,
        weekDayFormat: 'dd',
        showNearMonthDays: false,
        enableMonthSelector: true,
        yearFormat: 'YYYY',
        format: 'DD-MM-YYYY',
        min: dayjs(),
        isDayDisabledCallback: (date: any) => {
            // Disabled week-ends selection (samedi et dimanche)
            const isWeekend = date.day() === 0 || date.day() === 6;

            // Disabled dates before the date of the current day
            const currentDate = dayjs();
            const isBeforeToday = date.isBefore(currentDate, 'day');

            return isWeekend || isBeforeToday;
        },
    };

    /** Form */
    public form = {
        postal_code: "",
        userType: "particulier",
        name: "",
        email: "",
        phone_number: "",
        company: "",
        date: "",
        selectedTime: "",
        utm_source: this.storage.getValue('utm_source'),
        utm_campaign: this.storage.getValue('utm_campaign'),
        gclid: this.storage.getValue('gclid'),
        fbclid: this.storage.getValue('fbclid'),
    };

    /* Width of the window user */
    public currentWidth = 2000;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private storage: StorageService,
        public analytics: AnalyticsService,
        public api: ApiService,
        public router: Router
    ) {
    }

    ngOnInit(): void {
        // Execute code only on browser
        if (isPlatformBrowser(this.platformId)) {
            this.isMobile = window.innerWidth < 1024;
            this.currentWidth = window.innerWidth;
        }

        this.getAvailablePostalCode();
        this.stepCount = 1;
    }

    /**
     * Function to close the modal
     */
    public onHideModal(): void {
        this.stepCount = 1;
        this.getTimeMobile = false;
        this.isHidden = true;
        this.isInformationUser = false;
        this.isCalendar = true;
        document.body.style.overflow = 'scroll';
        this.isHiddenChange.emit(true);
    }

    /**
     * Function to get the time available mobile
     */
    public getDisplayedTimesMobile(): string[] {
        return this.availableTimes;
    }

    /**
     * Function to show more available time
     */
    public showMoreTimes(): void {
        this.timesIndex++;
    }

    /**
     * To show top time selection
     */
    public showLessTimes(): void {
        this.timesIndex--;
    }

    /**
     * Function to change value on select change
     * @param number id of the selector
     * @param value
     */
    public onSelectChange(number: number, value: any): void {
        if (number === 1) {
            this.form.userType = value;
        }
        else if (number === 2) {
            this.form.postal_code = value;
        }
        else if (number === 3) {
            this.form.selectedTime = value;
            this.form.date = `${dayjs(this.selectedDate?.toString()).format('YYYY-MM-DD')} ${value} UTC`;
        }
    }

    /**
     * Function to get postal code selected in google recommendation
     * @param string address selected
     */
    public onPlaceSelected(postal_code: string): void {
        this.form.postal_code = postal_code || '';
    }

    /**
     * Function to know if user cp correspond to the Monabee intervention
     */
    public isPostalCodeNotAvailable(): boolean {
        return !this.postalCodeAvailable.includes(this.form.postal_code.substring(0, 2)) && this.form.postal_code !== '';
    }

    /**
     * Function to know if the postal code is valid
     */
    public isPostalCodeValid(): boolean {
        if (this.form.postal_code === '' || this.form.postal_code === null || this.form.postal_code.length < 5) {
            return false;
        }

        const regex = /^\d{2}[ ]?\d{3}$/;
        return regex.test(this.form.postal_code);
    }

    /**
     * Function to check if phone number is valid
     */
    public checkPhoneNumber(): boolean {
        return this.form.phone_number.length >= 10;
    }

    /**
     * Function to valid step
     */
    public next(number: number) {
        if (number === 1) {
            this.stepCount++
        }
        else if (number === 2) {
            this.isCalendar = false;
            this.isInformationUser = true;
            this.stepCount++
        }
    }

    /**
     * Function to valid step
     */
    public nextMobile(number: number) {
        if (number === 1) {
            //this.getTimeMobile = true;
            this.stepCount++;
        } else if (number === 2) {
            this.getTimeMobile = true;
            this.isCalendar = true;
            this.isInformationUser = false;
            this.stepCount++;
        } else if (number === 3) {
            this.isInformationUser = true;
            this.stepCount++;
        }
    }

    /**
     * Function to valid step
     */
    public previousMobile() {
        if (this.stepCount === 2) {
            this.getTimeMobile = false;
            this.isCalendar = true;
            this.isInformationUser = false;
        }
        else if (this.stepCount === 3) {
            this.getTimeMobile = false;
            this.isCalendar = true;
            this.isInformationUser = false;
        } else if (this.stepCount === 4) {
            this.isInformationUser = false;
            this.getTimeMobile = true;
            this.isCalendar = true;

        }
        this.stepCount--;
    }


    /**
     * Function to go back
     */
    public previous() {
        this.isCalendar = true;
        this.isInformationUser = false;
        this.stepCount--;
    }

    /**
     * To display validate meeting step
     */
    public onSubmit(): void {
        this.analytics.trackForm(this.form.userType === 'particulier' ? 12737 : 11193);

        this.api.post(`contact/meeting`, this.form).subscribe(() => {
            this.isCalendar = false;
            this.isInformationUser = false;
            this.isConfirm = true;
        });
    }

    /**
     * Function to know if the postal code is valid
     */
    public onClickValidate(): void {
        this.isValidated = true;
    }

    /**
     * Function to get the handled postal code
     */
    public getAvailablePostalCode(): void {
        this.api.get(`availability/postalcode`).subscribe((response) => {
            this.postalCodeAvailable = response.data;
        });
    }

    /**
     * Function to get the available time slots
     */
    public getAvailableTimeslots(event: any): void {
        if (event === undefined || this.isPostalCodeNotAvailable() || this.form.postal_code === '') return;

        this.form.date = dayjs(event.toString()).format('YYYY-MM-DD');
        this.availableTimes = [];

        this.isLoadingTimeslots = true;
        this.api.post(`availability/timeslots`, {
            postal_code: this.form.postal_code,
            date: this.form.date
        }).subscribe((response) => {
            this.isLoadingTimeslots = false;
            this.availableTimes = response.data;
        });
    }

    /**
     * Function to check if the email is valid
     */
    public checkEmail(): boolean {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.form.email);
    }

    /** Check if input change */
    public onInputChange(number: number): void {
        if (number === 1) {
            this.isEmailInputDirty = true;
            this.checkEmail();
        }
        else if (number === 2) {
            this.isPhoneInputDirty = true;
            this.checkPhoneNumber();
        }
    }
}
