import {Component, OnInit, NgZone, Inject, PLATFORM_ID} from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsArticleType, CmsResponseType } from "../../types/cms.types";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";
import {FORM_TYPE} from "../../constants/constants";

@Component({
  selector: 'app-testimonials-detail',
  templateUrl: './testimonials-detail.component.html',
  styleUrls: ['./testimonials-detail.component.scss']
})
export class TestimonialsDetailPageComponent implements OnInit {


  /* The article from the CMS */
  public article: CmsArticleType | null = null;

  /* The article from the CMS */
  public articlesDiscovery: Array<CmsArticleType> = [];

  /** Show the contact meeting modal **/
  public hideMeetingModal: boolean = true;

  /** The width and height of the window user */
  public isMobile: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public cms: CmsService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.refresh();

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.refresh();
      }
    });

    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }

  }

  /** Refresh the content */
  public refresh() {
    const articleSlug = this.route.snapshot.paramMap.get('article');

    // Get Discovery articles
    this.cms.retrieve("[[at(document.type, \"testimonials\")][any(document.tags, [\"A découvrir également\"])]]", "[my.blog.published_at desc]", 1, 5).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articlesDiscovery = content.results;
      });
    });

    // Get an article
    this.cms.retrieve(`[[at(document.type, \"testimonials\")][at(my.testimonials.slug, \"${articleSlug}\")]]`, "[my.testimonials.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.article = content.results[0];

        // Set title and meta description
        this.titleService.setTitle(this.article?.data.metadata_title || "");
        this.metaService.updateTag({ name: 'description', content: this.article?.data.metadata_description || ""});
      });
    });
  }

  /** Handle the meeting modal */
  public handleMeetingModal() {
    this.hideMeetingModal = false;
    document.body.style.overflow = 'hidden';
  }

  protected readonly FORM_TYPE = FORM_TYPE;
}
