import { Component, Inject, OnInit, HostListener, PLATFORM_ID } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../../services/api.service";
import { AnalyticsService } from "../../services/analytics.service";
import {StorageService} from "../../services/storage.service";


/* Interface for Geocode answer */
interface GeocodeResponse {
    status: string;
    results: {
        geometry: {
            location: {
                lat: number;
                lng: number;
            };
        };
    }[];
}

declare let google: any;

@Component({
    selector: 'app-simulator',
    templateUrl: './simulator.component.html',
    styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {

    /* To check if navbar is sticky */
    public sticky: boolean = false;

    /* The postal code not accepted by Monabee */
    private postalCodeAvailable: string[] = [];
    public isPostalCodeAvailable?: boolean | null;

    /* Selected value */
    public selectedCardinalPoint: number = 0;

    /* Consumption timing */
    public consumptionTiming: string = 'Mensuel';

    /* User datas */
    public formDatas = {
        userType: "particulier",
        name: "",
        email: "",
        address: "",
        postal_code: "",
        recallTiming: "",
        acceptRecall: false,
        latitude: 0,
        longitude: 0,
        roofArea: 0,
        phone_number: "",
        userConsumption: "",
        peopleInHome: 0,
        company: "",
        message: "",
        utm_source: this.storage.getValue('utm_source'),
        utm_campaign: this.storage.getValue('utm_campaign'),
        gclid: this.storage.getValue('gclid'),
        fbclid: this.storage.getValue('fbclid'),
    };

    public result = {
        estimateConsumption: 0,
        estimatePrice: 0,
        solarPanel: 0,
        solarPanelProduction: 0,
        savings: 0
    }

    /* STEPS VARIABLE */

    // Steps count
    public stepCount: number = 1;

    // Check if user want a call
    public getCall: boolean = false;

    // Step validate for call section
    public callIsValidated: boolean = false;

    /* Google Maps setup  */
    public center: any = {lat: 0, lng: 0};
    public map: any = "satellite";
    public zoom: number = 22;
    public mapOptions: google.maps.MapOptions = {
        disableDefaultUI: true,
    };
    private mapInstance!: google.maps.Map;
    public markers: google.maps.Marker[] = [];
    public polyline!: google.maps.Polyline | undefined;

    /* Invoices */
    public showInvoiceExample: boolean = false;
    public showEdfInvoice: boolean = false;
    public showDirectInvoice: boolean = false;
    public showEkwateurInvoice: boolean = false;
    public showEnercoopInvoice: boolean = false;
    public showPlanetInvoice: boolean = false;
    public showUrbanInvoice: boolean = false;

    /* Equipments selections */
    public electricCar: boolean = false;
    public pool: boolean = false;
    public heather: boolean = false;
    public waterHeater: boolean = false;

    // The current width of the user window
    public currentWidth = 2000;

    /** Check if input is typing */
    public isEmailInputDirty: boolean = false;
    public isPhoneInputDirty: boolean = false;

    polygonClosed: boolean = false;


    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private storage: StorageService,
        private analytics: AnalyticsService,
        private http: HttpClient,
        private titleService: Title,
        private metaService: Meta,
        private router: Router,
        private api: ApiService,
        private route: ActivatedRoute
    ) {

    }

    ngOnInit(): void {

        this.getAvailablePostalCode();

        this.route.queryParams.subscribe(params => {
            this.formDatas.address = params['address'];
            this.formDatas.postal_code = params['postal_code'];
            if (this.formDatas.address !== undefined || '') {
                this.api.get(`availability/postalcode`).subscribe((response) => {
                    this.postalCodeAvailable = response.data;
                    this.getUserGeocode();
                    this.isPostalCodeNotAvailable();
                    this.stepCount = 2;
                });
            }
        });

        // Set title and meta description
        this.titleService.setTitle(' Simulateur panneau solaire, calculez la rentabilité de vos panneaux solaires');
        this.metaService.updateTag({ name: 'description', content: 'Calculez la rentabilité de vos panneaux photovoltaïques avec le simulateur de panneaux solaires de Monabee, installateur de panneaux depuis 2012.' });

        // Execute code only on browser
        if (isPlatformBrowser(this.platformId)) {
            this.center = {lat: 0, lng: 0};
            this.map = google.maps.MapTypeId.SATELLITE;
            this.currentWidth = window.innerWidth;
        }
    }

    /**
     * Get the navbar sticky on scroll
     */
    @HostListener('window:scroll', ['$event'])
    handleScroll(){
        const windowScroll = window.pageYOffset;

        if (windowScroll >= 40){
            this.sticky = true;
        } else {
            this.sticky = false;
        }
    }

    /*-- NAVIGATION FUNCTIONS --*/

    public nextStep(): void {
        this.stepCount++;
        this.analytics.trackEvent(`form_simulation_step${this.stepCount}`, '');

        if (this.stepCount === 9) this.getEstimation()
    }

    public previousStep(): void {
        this.stepCount--;
    }

    /**
     * Check if user want a call
     */
    public userGetCall(): void {
        this.getCall = !this.getCall;
        this.callIsValidated = false;
        this.formDatas.recallTiming = '';
    }

    /**
     * To display validate call step
     */
    public validateCall(): void {
        this.getCall = false;
        this.callIsValidated = true;
    }

    /**
     * Close call modal
     */
    public closeCallModal(): void {
        this.getCall = false;
        this.callIsValidated = false;
    }

    /**
     * Function to display the example invoice based on the user's choice
     * @param id of the invoices selected
     */
    public getInvoiceExample(id: number): void {

        if (id === 1) {
            this.showEdfInvoice = !this.showEdfInvoice;
            this.showInvoiceExample = !this.showInvoiceExample;
        }
        else if (id === 2) {
            this.showDirectInvoice = !this.showDirectInvoice;
            this.showInvoiceExample = !this.showInvoiceExample;
        }
        else if (id === 3) {
            this.showEkwateurInvoice = !this.showEkwateurInvoice;
            this.showInvoiceExample = !this.showInvoiceExample;
        }
        else if (id === 4) {
            this.showEnercoopInvoice = !this.showEnercoopInvoice;
            this.showInvoiceExample = !this.showInvoiceExample;
        }
        else if (id === 5) {
            this.showPlanetInvoice = !this.showPlanetInvoice;
            this.showInvoiceExample = !this.showInvoiceExample;
        }
        else if (id === 6) {
            this.showUrbanInvoice = !this.showUrbanInvoice;
            this.showInvoiceExample = !this.showInvoiceExample;
        }

    }

    /**
     * Close estimation form modal
     * */
    public closeEstimate(): void {
        this.stepCount = 7;
        this.formDatas.acceptRecall = false;
    }

    /*-- GOOGLE MAPS FUNCTIONS --*/

    /**
     * Reset markers / roofArea / polyline
     */
    public clearMarkers() {
        this.markers.forEach(marker => {
            marker.setMap(null);
        });
        this.markers = [];
        this.formDatas.roofArea = 0;
        this.polygonClosed = false;

        if (this.polyline) {
            this.polyline.setMap(null);
            this.polyline = undefined;
        }
    }

    /**
     * Reset markers and polyline but not the roofArea
     */
    public closeMarkers() {
        this.markers.forEach(marker => {
            marker.setMap(null);
        });
        this.markers = [];

        if (this.polyline) {
            this.polyline.setMap(null);
            this.polyline = undefined;
        }
    }

    /**
     * Function to update the map when it is ready
     * @param map
     */
    public onMapReady(map: google.maps.Map) {
        this.mapInstance = map;
    }

    /**
     * Function to create a stylized marker with a specified position
     * @param position for the marker
     */
    private createMarker(position: google.maps.LatLngLiteral) {
        const marker = new google.maps.Marker({
            position: position,
            map: this.mapInstance,
            draggable: true,
            icon: {
                fillColor: "#FFF",
                fillOpacity: 1,
                strokeWeight: 2,
                strokeColor: "#FF0000",
                scale: 5,
                path: google.maps.SymbolPath.CIRCLE,
            },
        });

        marker.addListener('drag', () => {
            this.updatePolyline(this.polygonClosed);
            this.updateArea(this.polygonClosed);
        });

        // Add listener for click event to close the polygon.
        marker.addListener('click', () => {
            // Check if the clicked marker is the first marker.
            if (this.markers.indexOf(marker) === 0 && this.markers.length > 3) {
                this.polygonClosed = true;
                this.updatePolyline(true);
                this.updateArea(true);
            }
        });

        return marker;
    }

    /**
     * Function to create a marker when the map is clicked
     * @param event map click
     */
    public onMapClick(event: google.maps.MapMouseEvent) {
        // Get the coord of the click on map and convert it to JSON
        const latLng = event.latLng!.toJSON();

        // Create marker on the click
        const marker = this.createMarker(latLng);
        this.markers.push(marker);

        // Add 'dragend' event listener for each marker
        marker.addListener('dragend', () => {
            this.updatePolyline(this.polygonClosed);
            this.updateArea(this.polygonClosed);
        });

        this.updatePolyline();

        // Begin calculating area when at least 3 markers have been placed
        if (this.markers.length >= 3) {
            this.updateArea();
        }
    }

    // Method to update polyline
    public updatePolyline(close = false): void {
        let path = this.markers.map(marker => marker.getPosition());

        // Close the path by adding the position of the first marker at the end.
        if (close && this.markers.length > 0) {
            path.push(this.markers[0].getPosition());
        }

        if (this.polyline) {
            this.polyline.setMap(null);
        }

        this.polyline = new google.maps.Polyline({
            path: path,
            map: this.mapInstance,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 5,
        });
    }

    // Method to update the area
    public updateArea(close = false) {
        // When close is true, add the position of the first marker at the end.
        let positions = this.markers.map(marker => marker.getPosition());

        if (close && this.markers.length > 0) {
            const firstPosition = this.markers[0].getPosition();
            const lastPosition = positions[positions.length - 1];

            if (firstPosition && lastPosition && !firstPosition.equals(lastPosition)) {
                positions.push(firstPosition);
            }
        }

        if (this.polygonClosed) {
            const area = this.calculateArea(
                positions.filter(position => position !== null && position !== undefined)
                    .map(position => position!.toJSON())
            );

            // Round the calculated area for a better displaying
            this.formDatas.roofArea = Math.round(area);
        } else {
            // Set the area to zero when the polygon is not closed
            this.formDatas.roofArea = 0;
        }
    }

    /**
     * Function to calculate the area of a polygon
     * @param coords
     */
    private calculateArea(coords: google.maps.LatLngLiteral[]): number {
        // Convert coords in LatLng google maps object
        const path = coords.map(coord => new google.maps.LatLng(coord.lat, coord.lng));

        // Calculate area with computeArea from Google Maps
        return google.maps.geometry.spherical.computeArea(path);
    }

    /**
     * Function to get GPS coordinates from an address using Google Maps API
     * @param address to convert in GPS coordinates
     * @param apiKey of google maps
     */
    private async getGeocodeData(address: string, apiKey: string): Promise<any> {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json';
        const params = {
            address: address,
            key: apiKey,
        };

        try {
            const response = await this.http.get<GeocodeResponse>(url, { params }).toPromise(); // Modification ici
            if (response && response.status === 'OK') {
                const location = response.results[0].geometry.location;
                return { latitude: location.lat, longitude: location.lng };
            } else {
                throw new Error(`Error geocoding address: ${response && response.status}`);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données géocodées:', error);
        }
    }

    /**
     * Save gps coordinate of user address
     **/
    public async getUserGeocode(): Promise<any> {
        const apiKey = environment.googleApiKey;
        const result = await this.getGeocodeData(this.formDatas.address, apiKey);

        // Save lng and lat in variable (delete?)
        this.formDatas.latitude = result.latitude;
        this.formDatas.longitude = result.longitude;

        // Set up the google maps data with lat and lng of the user address
        this.center.lat = result.latitude;
        this.center.lng = result.longitude;
    }

    /**
     * Function to get address selected in google recommendation and cp
     * @param string address selected
     */
    public onPlaceSelected(placeData: { formatted_address: string; postal_code: string }) {
        this.formDatas.address = placeData.formatted_address;
        this.formDatas.postal_code = placeData.postal_code;

        this.isPostalCodeNotAvailable();
    }

    /*-- USER SELECTION FUNCTIONS --*/

    /**
     * Function to get the handled postal code
     */
    public getAvailablePostalCode(): void {
        this.api.get(`availability/postalcode`).subscribe((response) => {
            this.postalCodeAvailable = response.data;
        });
    }

    /**
     * Function to know if user cp correspond to the Monabee intervention
     */
    public isPostalCodeNotAvailable(): boolean {
        this.isPostalCodeAvailable = !this.postalCodeAvailable.includes(this.formDatas.postal_code.substring(0, 2)) && this.formDatas.postal_code !== '';
        return this.isPostalCodeAvailable;
    }

    /*  /!**
       * Function to know if user cp correspond to the Monabee intervention
       *!/
      public isPostalCodeNotAvailable(): boolean {
        this.isPostalCodeAvailable = !this.postalCodeAvailable.includes(this.formDatas.postal_code) && this.formDatas.postal_code !== '';
        console.log('If postal code is different than 75116 :', this.isPostalCodeAvailable)
        return this.isPostalCodeAvailable;
      }*/

    /**
     * Check if address is empty
     */
    public checkAddressEmpty(): boolean {
        return this.formDatas.address === "" || this.formDatas.address === undefined || !this.canSubmitAddress();
    }

    public canSubmitAddress(): boolean {
        const digitCount = (this.formDatas.address.match(/\d/g) || []).length;
        return digitCount >= 5;
    }

    /**
     * Function to save the number of people in the user's home
     * @param number of people
     */
    public setPeopleInHome(number: number): void {
        this.formDatas.peopleInHome = number;
        this.stepCount++;
    }

    /**
     * Function to save the equipments owned by the user
     * @param id of the equipments
     * @param event click
     */
    public setSelectedEquipments(id: number): void {

        if (id === 1) {
            this.electricCar = !this.electricCar;
        } else if (id === 2) {
            this.pool = !this.pool;
        } else if (id === 3) {
            this.heather = !this.heather;
        } else if (id === 4) {
            this.waterHeater = !this.waterHeater;
        }
    }

    /**
     * Function to save if user accept to be contacted by expert
     * @param
     * @param event click
     */
    public userAcceptedContact(): void {
        this.formDatas.acceptRecall = !this.formDatas.acceptRecall;
    }

    /**
     * Function to change value on select change
     * @param number id of the selector
     * @param value
     */
    onSelectChange(number: number, value: any) {
        if (number === 1) {
            this.selectedCardinalPoint = value;
        }
        else if (number === 2) {
            this.consumptionTiming = value;
        }
        else if (number === 3) {
            this.formDatas.userType = value;
        }
        else if (number === 4) {
            this.formDatas.recallTiming = value;
        }
    }

    /**
     * Function to know if equipments is selected for enabled validate btn
     */
    public onEquipmentSelected(): boolean {
        return this.electricCar === false && this.pool === false && this.heather === false && this.waterHeater === false;
    }

    public closeSimulator(): void {
        this.router.navigate(['/']);
    }

    /**
     * To display validate meeting step
     */
    public getEstimation(): void {
        const basePrice = 0.2062;

        this.api.get(`availability/pvgis`, {
            latitude: this.center.lat,
            longitude: this.center.lng,
            aspect: this.selectedCardinalPoint
        }).subscribe((response) => {
            const power = response.data;
            if (this.consumptionTiming === 'Mensuel') {
                this.result.estimateConsumption = Math.round((Number(this.formDatas.userConsumption ) * 12) / basePrice);
                this.result.estimatePrice = Math.round(Number(this.formDatas.userConsumption) * 12);
            } else {
                this.result.estimateConsumption = Math.round(Number(this.formDatas.userConsumption) / basePrice);
                this.result.estimatePrice = Math.round(Number(this.formDatas.userConsumption));
            }
            // this.result.solarPanel = Math.round(Number(this.formDatas.roofArea) / 1.8);
            this.result.solarPanel = Math.round((6 * 1000) / 375);
            this.result.solarPanelProduction = Math.round(((Number(power) * 375) / 1000) * this.result.solarPanel);
            const diff = this.result.solarPanelProduction / this.result.estimateConsumption;
            this.result.savings = Math.round(this.result.estimatePrice * diff);

            this.formDatas.postal_code = this.formDatas.address.split(',')[1].trim().split(' ')[0];
            this.formDatas.message = `Simulation de panneaux solaires effectuée sur le site monabee.fr.\n
      Consommation annuelle estimée : ${this.result.estimateConsumption} kWh\n
      Surface estimée sur le toit : ${this.formDatas.roofArea} m2, ${this.result.solarPanel} panneaux préconisés soit ${this.result.solarPanelProduction} kWc.\n
      Taux d’effacement de la consommation estimée : ${diff * 100} %.\n
      Composition du foyer: ${this.formDatas.peopleInHome}.\n
      A reçu par mail ces premiers éléments et on lui a indiqué qu’il serait recontacté pour une étude plus complète.`;

            this.analytics.trackEvent('form_simulation_submit', '');
            this.api.post(`contact/simulator`, this.formDatas).subscribe(() => {});
        });
    }

    /**
     * Function to check if email is valid
     */
    public checkEmail(): boolean {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.formDatas.email);
    }

    /**
     * Function to check if phone number is valid
     */
    public checkPhoneNumber(): boolean {
        return this.formDatas.phone_number.length >= 10;
    }

    /** Check if input change */
    public onInputChange(number: number): void {
        if (number === 1) {
            this.isEmailInputDirty = true;
            this.checkEmail();
        }
        else if (number === 2) {
            this.isPhoneInputDirty = true;
            this.checkPhoneNumber();
        }
    }
}
