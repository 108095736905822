import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'Keypoint',
  templateUrl: './key-point.component.html',
  styleUrls: ['./key-point.component.scss']
})
export class KeyPointComponent implements OnInit {

  /** Icon of key-point */
  @Input() icon: string = '';

  /** Number of key-point */
  @Input() value: string = '';

  /** Text of key-point */
  @Input() text: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
