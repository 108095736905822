<div class="blog-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Témoignages', link: '/temoignage-client'}, {title: article?.data?.title }]"></Breadcrumb>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="blog-content">
    <ng-container *ngIf="article !== null">
      <TitleAtom type="h1" text="{{ article.data.title }}" align="left" color="dark" size="x-large" weight="semi-bold"></TitleAtom>

      <div>
        <div class="section-left">
          <div class="header">
            <div>
              <ParagraphAtom size="intermediate" weight="regular" align="left" text="{{ cms.textStyle(article.data.author[0]) }}"></ParagraphAtom>
              <ParagraphAtom size="intermediate" weight="regular" align="left" text="Publié le {{ article.data.published_at | date: 'dd MMMM YYYY': '': 'fr-FR' }}"></ParagraphAtom>
            </div>

            <div>
              <ButtonAtom type="share" [style]="'stroked'" icon="share" size="small" color="light" text="Partager"></ButtonAtom>
            </div>
          </div>

          <div class="body">
            <div>
              <VideoYoutubeAtom video="{{ article.data.video_youtube }}" width="800" height="410" widthMobile="100%" heightMobile="200px"></VideoYoutubeAtom>
            </div>
            <CmsHtmlRendererAtom [data]="article.data.body"></CmsHtmlRendererAtom>
          </div>

          <!-- FORM -->
          <div class="prismic-form-bottom" *ngIf="article.data.select === FORM_TYPE.CONTACT">
            <TitleAtom align="center" *ngIf="isMobile" color="dark" weight="semi-bold" size="intermediate" text="Discutons ensemble"></TitleAtom>
            <ContactMessage title="Je veux être contacté" btnText="Envoyer ma demande"></ContactMessage>
          </div>
          <div  class="prismic-form-bottom" *ngIf="article.data.select === FORM_TYPE.MEETING">
            <CardInline [style]="'basic'" title="Prendre un rendez-vous téléphonique" btnText="Prendre rendez-vous" btnIcon="calendar" (btnClick)="handleMeetingModal()" text="Un appel dure 30 min en moyenne. Vous souhaitez être rappelé à une date/heure précise car vous avez des questions ? Un Conseiller en Energie Solaire vous répond et vous conseille sur votre projet."></CardInline>
            <ContactMeeting [(isHidden)]="hideMeetingModal"></ContactMeeting>
          </div>
          <div class="prismic-form-bottom" *ngIf="article.data.select === FORM_TYPE.SIMULATOR">
            <SearchSimulation type="simulation" icon="pinned" text="Simuler mon installation solaire" placeholder="Saisissez votre adresse"></SearchSimulation>
          </div>
        </div>

        <div class="section-right">
          <TitleAtom type="h3" text="A découvrir également" color="dark" size="medium" weight="semi-bold"></TitleAtom>
          <ul>
            <ng-container *ngFor="let article of articlesDiscovery">
              <li>
                <a routerLink="/temoignage-client/{{ article.data.slug }}">
                  <Image imageExternal="{{ article.data.image.url }}" alt="{{ article.data.image.alt }}" width="140px" mobileWidth="140px" borderRadius="radius-half "></Image>
                  <div>
                    <TitleAtom type="h6" text="{{ article.data.published_at | date: 'dd MMMM YYYY': '': 'fr-FR' }}" align="left" color="dark" size="micro" weight="regular"></TitleAtom>
                    <TitleAtom type="h4" text="{{ article.data.title }}" align="left" color="dark" size="nano" weight="semi-bold"></TitleAtom>
                  </div>
                </a>
              </li>
            </ng-container>
          </ul>
          <div *ngIf="!isMobile" class="testimonial-right-container">
            <FormArticle btnText="Demander un devis" title="Étudier mon projet"></FormArticle>
          </div>
        </div>
      </div>

      <div *ngIf="isMobile" class="testimonial-right-container">
        <FormArticle btnText="Demander un devis" title="Étudier mon projet"></FormArticle>
      </div>
    </ng-container>
  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
