import { Component, OnInit, NgZone, Inject, PLATFORM_ID } from '@angular/core';
import { CmsAnnounceType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import { isPlatformBrowser, ViewportScroller } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'Announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {

  /** Data from the CMS */
  public data: CmsAnnounceType | null = null;

  /** The width and height of the window user */
  public isMobile = false;

  constructor(
    private viewportScroller: ViewportScroller,
    private router: Router,
    public cms: CmsService,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }

    // Get an article
    this.cms.retrieve(`[[at(document.type, \"bandeau_annonce\")]]`, "[my.page.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.data = content.results[0];
      });
    });

    // Subscribe to router events to handle anchor navigation
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const anchor = this.data?.data?.internal_link?.url?.split('#')[1];
      if (anchor) {
        this.scrollToAnchorWhenReady(anchor.trim());
      }
    });
  }

  /**
   * Function to open the link in other tab
   */
  openLink() {
    if (this.data?.data.external_link?.url) {
      window.open(this.data.data.external_link.url, '_blank');
    }
  }

  /**
   * Function to navigate on other page
   */
  navigate() {
    if (this.data?.data.internal_link?.url) {
      window.location.href = this.data.data.internal_link.url;
    }
  }

  /**
   * Function to navigate to an anchor link on another page
   **/
  navigateToAnchorLink() {
    const urlWithAnchor = this.data?.data.internal_link?.url ?? '';
    if (urlWithAnchor) {
      const [url, anchor] = urlWithAnchor.split('#');
      if (url && anchor) {
        this.router.navigate([url], { fragment: anchor.trim() });
      }
    }
  }

  /**
   * Function to scroll to anchor when the element is ready
   * @param anchor - The anchor to scroll to
   **/
  private scrollToAnchorWhenReady(anchor: string) {
    const checkExist = setInterval(() => {
      const element = document.getElementById(anchor);
      if (element) {
        clearInterval(checkExist);
        this.viewportScroller.scrollToAnchor(anchor);
      }
    }, 100);
  }
}
