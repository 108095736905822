<div class="footer-component">
  <div class="footer-bandeau">
    <div class="logo">
      <Image type="icon" width="158px" image="logo-footer"></Image>
    </div>
    <div class="rating">
      <GoogleRatingAtom [style]="'light'" [hasIcon]="false"></GoogleRatingAtom>

    </div>
  </div>
  <div class="footer-top">

    <ul>

      <li>
        <TitleAtom [mobileSize]="16" color="yellow" size="tiny" weight="semi-bold" text="Contact"></TitleAtom>
      </li>
      <li class="social">
        <div>
          <a href="https://www.facebook.com/monitoringmonabee" target="_blank"><Image type="icon" width="32px" mobileWidth="40px" image="facebook"></Image></a>
          <a href="https://www.youtube.com/@monabee2474" target="_blank"><Image type="icon" width="32px" mobileWidth="40px" image="youtube"></Image></a>
          <a href="https://www.linkedin.com/company/monabee/" target="_blank"><Image type="icon" width="32px" mobileWidth="40px" image="linkedin"></Image></a>
        </div>
      </li>
      <li>
        <Image type="icon" width="24px" mobileWidth="24px" image="phone-white"></Image>
        <ButtonAtom [style]="'clear'" href="tel:{{ data?.data?.phone_number }}"  color="grey-dark" text="{{ data?.data?.phone_number }}"></ButtonAtom>
        <!--
                <ParagraphAtom color="grey-dark" size="intermediate" weight="regular" text="04 82 53 72 45"></ParagraphAtom>
        -->
      </li>
      <li>
        <Image type="icon" width="24px" mobileWidth="24px" image="geoloc-white"></Image>
        <ParagraphAtom color="grey-dark" size="intermediate" weight="regular" text="4 chemin des Hirondelles à Dardilly"></ParagraphAtom>
      </li>

    </ul>
    <ul>
      <li>
        <TitleAtom [mobileSize]="16" color="yellow" size="tiny" weight="semi-bold" text="Nos implantations"></TitleAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Bordeaux" href="ville/bordeaux"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="La Rochelle" href="ville/la-rochelle"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Lyon" href="ville/lyon"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Marseille" href="ville/marseille"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Toulouse" href="ville/toulouse"></ButtonAtom>
      </li>
    </ul>
    <ul>
      <li>
        <TitleAtom [mobileSize]="16" color="yellow" size="tiny" weight="semi-bold" text="Nos offres"></TitleAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Installation Panneaux solaires" href="/installer-panneaux-solaires"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Domotique" href="/domotique-solaire-monitoring-photovoltaique/"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Installation Borne de recharge" href="/installer-borne-de-recharge-solaire"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Simuler mon installation solaire" href="/simulateur-panneau-solaire"></ButtonAtom>
      </li>
    </ul>
    <ul>
      <li>
        <TitleAtom [mobileSize]="16" color="yellow" size="tiny" weight="semi-bold" text="S'informer"></TitleAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Nos actualités et conseils" href="/blog"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Les aides financières" href="/conseils/aides-financieres"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Nos guides à télécharger" href="/guides"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Nos clients témoignent" href="/temoignage-client"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="FAQ" href="/faq"></ButtonAtom>
      </li>
    </ul>
    <ul>

      <li>
        <TitleAtom [mobileSize]="16" color="yellow" size="tiny" weight="semi-bold" text="Nous découvrir"></TitleAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Nos engagements" href="/entreprise"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Notre histoire" href="/histoire"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Monabee recrute" href="/recrutement"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Monabee dans la presse" href="/presse"></ButtonAtom>
      </li>
      <li>
        <ButtonAtom [style]="'clear'" color="grey" text="Nous contacter" href="/contact"></ButtonAtom>
      </li>
    </ul>
  </div>
  <div class="footer-mediation">
    <ParagraphAtom color="light" size="intermediate" weight="regulars" text="Médiateur à la consommation : C&C - médiation ; 37 rue des chênes 25480 Miserey-salines ; www.cc-mediateurconso-bfc.fr"></ParagraphAtom>
  </div>
  <div class="footer-bottom">
    <ul>
      <li>
        <Image width="49px" mobileWidth="71px" type="icon" image="footer-certif-1"></Image>
      </li>
      <li>
        <Image width="43px" mobileWidth="62px" type="icon" image="footer-certif-2"></Image>
      </li>
      <li>
        <Image width="34px" mobileWidth="49px" type="icon" image="footer-certif-3"></Image>
      </li>
      <li>
        <Image width="35px" mobileWidth="50px" type="icon" image="footer-certif-4"></Image>
      </li>
    </ul>
    <div>
      <ButtonAtom href="mentions-legales" color="grey" [style]="'clear'" text="Mentions légales"></ButtonAtom>
      <ButtonAtom href="donnees-personnelles-politique-de-confidentialite" color="grey" [style]="'clear'" text="Données personnelles"></ButtonAtom>
    </div>
    <ParagraphAtom color="grey" text="© Copyright 2023 Monabee" weight="400" size="intermediate"></ParagraphAtom>
  </div>
</div>
