import {Component, Inject, NgZone, PLATFORM_ID} from '@angular/core';
import { CmsPagePresseType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import {Meta, Title} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";
import {AnalyticsService} from "../../services/analytics.service";

@Component({
  selector: 'app-press-list',
  templateUrl: './press-list.component.html',
  styleUrls: ['./press-list.component.scss']
})
export class PressListComponent {

  /**
   *  The page from the CMS
   */
  public page: CmsPagePresseType | null = null;

  public showAll: boolean = false;

  public showAboutAll: boolean = false;

  public showReleasesAll: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public analytics: AnalyticsService,
    private titleService: Title,
    private metaService: Meta,
    public cms: CmsService,
    private ngZone: NgZone,
  ) {
  }

  /* The width of the user window */
  public currentWidth = 2000;

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.currentWidth = window.innerWidth;
    }

    // Get page
    this.cms.retrieve(`[[at(document.type, \"page_presse\")]]`, "[my.page.position]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.page = content.results[0];

        // Set title and meta description
        this.titleService.setTitle(this.page?.data.metadata_title || "");
        this.metaService.updateTag({ name: 'description', content: this.page?.data.metadata_description || ""});
      });
    });
  }

  /* To display all press list */
  public toggleAll(id: number): void {
    if (id === 1) {
      this.showAll = !this.showAll;
    }
    else if (id === 2) {
      this.showAboutAll = !this.showAboutAll;
    }
    else if (id === 3) {
      this.showReleasesAll = !this.showReleasesAll;
    }
  }

  /**
   * When clicking the content to download
   */
  public onClick(): void {
    this.analytics.trackForm(10647);
  }

}
