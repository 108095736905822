<div class="blog-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: page?.data?.breadcrumb}]"></Breadcrumb>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="blog-content">
    <ng-container  *ngIf="page !== null">
      <TitleAtom type="h1" text="{{ page.data.title }}" align="left" color="dark" size="x-large" weight="semi-bold"></TitleAtom>

      <div>
        <div class="section-left">
          <div class="body">
            <div>
              <Image *ngIf="page.data.image.url !== ''" imageExternal="{{ page.data.image.url }}" alt="{{ page.data.image.alt }}" width="800px" mobileWidth="100%" borderRadius="radius"></Image>
              <VideoYoutubeAtom video="{{ page.data.video_youtube }}" width="800px" height="410px" widthMobile="100%" heightMobile="222px" ></VideoYoutubeAtom>
            </div>
            <CmsHtmlRendererAtom [data]="page.data.body" [array1]="page.data.array_1" [array2]="page.data.array_2" [array3]="page.data.array_3"></CmsHtmlRendererAtom>
            <ButtonAtom size="default" [style]="'basic'" color="yellow" text="Je veux bénéficier des aides" href="/contact"></ButtonAtom>
          </div>
        </div>

        <div class="section-right">
          <TitleAtom type="p" text="A découvrir également" color="dark" size="medium" weight="semi-bold"></TitleAtom>
          <ul>
            <ng-container *ngFor="let article of articlesDiscovery">
              <li>
                <a routerLink="/blog/{{ article.data.slug }}">
                  <Image imageExternal="{{ article.data.image.url }}" alt="{{ article.data.image.alt }}" width="160px" borderRadius="radius-half "></Image>
                  <div>
                    <TitleAtom type="p" text="{{ article.data.published_at | date: 'dd MMMM YYYY': '': 'fr-FR' }}" align="left" color="dark" size="micro" weight="regular"></TitleAtom>
                    <TitleAtom type="p" text="{{ article.data.title }}" align="left" color="dark" size="nano" weight="semi-bold"></TitleAtom>
                  </div>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
