<div class="google-rating {{ style }}" (click)="redirect()">
  <Image *ngIf="hasIcon" type="icon" image="google" width="42px" alt="google"></Image>
  <div>
    <div>Avis Google</div>
    <div>
      <span>{{ rating }}</span>
      <Image type="icon" image="stars{{ style == 'dark' ? '-dark' : '' }}" width="92px" alt="étoiles"></Image>
    </div>
  </div>
</div>
