<div class="card-author-component" [ngClass]="classes">
    <Image *ngIf="this.image !== ''" width="96px" mobileWidth="96px" type="icon" imageExternal="{{image}}"></Image>
    <div class="card-author-content">
      <div class="card-author-content-title">
        <TitleAtom [mobileSize]="18" type="p" color="dark" weight="semi-bold" size="medium" text="{{title}}"></TitleAtom>
        <ButtonAtom [style]="'basic'" iconRight="linkedin-blue" href="{{href}}" target="_blank"></ButtonAtom>
      </div>
      <CmsHtmlRendererAtom [data]="body"></CmsHtmlRendererAtom>
<!--      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="{{text}}"></ParagraphAtom>-->

      <div class="links">
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Ses articles phares:"></ParagraphAtom>
        <ul>
          <ng-container *ngFor="let article of articles">
            <li>
              <ButtonAtom [style]="'is-link'" href="{{article.article_link.url}}" target="_blank" text="{{article.article_title}}"></ButtonAtom>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
</div>
