import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'CardMeeting',
  templateUrl: './card-meeting.component.html',
  styleUrls: ['./card-meeting.component.scss']
})
export class CardMeetingComponent implements OnInit {

  /* Image of the agency */
  @Input() image: string = '';

  /* Image from cloudinary */
  @Input() imageCloudinary: string = '';

  /* Localisation of the agency */
  @Input() localisation: string = '';

  /* Text of the agency */
  @Input() text: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
