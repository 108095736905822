 import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ButtonRadio',
  templateUrl: './button-radio.component.html',
  styleUrls: ['./button-radio.component.scss']
})
export class ButtonRadioComponent implements OnInit {

  /* Name of the input for id */
  @Input() name: string = '';

  /* Style of the button radio : Basic, second */
  @Input() style: string = '';

  /* Different options value for the select */
  @Input() options: { value: any; label: string; disabled?: boolean }[] = [];

  /* Different options value for the select */
  @Input() selectedOption: string = "";

  /* Event to check when value is selected */
  @Output() selectedValueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public get classes(): Array<string> {
    return [`${this.style}`];
  }

  /**
   * Function to get the value on change
   * @param value
   */
  public onValueChange(value: any): void {
    this.selectedValueChange.emit(value);
  }
}
