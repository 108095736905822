<div class="author-container">
  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{ title: 'Auteurs', link: '/auteurs' }]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom type="h1" align="center" text="Nos auteurs" color="purple" size="medium" weight="semi-bold"></TitleAtom>
      <TitleAtom type="h2" align="center" text="Découvrez notre équipe de rédaction" color="purple" size="heading" weight="semi-bold"></TitleAtom>
      <ParagraphAtom align="center" text="Il nous tient à cœur de vous proposer des articles de blog complets, d’actualité, et accessibles à tous ! Pour cette mission, nous avons mis nos meilleurs experts sur le coup ! Découvrez juste ici :" size="large" weight="regular"></ParagraphAtom>
    </div>
  </div>

  <!-- BOTTOM PART -->
  <div class="author-content">
    <!--  -->
    <ul>
      <ng-container *ngFor="let author of authors">
        <li>
          <CardAuthor [style]="'basic'" href="{{author.data.linkedin_link}}" image="{{ author.data.image.url }}" title="{{author.data.name}}" [body]="author.data.description" [articles]="author.data.articles_headline"></CardAuthor>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="footer-container">
      <Footer></Footer>
  </div>
</div>
