import { Component, Inject, NgZone, OnInit, PLATFORM_ID, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { CmsArticleType, CmsFaqType, CmsGuideType, CmsResponseType } from "../../types/cms.types";
import { CmsService } from "../../services/cms.service";
import { StructuredDataService } from "../../services/structured-data.service";
import { isPlatformBrowser } from "@angular/common";

declare const google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  /** List of reviews **/
  public reviews: Array<any> = [];

  /** The articles from the CMS **/
  public articles: Array<CmsArticleType> = [];

  /** The guides from the CMS **/
  public guides: Array<CmsGuideType> = [];

  /** The faq from the CMS **/
  public faqs: Array<CmsFaqType> = [];

  /** The testimonials from the CMS **/
  public testimonials: Array<CmsArticleType> = [];

  /** Show the contact modal **/
  public hideContactModal: boolean = true;

  /** Show the contact modal **/
  public hideCallModal: boolean = true;

  /** Show the contact modal **/
  public hideMeetingModal: boolean = true;

  /* The width of the user window */
  public currentWidth = 2000;

  public isMobile = false;

  // @ViewChildren('detailElement') detailsElements: QueryList<any> | undefined;



  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private ngZone: NgZone,
    private cms: CmsService,
    private structuredDataService: StructuredDataService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1000;
      this.currentWidth = window.innerWidth;
    }

    this.getGoogleReviews();

    // Set title and meta description
    this.titleService.setTitle('Installation de panneau solaire, installateur photovoltaique en autoconsomation - Monabee');
    this.metaService.updateTag({ name: 'description', content: 'Installer des panneaux photovoltaïques en autoconsommation en toute sérénité avec Monabee, installeur de panneaux solaires depuis 2012.' });

    // Get blog articles
    this.cms.retrieve("[[at(document.type, \"blog\")][any(document.tags, [\"Homepage\"])]]", "[my.blog.published_at desc]", 1, 4).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articles = content.results;
      });
    });

    // Get guides
    this.cms.retrieve("[[at(document.type, \"guide\")][any(document.tags, [\"Homepage\"])]]", "[my.guide.published_at desc]", 1, 2).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.guides = content.results;
      });
    });

    // Get faq
    this.cms.retrieve("[[at(document.type, \"faq\")][any(document.tags, [\"Homepage\"])]]", "[my.faq.title]", 1, 10).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.faqs = content.results;

        // Add structured data
        this.structuredDataService.addFaq(this.faqs);
      });
    });

    // Get testimonials
    this.cms.retrieve("[[at(document.type, \"testimonials\")][any(document.tags, [\"Homepage\"])]]", "[my.testimonials.pusblished_at]", 1, 2).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.testimonials = content.results;
      });
    });
  }

  /**
   * Get the google reviews
   */
  public getGoogleReviews(): void {
    if (isPlatformBrowser(this.platformId)) {
      const placeId = 'ChIJYTlcBKzs9EcRkK7WiWxzT7w';
      const placesService = new google.maps.places.PlacesService(document.createElement('div'));

      // Request place details including reviews
      placesService.getDetails({ placeId: placeId, fields: ['reviews'], language: 'fr-fr' }, (place: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.ngZone.run(() => {
            this.reviews = place.reviews;
          });
        }
      });
    }
  }

  public handleMeetingModal() {
    this.hideMeetingModal = false;
    document.body.style.overflow = 'hidden';
  }

  public handleCallModal() {
    const today = new Date();
    const hours = today.getHours();
    const day = today.getDay();

    if (day <= 5 && ((hours >= 9 && hours <= 14) || (hours >= 18 && hours <= 20)))
      this.hideCallModal = false;
    else
      this.handleMeetingModal();
  }



 
}
