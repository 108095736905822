import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Meta, Title } from "@angular/platform-browser";


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [DatePipe],
})
export class ContactComponent implements OnInit {

  /** Show the contact modal **/
  public hideContactModal: boolean = true;

  /** Show the contact call modal **/
  public hideCallModal: boolean = true;

  /** Show the contact meeting modal **/
  public hideMeetingModal: boolean = true;

  constructor(
    private datePipe: DatePipe,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    // Set title and meta description
    this.titleService.setTitle('Une question ? échangeons sur votre projet photovoltaïque');
    this.metaService.updateTag({ name: 'description', content: "Une question sur votre projet d'installation photovoltaïque ? Contactez nous ! - Monabee" });
  }

  public handleMeetingModal() {
    this.hideMeetingModal = false;
    document.body.style.overflow = 'hidden';
  }

  public handleCallModal() {
    const today = new Date();
    const hours = today.getHours();
    const day = today.getDay();

    if (day <= 5 && ((hours >= 9 && hours <= 14) || (hours >= 18 && hours <= 20)))
      this.hideCallModal = false;
    else
      this.handleMeetingModal();
  }

}
