<div class="cms-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: page?.data?.breadcrumb}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div class="herobanner-container">
      <TitleAtom type="h1" align="center" text="{{ page?.data?.title }}" color="dark" size="heading" weight="semi-bold"></TitleAtom>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="cms-content" *ngIf="page !== null">
    <CmsHtmlRendererAtom colorTitle="blue" [data]="page.data.body" [array1]="page.data.array_1" [array2]="page.data.array_2" [array3]="page.data.array_3"></CmsHtmlRendererAtom>
  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
