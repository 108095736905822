import { Component, OnInit, Input } from '@angular/core';
import { StructuredDataService } from "../../services/structured-data.service";
import { Location } from '@angular/common';

@Component({
  selector: 'Breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  /** Links */
  @Input() links: Array<{title: string|undefined, link?: string|undefined}> = [];

  /** Colors */
  @Input() color: string = "dark";

  constructor(
    private structuredDataService: StructuredDataService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    // add home link
    const breadcrumbLinks = [{ title: 'Monabee', link: '/' }, ...this.links];

    // Add current page link
    /*breadcrumbLinks.push({title: 'Current Page', link: this.location.path()});*/
    if (breadcrumbLinks.length > 1) {
      const lastTitle = this.links[this.links.length - 1]?.title;
      const currentPageLink = { title: lastTitle, link: 'https://monabee.com' + this.location.path() };

      breadcrumbLinks.push(currentPageLink);

    }

    // Remove elements without a link
    const filteredBreadcrumbLinks = breadcrumbLinks.filter((item) => item.link !== undefined);

    // Add structured data
    this.structuredDataService.addBreadcrumb(filteredBreadcrumbLinks);
  }

}
