import { Injectable } from '@angular/core';

// Declare gTM dataLayer array.
declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {
  }

  /**
   * Track the event
   * @param event The event name
   * @param category The data
   */
  public trackEvent(event: string, category: string): void {
    window.dataLayer.push({
      event: event,
      category: category
    });
  }

  /**
   * Track the form
   * @param formId The form id
   */
  public trackForm(formId: number): void {
    window.dataLayer.push({
      event: 'form_submit',
      event_name: 'form_submit',
      formId: formId,
      variable_formId: formId
    });
  }

  /**
   * Track the video event
   * @param name The name
   * @param action The action
   * @param progress The progress
   */
  public trackVideo(name: string, action: string, progress: number = 0): void {
    window.dataLayer.push({
      event: 'video',
      video_name: name,
      video_action: action,
      video_progress: progress
    });
  }

}
