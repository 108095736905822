<div class="card-content-component {{ type }}">
  <div>
    <VideoAtom *ngIf="type === 'video'" format="{{format}}" video="{{video}}" width="576px" height="324px"></VideoAtom>
    <Image *ngIf="type === 'image'" type="image" [width]="imageWidth" mobileWidth="100%" imageExternal="{{imageExternal}}" image="{{image}}"></Image>
    <Image *ngIf="type === 'guide'" type="image" [width]="imageWidth" mobileWidth="100%" imageExternal="{{imageExternal}}" image="{{image}}"></Image>
    <span *ngIf="textHeadline !== ''">{{ textHeadline }}</span>
  </div>
  <div [ngClass]="type === 'guide' ? 'guide' : ''">
    <div>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="{{date}}"></ParagraphAtom>
      <TitleAtom [mobileSize]="16" color="dark" [size]="size === 'small' ? 'small' : 'intermediate'" weight="semi-bold" text="{{title}}"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="{{text}}"></ParagraphAtom>
      <div *ngIf="type === 'video'" class="card-content-name">
        <ParagraphAtom color="dark" size="large" weight="semi-bold" text="{{name}}"></ParagraphAtom>
      </div>
    </div>
    <div *ngIf="type === 'guide'" class="card-content-download">
      <ButtonAtom [style]="'clear'" color="dark" text="Télécharger gratuitement" icon="download" href="{{ href }}"></ButtonAtom>
    </div>
  </div>
</div>
