import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'SelectAtom',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SelectComponent implements OnInit {

  /* Different options value for the select */
  @Input() options: { value: any; label: string }[] = [];

  /* Option to search in select dropdown */
  @Input() searchOptions: boolean = false;

  /* Event to check when value is selected */
  @Input() value: string | null = null;
  @Output() valueChange = new EventEmitter<any>();

  /* Filter text for options */
  public filterText: string = '';

  /* Filtered options */
  public filteredOptions: { value: any; label: string }[] = [];


  /* Select datas */
  public selectedLabel: string = '--Choisir--';
  public selectedValue: any;

  /* Check if select dropdown is active */
  public isDropdownVisible: boolean = false;


  constructor() {}

  ngOnInit() {
    if (this.options && this.options.length > 0) {
      const initialOption = this.options.find(option => option.value === this.value) || this.options[0];
      this.selectedLabel = initialOption.label;
      this.selectedValue = initialOption.value;
      this.filteredOptions = this.options;
    }
  }

  /**
  * Function to display the dropdown selection
   */
  public toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  /**
   * Function to get the value of the selected option
   * @param option object with the value and label
   */
  public selectOption(option: { value: any; label: string }): void {
    this.selectedLabel = option.label;
    this.selectedValue = option.value;
    this.valueChange.emit(option.value);
    this.isDropdownVisible = false;
  }

  /**
   * Function to filter the content in dropdown
   */
  public filterOptions(): void {
    if (this.filterText.trim() === '') {
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options.filter(option => option.label.toLowerCase().includes(this.filterText.toLowerCase()));
    }
  }

}
