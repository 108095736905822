<div class="video-container">
  <ng-container *ngIf="autoplay === true">
    <video [ngClass]="classes" #videoElement (click)="onVideoClick()" [ngStyle]="this.windowWidth > 1024 ? { width : width, height: height } : {width: mobileWidth, height: mobileHeight}" [attr.controls]="videoElement.paused ? null : true" autoplay loop muted>
      <source [src]="'../../../assets/videos/' + video + '.' + format" [type]="'video/' + format">
      <source [src]="video" [type]="'video/' + format">
    </video>
  </ng-container>

  <ng-container *ngIf="autoplay === false">
    <video [ngClass]="classes" #videoElement (click)="onVideoClick()" [ngStyle]="this.windowWidth > 1024 ? { width : width, height: height } : {width: mobileWidth, height: mobileHeight}" [attr.controls]="videoElement.paused ? null : true">
      <source [src]="'../../../assets/videos/' + video + '.' + format" [type]="'video/' + format">
      <source [src]="video" [type]="'video/' + format">
    </video>

    <div class="video-controls" [ngClass]="{'paused': videoElement.paused}">
      <ButtonAtom #playButton [style]="'clear'"  icon="play" (click)="onVideoClick()"></ButtonAtom>
    </div>
  </ng-container>
</div>
