<ul class="btn-radio-component" [ngClass]="classes" *ngIf="this.style === 'basic'">
  <li *ngFor="let option of options">
    <input type="radio" [(ngModel)]="selectedOption" [value]="option.value" id="{{name}}_{{option.label}}" name="{{name}}" [disabled]="option.disabled ?? false" (change)="onValueChange(option.value)">
    <span class="radio"></span>
    <label for="{{name}}_{{option.label}}">{{option.label}}</label>
  </li>
</ul>

<div class="radio-inputs" [ngClass]="classes" *ngIf="this.style === 'second'">
  <label *ngFor="let option of options" class="radio">
    <input type="radio" [(ngModel)]="selectedOption" [value]="option.value" id="{{name}}_{{option.label}}" name="{{name}}" [disabled]="option.disabled ?? false" (change)="onValueChange(option.value)">
    <span class="name">{{option.label}}</span>
  </label>
</div>
