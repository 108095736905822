import {Component, OnInit, NgZone, Inject, PLATFORM_ID} from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { CmsArticleType,  CmsResponseType } from "../../types/cms.types";
import { Meta, Title } from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";
import {FORM_TYPE} from "../../constants/constants";

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsPageComponent implements OnInit {

  /* The articles from the CMS */
  public articles: Array<CmsArticleType> = [];

  /** Show the contact meeting modal **/
  public hideMeetingModal: boolean = true;

  /* To know the form define on prismic */
  public formType: string = '';

  /* The articles choose for the headline from the CMS */
  public articleHeadline: CmsArticleType | null = null;

  /* The popular articles from the CMS */
  public articlesPopular: Array<CmsArticleType> = [];

  /** The width and height of the window user */
  public isMobile: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public cms: CmsService,
    private ngZone: NgZone,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {


    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }

    // Set title and meta description
    this.titleService.setTitle("Témoignage client - Installation de panneaux solaires - Monabee");
    this.metaService.updateTag({ name: 'description', content: "Découvrez le témoignage de notre client. Il nous a fait confiance pour l'installation de panneaux solaires d'une puissance de 4,5 kWc." });


    // Get headline
    this.cms.retrieve("[[at(document.type, \"testimonials\")][any(document.tags, [\"Mis en avant\"])]]", "[my.testimonials.published_at desc]", 1, 1).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articleHeadline = content.results[0];
      });
    });

    // Get Popular articles
    this.cms.retrieve("[[at(document.type, \"testimonials\")][any(document.tags, [\"Populaire\"])]]", "[my.testimonials.published_at desc]", 1, 5).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articlesPopular = content.results;
      });
    });

    // Retrieve the articles
    this.cms.retrieve(`[[at(document.type, \"testimonials\")]]`, "[my.testimonials.published_at desc]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.articles = [...content.results, ...this.articles];
      });
    });

    // Retrieve the form type
    this.cms.retrieve(`[[at(document.type, \"page_testimonials\")]]`, "[my.testimonials.published_at desc]").then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.formType = content.results[0].data.select;
      });
    });
  }

  /** Handle the meeting modal */
  public handleMeetingModal() {
    this.hideMeetingModal = false;
    document.body.style.overflow = 'hidden';
  }

  protected readonly FORM_TYPE = FORM_TYPE;
}
