<div  class="call-container"  *ngIf="isHidden === false">
  <div *ngIf="this.currentWidth < 1024" class="mobile-call-header">
    <ParagraphAtom [mobileSize]="16" color="dark" size="large" weight="medium-weight" text="{{this.isValidated === false ? 'Être rappelé par un conseiller' : ''}}"></ParagraphAtom>
    <div class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
  </div>

  <div class="call-section" *ngIf="isValidated === false">
    <div *ngIf="currentWidth > 1024" class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
    <TitleAtom [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" text="Vous souhaitez être rappelé(e) par un Conseiller"></TitleAtom>
    <div>
      <ParagraphAtom weight="semi-bold" size="intermediate" text="Téléphone*"></ParagraphAtom>
      <InputAtom type="tel" (input)="onInputChange(1)" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="{{form.phone_number === '' || checkPhoneNumber() ? 'light' : 'red'}}" name="phoneNumber" [(value)]="form.phone_number"></InputAtom>
      <ParagraphAtom *ngIf="!checkPhoneNumber() && isPhoneInputDirty && form.phone_number !== ''" color="red" size="small" weight="medium" text="Numéro de téléphone invalide."></ParagraphAtom>
    </div>
    <div class="validate-btn">
      <ButtonAtom [style]="'basic'" [isDisabled]="this.form.phone_number.length < 10" color="yellow" text="Valider" (click)="onSubmit()"></ButtonAtom>
    </div>

    <div class="opposition-message">
      <ParagraphAtom color="dark" align="left" size="intermediate" weight="regular" text="Vous avez le droit de vous inscrire gratuitement sur la liste d'opposition au démarchage téléphonique."></ParagraphAtom>
    </div>
  </div>

  <div class="call-validated" *ngIf="isValidated === true">
    <div *ngIf="currentWidth > 1024" class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
    <Image type="icon" image="check-blue" width="56px"></Image>
    <TitleAtom [mobileSize]="28" color="dark" align="center" size="large" weight="semi-bold" text="Votre demande a bien été prise en compte"></TitleAtom>
    <ParagraphAtom color="dark" align="center" size="intermediate" weight="regular" text="Un Conseiller en Energie Solaire vous rappelle dès que possible."></ParagraphAtom>
    <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="onHideModal()"></ButtonAtom>
  </div>

</div>
